//ignorei18n_start
import { Button, TextField, Menu, MenuItem, IconButton, ButtonGroup } from "@mui/material";
import React, { useState, useContext } from "react";
import PropTypes from 'prop-types'; // Import PropTypes
import Collapse from '@mui/material/Collapse';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BlockIcon from '@mui/icons-material/Block';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { useTheme } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { UserContext } from '../../../contexts/UserContext';

export default function BuildHeader({ handleSearch, handleDateRange, handleBuildReportFilter, handleBuildDcFilter }) {

    const theme = useTheme();

    return (
        <div style={{
            height: "30px",
            backgroundColor: theme.palette.mode === "light" ? "rgb(248 250 252)" : "rgba(3, 3, 3, 0)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px 30px",
            boxSizing: "border-box"
        }}>
            <Lefty handleSearch={handleSearch} handleDateRange={handleDateRange}/>
            <Middle />
            <Righty handleBuildReportFilter={handleBuildReportFilter} handleBuildDcFilter={handleBuildDcFilter}/>
            {/* <div style={{minWidth: "500px"}}></div> */}
        </div>
    );
}

// Add propTypes to BuildHeader
BuildHeader.propTypes = {
    handleSearch: PropTypes.func.isRequired,
    handleDateRange: PropTypes.func.isRequired,
    handleBuildReportFilter: PropTypes.func.isRequired,
    handleBuildDcFilter:PropTypes.func.isRequired
};

function Lefty({ handleSearch, handleDateRange }) {
    const [open, setOpen] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [dateOpen, setDateOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const theme = useTheme();

    const getPastMonths = () => {
        const months = [];
        for (let i = 1; i <= 3; i++) {
            months.push(dayjs().subtract(i, 'month').format('MMMM'));
        }
        return months;
    };

    const pastMonths = getPastMonths();

    const handleSubmit = () => {
        const formattedStartDate = startDate ? dayjs(startDate).format('YYYY-MM-DD') : null;
        const formattedEndDate = endDate ? dayjs(endDate).format('YYYY-MM-DD') : null;
        handleDateRange(formattedStartDate, formattedEndDate);
        setDateOpen(false);
    };

    const handleReset = () => {
        setDateOpen(false);
        setStartDate(null);
        setEndDate(null);
        handleDateRange("Reset", "Reset");
    };

    const handleDateRangeChange = (months) => {
        const newStartDate = dayjs().subtract(months, 'month').format('YYYY-MM-DD');
        const newEndDate = dayjs().format('YYYY-MM-DD');
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const handleClearSearch = () => {
        setSearchText(""); // Clear search input
        setOpen(false);
        handleSearch("No Data");  // Trigger handleSearch with cleared data
    };

    return (
        <div style={{
            minWidth: "500px",
            maxWidth: "500px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start"
        }}>
            <ClickAwayListener onClickAway={() => setDateOpen(false)}>
                <div style={{ position: "relative" }}>
                    <Button style={{ minWidth: "0px" }} onClick={() => setDateOpen(s => !s)}>
                        <DateRangeOutlinedIcon style={{ color: "#116D6E"}}/>
                    </Button>
                    {dateOpen && (
                        <div style={{ position: 'absolute', top: '100%', left: 0, zIndex: 1, background: 'white', border: '1px solid #ccc', padding: '10px', maxHeight: "600px",
                        overflowY: "auto", backgroundColor: theme.palette.mode === "dark" ? "rgba(3, 3, 3, 0.79)" : "rgb(248 250 252)" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Box sx={{ margin: "0px 270px", gap: "2px" }} display="flex" flexDirection="row">
                                    <Button onClick={() => handleDateRangeChange(1)} autoFocus>{pastMonths[0]}</Button>
                                    <Button onClick={() => handleDateRangeChange(2)} autoFocus>{pastMonths[1]}</Button>
                                    <Button onClick={() => handleDateRangeChange(3)} autoFocus>{pastMonths[2]}</Button>
                                </Box>
                                <Box display="flex" flexDirection="row">
                                    <Typography variant="caption">Start Date</Typography>
                                    <DateCalendar value={startDate ? dayjs(startDate) : null} sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }} onChange={(newStartDate) => setStartDate(newStartDate)} />
                                    <Typography variant="caption" style={{ marginTop: '10px' }}>End Date</Typography>
                                    <DateCalendar value={endDate ? dayjs(endDate) : null} sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }} onChange={(newEndDate) => setEndDate(newEndDate)} />
                                </Box>
                                <Box sx={{ padding: "0px 250px", gap: "2px" }}>
                                    <Button onClick={() => setDateOpen(false)}>Cancel</Button>
                                    <Button onClick={handleReset}>Reset</Button>
                                    <Button onClick={handleSubmit} autoFocus>Submit</Button>
                                </Box>
                            </LocalizationProvider>
                        </div>
                    )}
                </div>
            </ClickAwayListener>
            <Button style={{ minWidth: "0px" }}
                onClick={() => setOpen(s => !s)}
            >
                <SearchIcon style={{ color: "#116D6E"}}/>
            </Button>
            <Collapse orientation="horizontal" in={open}>
                <TextField
                    variant="standard"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={e => e.code === "Enter" ? handleSearch(searchText) : null}
                    size="small"
                    style={{
                        width: "400px",
                        fontSize: "14px"
                    }}
                    InputProps={{
                        endAdornment: searchText && (
                            <InputAdornment position="end">
                                <Button onClick={handleClearSearch} style={{ minWidth: "0px" }}>
                                    <ClearIcon style={{ color: "#3c5c9b" }}/>
                                </Button>
                            </InputAdornment>
                        )
                    }}
                />
            </Collapse>
        </div>
    );
}

// Add propTypes to Lefty
Lefty.propTypes = {
    handleSearch: PropTypes.func.isRequired,
    handleDateRange: PropTypes.func.isRequired
};

function Middle() {
    return (
        <div style={{
            color: "#116D6E",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "3px",
            fontSize: "14px"
        }}>
            <NewReleasesIcon fontSize="14px"/>
            Build Report
        </div>
    );
}

function Righty({ handleBuildReportFilter, handleBuildDcFilter }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState("All"); // Default to "All"
    const [selectedDC, setSelectedDC] = useState("DC DATA"); // Default to "All"
    const [dcAnchorEl, setDcAnchorEl] = useState(null); // State for dcData menu
    const { configuration } = useContext(UserContext);
    const dcMap = configuration.current[`build.DC_columnName_map`];
    const theme = useTheme();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDcMenuClick = (event) => {
        setDcAnchorEl(event.currentTarget); // Open dcData menu
    };

    const handleSelectClose = () => {
        setAnchorEl(null);
    };

    const handleDcMenuClose = () => {
        setDcAnchorEl(null);
    };

    const handleMenuItemClick = (buttonName) => {
        setSelectedFilter(buttonName);
        handleBuildReportFilter(buttonName);
        handleSelectClose();
    };

    const handleDCDataClick = (DC_Selected) => {
        setDcAnchorEl(null);
        if (DC_Selected === "DC DATA") {
            setSelectedDC("DC DATA"); // Reset to default display label
            handleBuildDcFilter("Not Selected"); // Clear filter
        } else {
            Object.keys(dcMap).forEach((key) => {
                if (dcMap[key] === DC_Selected) {
                    setSelectedDC(key);
                    handleBuildDcFilter(DC_Selected);
                }
            });
        }
    };
    

    return (
        <div style={{
            minWidth: "500px",
            maxWidth: "500px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end"
        }}>
             <ButtonGroup sx={{
                backgroundColor: theme.palette.mode === "dark" ? "#062B2B" : "#F7F7F7",
                borderColor: theme.palette.mode === "dark" ? "#062B2B" : "#F7F7F7"}}>
                {/* First Button */}
                <Button 
                    sx={{ 
                        color: "#1092A3", 
                        fontWeight:"bold",
                        fontSize: "12px",
                        padding: "4px 8px",
                        minWidth: "100px"
                    }} 
                    onClick={handleClick} 
                    endIcon={<ArrowDropDownIcon />}
                >
                    {selectedFilter}
                </Button>

                {/* Second Button with Clear Icon */}
                <Button 
                    sx={{ 
                        color: "#1092A3", 
                        fontWeight: "bold",
                        fontSize: "12px",
                        padding: "4px 8px",
                        minWidth: "100px",
                        display: "flex",
                        alignItems: "center"
                    }} 
                    onClick={handleDcMenuClick} 
                    endIcon={<ArrowDropDownIcon />}
                >
                    {selectedDC}
                    {selectedDC !== "DC DATA" && (
                        <IconButton
                            aria-label="clear selection"
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent parent button's click from firing
                                handleDCDataClick("DC DATA"); // Call the clear function
                            }}
                            size="small"
                            sx={{
                                padding: 0, // Reduce padding to make the icon smaller
                                marginLeft: "6px",
                                color: "white",
                                backgroundColor: "#ff595e"
                            }}
                        >
                            <ClearIcon fontSize="small" sx={{ height: "15px", width: "14px", backgroundColor: "#ff595e", borderRadius: "55%" }} />
                        </IconButton>
                    )}
                </Button>
            </ButtonGroup>
            {/* First button menu */}
            <Menu
                id="customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'customized-button'
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleSelectClose}
            >
                <MenuItem
                    sx={{ 
                        width: "124px", 
                        justifyContent: "flex-start", 
                        fontSize: "12px", 
                        display: 'flex', 
                        alignItems: 'center' 
                    }}
                    onClick={() => handleMenuItemClick("Tracked")}
                    disableRipple
                >
                    <AssignmentTurnedInIcon fontSize="small" sx={{ fontSize: "15px" }} />
                    &nbsp;&nbsp;Tracked
                </MenuItem>
                <MenuItem
                    sx={{ 
                        width: "124px", 
                        justifyContent: "flex-start", 
                        fontSize: "12px", // Ensure consistent font size with the button
                        display: 'flex', 
                        alignItems: 'center' 
                    }}
                    onClick={() => handleMenuItemClick("Not Tracked")}
                    disableRipple
                >
                    <UnpublishedIcon fontSize="small" sx={{ fontSize: "15px" }} />
                    &nbsp;&nbsp;Not Tracked
                </MenuItem>
                <MenuItem
                    sx={{ 
                        width: "124px", 
                        justifyContent: "flex-start", 
                        fontSize: "12px", 
                        display: 'flex', 
                        alignItems: 'center' 
                    }}
                    onClick={() => handleMenuItemClick("Opted Out")}
                    disableRipple
                >
                    <BlockIcon fontSize="small" sx={{ fontSize: "15px" }} />
                    &nbsp;&nbsp;Opted Out
                </MenuItem>
                <MenuItem
                    sx={{ 
                        width: "124px", 
                        justifyContent: "flex-start", 
                        fontSize: "12px", 
                        display: 'flex', 
                        alignItems: 'center' 
                    }}
                    onClick={() => handleMenuItemClick("All")}
                    disableRipple
                >
                    <AutoStoriesIcon fontSize="small" sx={{ fontSize: "15px" }} />
                    &nbsp;&nbsp;All
                </MenuItem>
            </Menu>
             {/* Second button menu */}
            <Menu
                id="dc-data-menu"
                anchorEl={dcAnchorEl}
                open={Boolean(dcAnchorEl)}
                onClose={handleDcMenuClose}
            >
                 {Object.keys(dcMap).map((key) => (
                    <MenuItem
                        key={key}
                        sx={{ 
                            width: "124px", 
                            justifyContent: "flex-start", 
                            fontSize: "12px", 
                            display: 'flex', 
                            alignItems: 'center' 
                        }}
                        onClick={() => handleDCDataClick(dcMap[key])}
                        disableRipple
                    >
                        <BuildCircleIcon fontSize="small" sx={{ fontSize: "15px" }} />
                        &nbsp;&nbsp;{key}
                    </MenuItem>
                ))}
            </Menu>
        </div>        
    );
}

Righty.propTypes = {
    handleBuildReportFilter: PropTypes.func.isRequired,
    handleBuildDcFilter: PropTypes.func.isRequired
};

//ignorei18n_end
