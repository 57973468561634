import { Button, Collapse, Divider } from "@mui/material";
import React, { useState, useContext } from "react";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Tooltip from '@mui/material/Tooltip';
import History from "./History";
import { UserContext } from '../../../contexts/UserContext';
import { useTheme } from '@mui/material/styles';
import { GetNameAndEmpID} from '../../../util/Loading';

export default function BuildTimelineData({ data }) {
    const keys = ["Server", "BuildLabel", "BuildBy", "BuildComment", "ApprovalStatus"];
    const { configuration } = useContext(UserContext);
    const [historyOpen, setHistoryOpen] = useState(false);

    const status = extractData(data.AllBuildDetails, configuration);

    return (
        <>
            <div style={{
                display: "grid",
                gridTemplateColumns: "0.9fr 5px 3fr 5px 2fr 5px 2fr 5px 1.4fr 0.25fr", // Add narrow columns for dividers
                alignItems: "center", // Ensure vertical alignment
                paddingLeft: "10px",
                boxSizing: "border-box",
                width: "100%"
            }}>
                {keys.map((k, i) => (
                    <React.Fragment key={i}>
                        {/* Stat data */}
                        <Stat 
                            k={k} 
                            v={typeof data[k] === "object" ? data.AllBuildDetails[k] : data.AllBuildDetails[k]} 
                            color={"#116D6E"} 
                            data={data} 
                        />
                        
                        {/* Add divider between items, but not after the last one */}
                        {i !== keys.length - 1 && (
                            <Divider 
                                orientation="vertical" 
                                flexItem 
                                style={{ height: "80%", margin: "auto" }} // Adjust height and centering
                            />
                        )}
                    </React.Fragment>
                ))}

                <div style={{ margin: "auto" }}>
                    <Button 
                        onClick={() => setHistoryOpen(s => !s)} 
                        style={{ 
                            minWidth: 0, 
                            color: '#116D6E', 
                            visibility: status && Object.keys(status).length !== 0 ? 'visible' : 'hidden',
                            cursor: status && Object.keys(status).length !== 0 ? 'pointer' : 'default'
                        }}
                        disabled={!status || Object.keys(status).length === 0} 
                    >
                        {historyOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </Button>
                </div>
            </div>

    
            {/* Only render Collapse when historyOpen is true */}
            {historyOpen && status && Object.keys(status).length !== 0 ? (
                <Collapse 
                    orientation="vertical" 
                    in={historyOpen} 
                    style={{
                        paddingLeft: "15px",
                        borderRadius: "10px",
                        margin: "10px 20px"
                    }}
                >
                    <History data={status} />
                </Collapse>
            ) : null}
        </>
    );
    }
    

export function extractData(obj, configuration) {
    const dcMap = configuration.current[`build.DC_columnName_map`];
    var result = {};
    for (const key in dcMap) {
        const value = dcMap[key];
        if (obj[value] !== null && obj[value] !== undefined) {
            try {
                result[key] = JSON.parse(obj[value]);
            } catch (e) { }
        }
    }
    return result;
}

export function Stat({ k, v, color, data }) {
    const theme = useTheme();

    return (
        <div style={{
            // width: "50%",
            padding: "5px 10px",
            boxSizing: "border-box",
            display: "flex",
            overflow: 'hidden',
            flexDirection: "column",
            height: "80px",
            justifyContent: "space-between"
        }}>
            <div style={{
                fontSize: "10px",
                fontWeight: "bold",
                opacity: 0.75,
                color: "white",
                padding: "0px 4px",
                borderRadius: "4px",
                boxShadow: `0 5px 10px -6px ${color}`,
                backgroundColor: color,
                textAlign: "center",
                padding: "0 10px",
                width: "fit-content",
            }}>
                {k}
            </div>
            {k === "BuildLabel" ? (
                <div style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: theme.palette.mode === "dark" ? "white" : "rgb(71 85 105)",
                    marginLeft: "2px",
                    width: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis" ,
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start"
                }}>
                    <Tooltip title={v} placement="bottom">
                        {v !== null ? (v.length > 50 ? v.slice(0, 50) + "..." : v) : (v)} 
                    </Tooltip>
                </div>
            ) : (
                <Tooltip title={v} placement="bottom">
                    <div style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: theme.palette.mode === "dark" ? "white" : "rgb(71 85 105)",
                        marginLeft: "2px",
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}>
                            {k === "BuildBy" ? (
                                <GetNameAndEmpID emails={v} />
                            ) : (
                                v !== null ? (v.length > 30 ? v.slice(0, 30) + "..." : v) : (v)
                            )}
                    </div>
                </Tooltip>
            )}
        </div>
    );
}

