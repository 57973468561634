import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../contexts/UserContext.js';
import './Notification.css';
import {
    notifications_get_query_1, notifications_get_query_2, notifications_get_query_3, notifications_get_query_4,
    notifications_get_query_5
} from '../catalystDB/queryGenerator.js';
import NotificationsNoneTwoToneIcon from '@mui/icons-material/NotificationsNoneTwoTone';
import { List, ListItem, ListItemText, Box, Button, Paper, Snackbar, Alert, Badge, Menu, Avatar, Divider, Tooltip } from '@mui/material';


function Notifications() {

    // let msg;
    const [notificationTableData, setNotificationTableData] = useState([]);
    const { userDetails, teamDetails } = useContext(UserContext);
    const user_email = userDetails.current.auth.email_id;
    const [openMessageNotication, setOpenMessageNotication] = useState(false);
    const [updateProcess, setUpdateProcess] = useState(false);
    const [updateRejectProcess, setUpdateRejectProcess] = useState(false);
    const [updateProcessFailed, setUpdateProcessFailed] = useState(false);
    const [updateProcessCompleted, setUpdateProcessCompleted] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0);

    useEffect(() => {
        async function ToFetchNotifactionData() {
            var query = notifications_get_query_1(user_email);
            var countQuery = notifications_get_query_4(user_email);
            var zcql = window.catalyst.ZCatalystQL;
            try {
                const response = await zcql.executeQuery(query);
                const countResponse = await zcql.executeQuery(countQuery);
                const countData = +countResponse.content[0].NotificationCount.Notification_Count;
                const newNotifications = response.content.map(item => ({
                    Message: JSON.parse(item.Notifications.Message),
                    IsApprovedOrRejected: item.Notifications.Is_Approved_Or_Rejected,
                    CreatorID: item.Notifications.Creator_RowId
                }));
                setNotificationTableData(prevData => [...prevData, ...newNotifications]);
                setUnreadCount(countData);
            } catch (error) {
                console.error(error);
            }
        }
        ToFetchNotifactionData();
    }, []);

    async function ToInsertNotifactionDataAsRow(message, Is_Approved_Or_Rejected, user_email, CreatorId, TriggerdFrom) {
        var query;
        if (TriggerdFrom === "Updated") {
            query = notifications_get_query_3(CreatorId);
        }
        else {
            query = notifications_get_query_2(message, Is_Approved_Or_Rejected, user_email, CreatorId);
        }
        var zcql = window.catalyst.ZCatalystQL;
        try {
            const response = await zcql.executeQuery(query);
        } catch (error) {
            console.error(error);
        }
    }

    async function ToUpdateNoticationCount(isMarkedAsRead) {
        if (unreadCount !== 0) {
            var query;
            if (isMarkedAsRead === "MarkedAsRead" && unreadCount !== 0) {
                query = notifications_get_query_5(user_email, unreadCount - 1);
                setUnreadCount(unreadCount - 1);
            }
            else if (unreadCount < 0) {
                query = notifications_get_query_5(user_email, unreadCount);
            }
            var zcql = window.catalyst.ZCatalystQL;
            try {
                const response = await zcql.executeQuery(query);
            } catch (error) {
                console.error(error);
            }
        }
    }

    window.catalyst.notification.enableNotification().then(resp => {
        window.catalyst.notification.messageHandler = msg => {
            // console.log(msg);
            var jsonMessage = JSON.parse(msg);
            // console.log(jsonMessage);
            // Message Formatte to send
            // msg = {"Milestone Name":"NRS_Feature_Midsfsdflestone147","Type":"Releadsfsdfse","Component":"NRsdfsdfS","Due Date":"04-05-2023","Feature Involved":"QWEWsdfsdfsdfERT","user":"has submitted the Milessd sdf sdf sd fds. sdfdfsdftone form on 03-28-2023","Meta":{"CreatorId":"122333","Form_Name":"Milestone","Button":"true","user_email":"sharafath.s@zohocorp.com","Is_Approved_Or_Rejected":"False"}}
            let userNotification = {
                Message: jsonMessage,
                IsApprovedOrRejected: jsonMessage.Meta.Is_Approved_Or_Rejected,
                CreatorID: jsonMessage.Meta.CreatorId
            }
            setNotificationTableData(prevData => [userNotification, ...prevData]);
            setUnreadCount(prevCount => prevCount + 1); // Increment the unread count

            // To create a row in catalyst db ///////
            ToInsertNotifactionDataAsRow(msg, jsonMessage.Meta.Is_Approved_Or_Rejected, jsonMessage.Meta.NotifyUserEmail, jsonMessage.Meta.CreatorId, "Create New Row");
            ToUpdateNoticationCount();
        }
    });

    function handleMenuOpenOrClose() {
        setOpenMessageNotication(!openMessageNotication);
    }

    function handleApproveOrRejectButton(selectedNotification, selectedButton) {

        if (selectedButton === "Reject") {
            setUpdateRejectProcess(true);
        }

        setUpdateProcess(true);
        const url = '/server/sample?table=ApprovalForRoadmapOrMilestone&CreatorRowId=' + selectedNotification.CreatorID + '&ApprovalStatus=' + selectedButton + '&formName=' + selectedNotification.Message.Meta.Form_Name;
        const headers = {
            'Content-Type': 'application/json',
        };
        fetch(url, {
            method: 'POST',
            headers: headers,
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Request failed with status: ' + response.status);
                }
                if (response.headers.get('content-length') === '0') {
                    throw new Error('Empty response');
                }
                return response.json();
            })
            .then(data => {
                const statusCode = data;
                if (statusCode == "200") {
                    setUpdateProcess(false);
                    setUpdateRejectProcess(false);
                    setUpdateProcessCompleted(true);
                    setTimeout(() => {
                        setUpdateProcessCompleted(false);
                    }, 3000);
                    setNotificationTableData(prevData => prevData.filter(notification => notification.CreatorID !== selectedNotification.CreatorID));
                    ToInsertNotifactionDataAsRow("no need", "no need", "no need", selectedNotification.CreatorID, "Updated");
                }
                else {
                    setUpdateProcess(false);
                    setUpdateRejectProcess(false);
                    setUpdateProcessFailed(true);
                    setTimeout(() => {
                        setUpdateProcessFailed(false);
                    }, 3000);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    const renderNotificationMessage = message => {
        const otherKeys = Object.keys(message).filter(key => key !== 'Meta' && key !== 'Type' && key !== 'DRI');
        
        // Handle multiple DRIs by splitting the string on commas
        const driList = message.DRI ? message.DRI.split(',') : [];
    
        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {driList.length > 0 && (
                    <div className="notification-avatar">
                        {driList.map((dri, index) => {
                            const trimmedDri = dri.trim(); // Trim to remove extra spaces
                            const user = teamDetails.current[trimmedDri];
    
                            return (
                                <Tooltip 
                                    key={index} 
                                    title={user?.PeopleUserName} 
                                    sx={{ backgroundColor: 'rgb(248,250,242) !important' }}
                                >
                                    <Avatar
                                        alt="Profile Photo"
                                        src={`https://contacts.zoho.com/file?ID=${user?.zuid}&exp=6000&t=user&fs=thumb`}
                                        sx={{ width: 35, height: 35, cursor: 'pointer', marginTop: "0px" }}
                                    />
                                </Tooltip>
                            );
                        })}
                    </div>
                )}
                <Divider orientation="vertical" flexItem />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {otherKeys.map(key => (
                        <Box key={key} className="notification-value">{message[key]}</Box>
                    ))}
                </div>
            </div>
        );
    };
    

    return (
        <div className="notification-menu">
            <Badge badgeContent={unreadCount} color="primary">
                <NotificationsNoneTwoToneIcon
                    onClick={handleMenuOpenOrClose}
                    style={{ cursor: 'pointer' }}
                />
            </Badge>

            <Menu
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={openMessageNotication}
                onClose={handleMenuOpenOrClose}
                sx={{
                    borderRadius: "100px !important",
                    marginTop: "38px",
                    minHeight: '100vh !important'
                }}
            >
                <div style={{ minHeight: '100vh !important', overflowY: 'scroll', width: '440px', backgroundColor: 'rgb(248,250,242)', padding: '10px'}}>
                    {notificationTableData.length === 0 ? (
                      <div style={{
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        minHeight: '100%', 
                        color: '#555', 
                        backgroundColor: '#f1f1f1',
                        borderRadius: '12px',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                        textAlign: 'center',
                        fontFamily: 'Arial, sans-serif',
                    }}>
                        <span style={{
                            fontSize: '0.9rem',
                        }}>😔</span>
                        <h2 style={{
                            fontSize: '0.9rem',
                            margin: 0
                        }}>You have no notifications yet</h2>
                        <p style={{
                            fontSize: '0.8rem',
                            color: '#777',
                            marginTop: '10px'
                        }}>Check back later for updates</p>
                    </div>                    
                    ) : (
                        <List>
                            {notificationTableData.map((notification, index) => (
                                <ListItem key={index} style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
                                    <Paper onClick={() => ToUpdateNoticationCount("MarkedAsRead")} elevation={1} style={{ display: 'flex', flexDirection: 'row', padding: '10px', borderRadius: '15px', backgroundColor: 'rgb(241,242,232)', width: '100%', position: 'relative', height: "90px", overflow: 'auto' }}>
                                        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                            <ListItemText primary={renderNotificationMessage(notification.Message)} />
                                        </Box>
                                        {notification.IsApprovedOrRejected === "False" ? (
                                            <>
                                                <Divider orientation="vertical" flexItem></Divider>
                                                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: '20px', gap: "10px", padding: "5px 10px 5px 10px", height: "10px", width: "20px" }}>
                                                    <Button variant="contained" color="success" onClick={() => handleApproveOrRejectButton(notification, 'Approve')} style={{ flex: 1, fontSize: "10px", padding: "2px", cursor: 'pointer', opacity: 0.8 }}>✔</Button>
                                                    <Button variant="contained" color="error" onClick={() => handleApproveOrRejectButton(notification, 'Reject')} style={{ flex: 1, fontSize: "10px", padding: "2px", cursor: 'pointer', opacity: 0.8 }}>✖</Button>
                                                </Box>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        <Box className={notification.IsApprovedOrRejected === "False" ? `vertical-tab` : `vertical-tab-withoutButton`}>{notification.Message.Type}</Box>
                                    </Paper>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </div>
            </Menu>

            <Snackbar
                open={updateProcess}
                onClose={() => setUpdateProcess(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                {updateRejectProcess === true ? (
                    <Alert severity="error">The reject process has been initiated 🚫</Alert>
                ) : (
                    <Alert severity="success">The approval process has been initiated 😀</Alert>
                )}
            </Snackbar>
            <Snackbar
                open={updateProcessCompleted}
                onClose={() => setUpdateProcessCompleted(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                {updateRejectProcess === true ? (
                    <Alert severity="error">The reject process has been completed 🚫</Alert>
                ) : (
                    <Alert severity="success">The approval process has been completed 🥳</Alert>
                )}
            </Snackbar>
            <Snackbar
                open={updateProcessFailed}
                onClose={() => setUpdateProcessFailed(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity="error">Some error occurred kindly contact zone support for more ☹️</Alert>
            </Snackbar>
        </div>
    );
}

export default Notifications;
