//ignorei18n_start
import { Button, Divider, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useState, useRef, useLayoutEffect, useEffect, useContext } from 'react';
import Collapse from '@mui/material/Collapse';
import SearchIcon from '@mui/icons-material/Search';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { GetNameAndEmpID } from "../../../../util/Loading";
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import QueryStatsSharpIcon from '@mui/icons-material/QueryStatsSharp';
import { useTheme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const MODE_OPTIONS = Object.freeze([
    "Manager",
    "Mentor",
    "Developer",
    "Component"
]);

export default function Header({
    handleSearch,
    handleModeChange,
    handleSecondaryModeChange,
    initialMode,
    listOfManagers,
    listOfMentors,
    listOfDevelopers,
    listOfComponents,
    GlobalServer,
    handleDateRange,
    setToOpenTheAllData
}) {

    const theme = useTheme();
    
    return (
        <div style={{
            height: "30px",
            backgroundColor:  theme.palette.mode === "dark" ? "" : "rgb(248 250 252)",
            // boxShadow: "1px 1px 20px 1px rgba(71, 85, 105, 0.25)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px 10px",
            boxSizing: "border-box"
        }}>
            <Lefty handleSearch={handleSearch} handleDateRange={handleDateRange} setToOpenTheAllData={setToOpenTheAllData}/>
            <Middle />
            <Righty
                handleModeChange={handleModeChange}
                handleSecondaryModeChange={handleSecondaryModeChange}
                initialMode={initialMode}
                listOfManagers={listOfManagers}
                listOfMentors={listOfMentors}
                listOfDevelopers={listOfDevelopers}
                listOfComponents={listOfComponents}
                GlobalServer = {GlobalServer}
            />
        </div>
    );

}

function Lefty({
    handleSearch,
    handleDateRange,
    setToOpenTheAllData
}) {
    const [open, setOpen] = useState(false);
    const [dateOpen, setDateOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [searchText, setSearchText] = useState("");
    const theme = useTheme();

   
    const getPastMonths = () => {
        const months = [];
        for (let i = 1; i <= 3; i++) {
            months.push(dayjs().subtract(i, 'month').format('MMMM'));
        }
        return months;
    };

    const pastMonths = getPastMonths();

    const handleSubmit = () => {
        const formattedStartDate = startDate ? dayjs(startDate).format('YYYY-MM-DD') : null;
        const formattedEndDate = endDate ? dayjs(endDate).format('YYYY-MM-DD') : null;
        handleDateRange(formattedStartDate, formattedEndDate);
        setDateOpen(false);
      };
    
      const handleReset = () => {
        setDateOpen(false);
        setStartDate(null);
        setEndDate(null);
        handleDateRange("Reset", "Reset");
      };
      
      const handleDateRangeChange = (months) => {
        const newStartDate = dayjs().subtract(months, 'month').format('YYYY-MM-DD');
        const newEndDate = dayjs().format('YYYY-MM-DD');
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    return (
        <div style={{
            minWidth: "450px",
            maxWidth: "450px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            position: "relative",  // Make this div a relative container
            overflow: "visible"
          }}>
            <Button onClick={() => setToOpenTheAllData(openAllDatta => !openAllDatta)}>
                <QueryStatsSharpIcon />
            </Button>
            <div style={{ position: "relative" }}>
              <Button style={{ minWidth: "0px" }} onClick={() => setDateOpen(s => !s)}>
                <DateRangeOutlinedIcon />
              </Button>
              {dateOpen && (
                <div style={{ position: 'absolute', top: '100%', left: 0, zIndex: 200, background: 'white', border: '1px solid #ccc', padding: '10px', maxHeight: "600px",
                overflowY: "auto" , backgroundColor:  theme.palette.mode === "dark" ? "#000023" : "rgb(248 250 252)", borderRadius:"5%"}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{ margin: "0px 270px", gap: "2px" }} display="flex" flexDirection="row">
                        <Button onClick={() => handleDateRangeChange(1)} autoFocus>{pastMonths[0]}</Button>
                        <Button onClick={() => handleDateRangeChange(2)} autoFocus>{pastMonths[1]}</Button>
                        <Button onClick={() => handleDateRangeChange(3)} autoFocus>{pastMonths[2]}</Button>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Typography sx={{color:"white"}} variant="caption">Start Date</Typography>
                        <DateCalendar sx={{color: theme.palette.mode === "dark" ? "white" : "black"}} value={startDate ? dayjs(startDate) : null} onChange={(newStartDate) => setStartDate(newStartDate)} />
                        <Typography sx={{color:"white"}} variant="caption" style={{ marginTop: '10px' }}>End Date</Typography>
                        <DateCalendar sx={{color: theme.palette.mode === "dark" ? "white" : "black"}} value={endDate ? dayjs(endDate) : null} onChange={(newEndDate) => setEndDate(newEndDate)} />
                    </Box>
                    <Box sx={{padding:"0px 250px",gap:"2px"}}>
                        <Button onClick={() => setDateOpen(false)}>Cancel</Button>
                        <Button onClick={handleReset}>Reset</Button>
                        <Button onClick={handleSubmit} autoFocus> Submit</Button>
                    </Box>
                  </LocalizationProvider>
                </div>
              )}
            </div>
            <Button style={{ minWidth: "0px" }} onClick={() => setOpen(s => !s)}>
              <SearchIcon />
            </Button>
            <Collapse orientation="horizontal" in={open}>
              <TextField
                variant="standard"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={e => e.code === "Enter" ? handleSearch(searchText) : null}
                size="small"
                style={{
                  width: "305px",
                  fontSize: "14px"
                }}
              />
            </Collapse>
          </div>
    );
}

function Middle() {
    return (
        <div style={{
            // minWidth: "300px",
            // maxWidth: "300px",
            color: "rgb(29 78 216)",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "3px",
            fontSize: "14px",
            marginRight:"113px"
        }}>
            <NewReleasesIcon fontSize="14px"/>
            Release Timeline
        </div>
    );
}

function Righty({
    handleModeChange,
    handleSecondaryModeChange,
    initialMode,
    listOfManagers,
    listOfMentors,
    listOfDevelopers,
    listOfComponents,
    GlobalServer
}) {

    const [selectedMode, setSelectedMode] = useState(initialMode);
    const [selectedOption, setSelectedOption] = useState();
    const localStorageFetchedServer = localStorage.getItem('selectedServer');
    const theme = useTheme();
    
    useEffect(() => {
		if(GlobalServer != "unknown" && GlobalServer != undefined){
			setSelectedOption(GlobalServer)
		}
        else{
            setSelectedOption(null)
        }
		
	  }, [GlobalServer]);

    //   useEffect(() => {
	// 	console.log(selectedOption)
	//   }, [selectedOption]);

    var secondaryOptions = listOfManagers;

    switch(selectedMode) {
        case "Manger": 
            secondaryOptions = listOfManagers;
            break;
        case "Mentor":
            secondaryOptions = listOfMentors;
            break;
        case "Developer":
            secondaryOptions = listOfDevelopers;
            break;
        case "Component":
            secondaryOptions = listOfComponents;
            break;
    }

    return (
        <div style={{
            minWidth: "500px",
            maxWidth: "500px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "5px"
        }}>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                fontSize: "12px",
                color: theme.palette.mode === "dark" ? "white" : "black"
            }}>
                Mode:
                {/* <Divider orientation="vertical" flexItem /> */}
                <Select
                    variant="standard"
                    value={selectedMode ?? ""}
                    onChange={v => { setSelectedMode(v.target.value); handleModeChange(v.target.value) }}
                    style={{ fontSize: "14px", width: "120px", textAlign: "left", textIndent: "10px"}}
                >
                    {
                        MODE_OPTIONS.map((o, i) => {
                            return <MenuItem value={o} style={{ fontSize: "14px" }} key={"mode-"+i}>{o}</MenuItem>
                        })
                    }
                </Select>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                fontSize: "12px"
            }}>
                <span style={{minWidth: "80px", textAlign: "right", color: theme.palette.mode === "dark" ? "white" : "black"}}>{selectedMode}:</span>
                {/* <Divider orientation="vertical" flexItem /> */}
                <Autocomplete
                    sx={{marginBottom:"12px"}}
                    value={selectedOption ?? localStorageFetchedServer}
                    onChange={(event, newValue) => {
                        setSelectedOption(newValue);
                        handleSecondaryModeChange(newValue);
                    }}
                    options={secondaryOptions}
                    getOptionLabel={(option) => {
                        // If it's in 'Component' mode, display the string, otherwise handle the custom logic
                        return selectedMode === "Component" 
                        ? option 
                        : typeof option === 'string' ? option : option?.name || "";  // Ensure it uses the name for custom objects
                    }}
                    isOptionEqualToValue={(option, value) => {
                        // Compare by value to ensure it works with strings and objects alike
                        return option === value || (typeof option === 'object' && option?.name === value?.name);
                    }}
                    renderOption={(props, option) => (
                        <MenuItem
                        key={"secondary-mode-" + option}
                        style={{ fontSize: "14px" }}
                        {...props}
                        >
                            {selectedMode === "Component" ? (
                                option
                            ) : (
                                <GetNameAndEmpID style={{ height: "30px" }} emails={option} />
                            )}
                        </MenuItem>
                    )}
                    style={{ fontSize: "9px", width: "320px", textAlign: "left", textIndent: "10px" }}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        variant="standard"
                        label="Search"
                        style={{ fontSize: "9px" }}
                        />
                    )}
                />
            </div>
        </div>
    );
}
//ignorei18n_end