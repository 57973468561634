/* eslint-disable react/prop-types */

import React from "react";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { useTheme } from '@mui/material/styles';

export default function History({ data }) {
    const keys = Object.keys(data);  // Dynamically fetch the keys, like "LOCALPre"

    return (
        <div>
            <Timeline
                sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.25
                    }
                }}
            >
                {
                    keys.map((key, i) => {
                        const statusData = data[key];
                        const upgradeStatus = statusData.data['Upgrade Status'];
                        return (
                            <TimelineItem key={i} sx={{ alignItems: "center" }}>
                                <TimelineOppositeContent
                                    sx={{
                                        paddingTop: "12px",   
                                        paddingBottom: "12px",
                                        display: "flex",     
                                        justifyContent: "center"
                                    }}
                                >
                                    <Lefty 
                                        upgradedOn={statusData.data['Upgraded On']} 
                                        dcData = {key}
                                     />
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    {
                                        upgradeStatus === 'Upgrade Completed' ? (
                                            <CheckCircleIcon style={{ color: 'green' }} />
                                        ) : (
                                            <CancelIcon style={{ color: 'red' }} />
                                        )
                                    }
                                    {i !== keys.length - 1 && (
                                        <TimelineConnector style={{ backgroundColor: "#D98324" }} />
                                    )}
                                </TimelineSeparator>
                                <TimelineContent
                                    sx={{
                                        paddingTop: "12px",  
                                        paddingBottom: "12px",
                                        display: "flex",     
                                        justifyContent: "flex-start"
                                    }}
                                >
                                    <Righty 
                                        upgradeStatus={statusData.data['Upgrade Status']} 
                                        upgradedBy={statusData.data['Upgraded By']} 
                                        dcData = {key}
                                    />
                                </TimelineContent>
                            </TimelineItem>
                        );
                    })
                }
            </Timeline>
        </div>
    );
}

function Lefty({ upgradedOn, dcData }) {

    const theme = useTheme();

    return (
        <div style={{ fontSize: "14px", lineHeight: "1.5", color:theme.palette.mode === "light" ? 'black' : 'white' }}>
             <div><strong>DC :</strong> {dcData || "N/A"}</div>
            <div style={{ fontSize: "12px", fontWeight: "bold", textAlign: "center" }}>
                {upgradedOn ? upgradedOn : "N/A"}
            </div>
         </div>
    );
}

function Righty({ upgradeStatus, upgradedBy, dcData }) {

    const theme = useTheme();

    return (
        <div style={{ fontSize: "14px", lineHeight: "1.5", color:theme.palette.mode === "light" ? 'black' : 'white' }}>
            <div><strong>Status :</strong> {upgradeStatus || "Unknown"}</div>
            <div><strong>Upgraded By :</strong> {upgradedBy || "N/A"}</div>
            {/* <div><strong>DC :</strong> {dcData || "N/A"}</div> */}
        </div>
    );
}
