/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { FormControl, MenuItem, Select, Box, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Chip } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from 'dayjs';

function TicketRangeSelector({ startTime, setStartTime, customStartDate, setCustomStartDate, customEndDate, setCustomEndDate, fetchTicketReports, showChip, setShowChip }) {
    const [openDialog, setOpenDialog] = useState(false);

    function formattedDate(date) {
        const formattedGivenDate = new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
        return formattedGivenDate;
    }

    const handleRangeChange = (event) => {
        const value = event.target.value;
        // Clear custom dates and hide chip if switching to a preset option
        if (value !== 'Custom') {
            setStartTime(value);
            setCustomStartDate(null);
            setCustomEndDate(null);
            setShowChip(false);
        }
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleReset = () => {
        setCustomStartDate(null);
        setCustomEndDate(null);
        setShowChip(false);
        setStartTime('Week');
        setOpenDialog(false);
    };

    const handleConfirm = () => {
        setStartTime("Custom");
        if (customStartDate && customEndDate) {
            setShowChip(true);
            setOpenDialog(false);
            fetchTicketReports();
        }
    };

    const handleChipDelete = () => {
        setCustomStartDate(null);
        setCustomEndDate(null);
        setShowChip(false);
        setStartTime('Week');
    };

    return (
        <Box display="flex" alignItems="center">
            <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                <Select
                    value={startTime}
                    onChange={handleRangeChange}
                    sx={{ height: 30, margin: '0px 0px 20px 17px' }}
                >
                    <MenuItem value="Week">This Week</MenuItem>
                    <MenuItem value="Month">This Month</MenuItem>
                    <MenuItem value="Quarter">This Quarter</MenuItem>
                    <MenuItem value="Custom" onClick={handleOpenDialog}>Custom Duration</MenuItem>
                </Select>
            </FormControl>

            {showChip && (
                <Chip
                    label={`${formattedDate(customStartDate)} - ${formattedDate(customEndDate)}`}
                    onDelete={handleChipDelete}
                    onClick={handleOpenDialog}
                    color='primary'
                    sx={{ marginLeft: 2,marginBottom:2.8 }}
                />
            )}

            {/* Dialog with Wider Width and Two StaticDatePickers */}
            <Dialog 
                open={openDialog} 
                onClose={handleCloseDialog} 
                maxWidth="md" 
                fullWidth
                sx={{ '& .MuiDialog-paper': { maxWidth: '700px', width: '100%' } }}
            >
                <DialogTitle>Select Date Range</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box sx={{ display: 'flex', gap: 4 }}>
                            <div>
                                <StaticDatePicker
                                    displayStaticWrapperAs="desktop"
                                    value={customStartDate}
                                    onChange={(newDate) => setCustomStartDate(dayjs(newDate))}
                                    label="Start Date"
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </div>
                            <div>
                                <StaticDatePicker
                                    displayStaticWrapperAs="desktop"
                                    value={customEndDate}
                                    onChange={(newDate) => setCustomEndDate(dayjs(newDate))}
                                    label="End Date"
                                    maxDate={dayjs()} // Disable dates after today
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </div>
                        </Box>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button onClick={handleReset} color="primary">
                        Reset
                    </Button>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default TicketRangeSelector;
