import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Select, MenuItem, Button, Typography, Box, TextField
} from '@mui/material';

export default function WorkBench() {
  const [tasks, setTasks] = useState([
    { id: 1, name: 'Build Server', status: 'Open', time: '00:00:00', remainingTime: 0, isRunning: false },
    { id: 2, name: 'Deploy App', status: 'Open', time: '00:00:00', remainingTime: 0, isRunning: false },
    { id: 3, name: 'Monitor Logs', status: 'Open', time: '00:00:00', remainingTime: 0, isRunning: false },
  ]);

  const timeToSeconds = (timeString) => {
    const [hrs, mins, secs] = timeString.split(':').map(Number);
    return hrs * 3600 + mins * 60 + secs;
  };

  const formatTime = (time) => {
    const hrs = Math.floor(time / 3600);
    const mins = Math.floor((time % 3600) / 60);
    const secs = time % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const handleStatusChange = (task, status) => {
    const updatedTasks = tasks.map(t => {
      if (t.id === task.id) {
        if (status === 'Ongoing') {
          const totalSeconds = timeToSeconds(t.time);
          return { ...t, status, remainingTime: totalSeconds, isRunning: true };
        } else if (status === 'Completed') {
          return { ...t, status, isRunning: false };
        }
        return { ...t, status };
      }
      return t;
    });
    setTasks(updatedTasks);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTasks(tasks.map(task => {
        if (task.isRunning && task.remainingTime > 0) {
          return { ...task, remainingTime: task.remainingTime - 1, time: formatTime(task.remainingTime - 1) };
        } else if (task.remainingTime === 0) {
          return { ...task, isRunning: false };
        }
        return task;
      }));
    }, 1000);

    return () => clearInterval(timer);
  }, [tasks]);

  const handleTimeChange = (task, time) => {
    const updatedTasks = tasks.map(t => (t.id === task.id ? { ...t, time } : t));
    setTasks(updatedTasks);
  };

  return (
    <Box className="page-content-2" sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>DevOps Task Status</Typography>
      
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Task Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Set Time (HH:MM:SS)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((task) => (
              <TableRow key={task.id}>
                <TableCell>{task.name}</TableCell>
                <TableCell>
                  <Select
                    value={task.status}
                    onChange={(e) => handleStatusChange(task, e.target.value)}
                    disabled={task.status === 'Completed'}
                  >
                    <MenuItem value="Open">Open</MenuItem>
                    <MenuItem value="Ongoing">Ongoing</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                  </Select>
                </TableCell>
                <TableCell>
                  <Typography>{task.time}</Typography>
                </TableCell>
                <TableCell>
                  {/* {task.status === 'Ongoing' ? (
                    <Typography>Timer Running...</Typography>
                  ) : ( */}
                    <TextField
                      label="Set Time"
                      value={task.time}
                      onChange={(e) => handleTimeChange(task, e.target.value)}
                      disabled={task.status !== 'Open'}
                      helperText="Format: HH:MM:SS"
                    />
                  {/* )} */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
