import React, { useState, useContext, useEffect } from 'react';
import { Box, Typography, Divider, Card, CardContent, Tooltip, TextField, Button, Collapse, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ServerDetail from './ServerDetail';
import { UserContext } from '../../../../contexts/UserContext';
import { queryDB } from '../../../../util/QueryDB';
import { TicketCard_page_component_get_query_1 } from '../../../../catalystDB/queryGenerator';
import TicketRangeSelector from './TicketRangeSelector';
import dayjs from 'dayjs';
import { CheckCircleOutline, HourglassEmpty, ErrorOutline, CancelOutlined, TimerOutlined, DoneAllOutlined, NotInterestedOutlined } from '@mui/icons-material';

const TicketCard = () => {
    const [selectedServer, setSelectedServer] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchOpen, setSearchOpen] = useState(false);
    const [overallData, setOverallData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { userDetails, teamDetails, allServers, serverConfiguration } = useContext(UserContext);
    const user_email = userDetails.current.auth.email_id;
    const userserver = teamDetails.current[user_email].Servers.split(",").filter(server => allServers.includes(server)).join(",");
    const serverData = userserver.split(",");
    const today = new Date();
    const formattedDate = formatDate(today);
    const [startTime, setStartTime] = useState("Week");
    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);
    const [showChip, setShowChip] = useState(false);

    const fetchTicketReports = async () => {
        try {
            setLoading(true);
            const { startDate, endDate } = getDateRange(startTime);
            const serverProducts = [];
            serverData.forEach(serverValue =>{
                if (serverValue in serverConfiguration.current) {
                    const products = JSON.parse(serverConfiguration.current[serverValue].IssueProductType);
                    products.forEach(prod => {
                        if (!serverProducts.includes(prod)) {
                            serverProducts.push(prod);
                        }
                    });
                }
            });
            
            const productName = serverProducts?.join(`','`) || "undefined";
            const response = await queryDB(TicketCard_page_component_get_query_1(productName,startDate, endDate, []));
            const data = response.map(item => item.Tickets);
            setOverallData(data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTicketReports();
    }, [startTime]);

    function formatDate(date) {
        // Check if date is a dayjs object
        if (dayjs.isDayjs(date)) {
            return date.format('YYYY-MM-DD');
        } 
        // Check if it's a Date object
        else if (date instanceof Date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        } 
        // If neither, log and throw an error
        else {
            // console.log("Invalid date format:", date);
            // throw new Error("Invalid date format");
        }
    }
    

    function getDateRange(rangeType) {
        const today = new Date();
        let endDate = formatDate(new Date(today.setDate(today.getDate() + 1)));
        let startDate;
    
        if (rangeType === "Month") {
            startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        } else if (rangeType === "Quarter") {
            const quarterStartMonth = today.getMonth() - 2;
            startDate = new Date(today.getFullYear(), quarterStartMonth, 1);
        } else if (rangeType === "Custom") {
            // Convert custom dates to dayjs objects if they're strings
            const start = dayjs(customStartDate);
            const end = dayjs(customEndDate).add(1, 'day'); // Add an extra day to the end date
        
            if (start.isValid() && end.isValid()) {
                startDate = start;
                endDate = end;
            } else {
                // throw new Error("Invalid custom date range.");
            }
        }
         else if (rangeType === "Week") {
            const dayOfWeek = today.getDay();
            const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
            startDate = new Date(today.setDate(diff));
        } else {
            startDate = "Not Selected";
        }
    
        return {
            startDate: startDate === "Not Selected" ? "Not Selected" : formatDate(startDate),
            endDate: endDate === "Not Selected" ? "Not Selected" : rangeType === "Custom" ? formatDate(endDate) : endDate
        };
    }
    

    const handleCardClick = (serverName) => {
        setSelectedServer(serverName);
    };

    const closeServerDetail = () => {
        setSelectedServer(null);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredServers = serverData.filter(serverName =>
        serverName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const getServerValues = (serverName) => {
        const parsedServerArray = JSON.parse(serverConfiguration.current[serverName]?.IssueProductType || '[]');
        const matchingData = overallData.filter(item => 
            parsedServerArray.includes(item.Product_Type)
        );
    
        const totalTickets = matchingData.length;
        const onTimeCount = matchingData.filter(ticket => OntimeOrNot(ticket.CREATEDTIME, ticket.ResolvedOn, ticket.Status) && ticket.Status !== 'Ticket Rejected').length;
        const DelayedCount = matchingData.filter(ticket => !OntimeOrNot(ticket.CREATEDTIME, ticket.ResolvedOn, ticket.Status) && ticket.Status !== 'Ticket Rejected').length;
        
        // Calculate percentage only if totalTickets > 0
        const onTimePercentage = totalTickets > 0 ? (onTimeCount / totalTickets) * 100 : 0;
        
    
        return {
            ontime: onTimeCount,
            delayed: DelayedCount,
            claimed: matchingData.filter(ticket => ticket.Status === 'Open').length || "-",
            unclaimed: matchingData.filter(ticket => ticket.Status === 'Ticket Created').length || "-",
            onTimePercentage: onTimePercentage.toFixed(0),
            totalTickets
        };
    };

    const sortedServers = filteredServers
        .map((serverName) => ({
            serverName,
            ...getServerValues(serverName),
        }))
        .sort((a, b) => b.totalTickets - a.totalTickets); // Sort by total tickets count in descending order
    

    return (
        <Box className="page-content-12" sx={{ padding: 3, height: '100vh', display: 'flex', flexDirection: 'column', bgcolor: '#f7f9fc' }}>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <CircularProgress />
                </Box>
            ) : selectedServer ? (
                <ServerDetail serverName={selectedServer} onClose={closeServerDetail} TicketCardStartTime={startTime} TicketCardCustomStartDate={customStartDate} TicketCardCustomEndDate={customEndDate}/>
            ) : (
                <>
                     <Box sx={{ position: 'sticky', top: 0, zIndex: 1, paddingBottom: 2, borderRadius: 2, boxShadow: 1, bgcolor: 'white', marginBottom: 2 }}>
                     <Box display="flex" justifyContent="center" sx={{ paddingTop: "10px", gap: 2 }}>
                            <Typography variant="h6" sx={{ color: '#333' }}>Overall (ZVP)</Typography>
                            <TicketRangeSelector fetchTicketReports={fetchTicketReports} startTime={startTime} setStartTime={setStartTime} customStartDate={customStartDate} setCustomStartDate={setCustomStartDate}
                                                 customEndDate={customEndDate} setCustomEndDate={setCustomEndDate} fetchTicketReports={fetchTicketReports} showChip={showChip} setShowChip={setShowChip}
                            />
                        </Box>
                        <DashboardMetrics overallData={overallData} fetchTicketReports={fetchTicketReports} startTime={startTime} setStartTime={setStartTime}/>
                    </Box>

                    <Divider sx={{ my: 2 }} />

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, width: "100%" }}>
                        <Button style={{ minWidth: "0px" }} onClick={() => setSearchOpen(s => !s)}>
                            <SearchIcon style={{ color: "#4A628A" }} />
                        </Button>
                        <Collapse orientation="horizontal" in={searchOpen} sx={{ width: "100%" }}>
                            <TextField
                                variant="standard"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                size="small"
                                sx={{ width: "400px" }}
                                fullWidth
                            />
                        </Collapse>
                    </Box>

                    <Box sx={{ flex: 1, overflowY: 'auto', paddingBottom: "70px" }}>
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(auto-fill, minmax(min(200px, 100%), 1fr))",
                                    gap: "20px"
                                }}
                            >
                                {sortedServers.map(({ serverName, ontime, delayed, claimed, unclaimed, onTimePercentage }) => (
                                    <Card key={serverName} onClick={() => handleCardClick(serverName)}
                                        sx={{
                                            cursor: 'pointer', backgroundColor: '#ffffff', height: 230, width: "100%", borderRadius: 2, boxShadow: 2
                                        }}
                                    >
                                        <Typography sx={{ textAlign: "center", marginTop: 1, color: "#333" }} variant="h6">{serverName}</Typography>
    
                                        <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 2 }}>
                                            <div
                                                style={{
                                                    display: "grid",
                                                    gridTemplateColumns: "repeat(2, 1fr)",
                                                    gap: "10px",
                                                    rowGap: "20px"
                                                }}
                                            >
                                                <Count label={"On Time"} value={ontime} color="#28a745" />
                                                <Count label={"Delayed"} value={delayed} color="#dc3545" />
                                                <Count label={"Claimed"} value={claimed} color="#007bff" />
                                                <Count label={"Unclaimed"} value={unclaimed} color="#fd7e14" />
                                            </div>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    textAlign: 'center',
                                                    color:
                                                        onTimePercentage <= 50 ? '#dc3545' : 
                                                        onTimePercentage <= 80 ? '#fd7e14' : 
                                                        '#28a745', 
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    padding: '8px 12px', 
                                                    borderRadius: '8px',
                                                    backgroundColor:
                                                        onTimePercentage <= 50 ? 'rgba(220, 53, 69, 0.1)' : 
                                                        onTimePercentage <= 80 ? 'rgba(253, 126, 20, 0.1)' : 
                                                        'rgba(40, 167, 69, 0.1)', 
                                                    width: '100%',
                                                    maxWidth: '70%',
                                                    margin:"auto",
                                                    height:"10px"
                                                }}
                                            >
                                                {onTimePercentage}% On Time
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                ))}
                            </div>
                        </Box>
                </>
            )}
        </Box>
    );
};

function Count({ label, value, color }) {
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "5px", alignItems: "center", fontSize: "10px" }}>
            <div style={{  opacity: "0.8" }}>{label}</div>
            <div style={{ fontSize: "20px", color }}>{value}</div>
        </div>
    );
}

function OntimeOrNot(CreatedTime, CompletedTime, Status) {
    const createdDate = new Date(CreatedTime);
    const now = new Date();

    if (Status === "Completed") {
        const completedDate = new Date(CompletedTime);
        const timeDiff = completedDate - createdDate; // Difference in milliseconds
        const hoursDiff = timeDiff / (1000 * 60 * 60); // Convert to hours
        return hoursDiff <= 24; // If more than 24 hours, return false
    } else {
        const timeDiff = now - createdDate; // Difference between current time and created time
        const hoursDiff = timeDiff / (1000 * 60 * 60); // Convert to hours
        return hoursDiff <= 24;
    }
}

const MetricBox = ({ label, value, color, icon: IconComponent }) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            padding: 1, // Reduce padding to make it more compact
        }}
    >
        <Box
            sx={{
                position: 'relative',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 40, // Decrease icon box size
                height: 40,
                bgcolor: color,
                borderRadius: '50%',
                color: '#fff',
            }}
        >
            <IconComponent sx={{ fontSize: 20 }} /> {/* Smaller icon size */}
        </Box>
        <Tooltip title={label} arrow>
            <Typography sx={{ color: '#333', mt: 0.5, textAlign: 'center', fontSize: 12 }}>{label}</Typography> {/* Smaller label font */}
        </Tooltip>
        <Typography sx={{ color: color, fontWeight: 'bold', fontSize: 14 }}>{value}</Typography> {/* Smaller value font */}
    </Box>
);

const DashboardMetrics = ({ overallData }) => {
    const metrics = [
        { label: 'Total Ticket Raised', value: overallData.filter(ticket => ticket).length || "-", color: '#6A5ACD', icon: DoneAllOutlined },
        { label: 'Unclaimed', value: overallData.filter(ticket => ticket.Status === 'Ticket Created').length || "-", color: '#fd7e14', icon: HourglassEmpty },
        { label: 'Claimed', value: overallData.filter(ticket => ticket.Status === 'Open').length || "-", color: '#007bff', icon: TimerOutlined },
        { label: 'Completed', value: overallData.filter(ticket => ticket.Status === 'Completed').length || "-", color: '#17a2b8', icon: CheckCircleOutline },
        { label: 'Resolution waiting for Approval', value: overallData.filter(ticket => ticket.Status === 'Resolution waiting for Approval').length || "-", color: '#ffc107', icon: NotInterestedOutlined },
        { label: 'Cancelled', value: overallData.filter(ticket => ticket.Status === 'Ticket Rejected').length || "-", color: '#6c757d', icon: CancelOutlined },
        { label: 'Ontime', value: overallData.filter(ticket => OntimeOrNot(ticket.CREATEDTIME, ticket.ResolvedOn, ticket.Status)).length || "-", color: '#28a745', icon: CheckCircleOutline },
        { label: 'Delayed', value: overallData.filter(ticket => !OntimeOrNot(ticket.CREATEDTIME, ticket.ResolvedOn, ticket.Status)).length || "-", color: '#dc3545', icon: ErrorOutline },
    ];

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(8, 1fr)',
                gap: 0.5, // Reduce gap between items to make it more compact
                padding: 1, // Reduce padding around the grid
                borderRadius: 1,
            }}
        >
            {metrics.map((metric) => (
                <MetricBox key={metric.label} {...metric} />
            ))}
        </Box>
    );
};


export default TicketCard;
