//ignorei18n_start
import React, { useState } from "react";
 
import "./History.css";
import HistoryView from "./history_view/HistoryView";
import YearViewBottom from "./year_view_bottom/YearViewBottom";
import YearViewTop from "./year_view_top/YearViewTop";

const data = Object.freeze({
    "milestones": [
        // {
        //     "year": 2005,
        //     "server": null,
        //     "clusters": null,
        //     "core_functionality": null,
        //     "protocol": null
        // },
        {
            "year": 2007,
            "server": "Pserver",
            "clusters": ["PServer", "PSQServer"],
            "core_functionality": "Persistence Server",
            "protocol": null
        },
        {
            "year": 2008,
            "server": "MPServer",
            "clusters": null,
            "core_functionality": "Multi Protocol Server",
            "protocol": ["Yahoo", "GMail", "MSN", "AIM", "ICQ", "QQ", "MySpace", "Facebook"]
        },
        {
            "year": 2009,
            "server": "XMPPServer",
            "clusters": null,
            "core_functionality": "Jabber Server",
            "protocol": ["XMPP Protocol (Server to Server & Client to Server)"]
        },
        {
            "year": 2011,
            "server": "AWServer",
            "clusters": ["AWServer", "AWQServer"],
            "core_functionality": "Device Messaging System , MSync (Microsoft Exchange)",
            "protocol": null
        },
        {
            "year": 2012,
            "server": "AWS Framework",
            "clusters": null,
            "core_functionality": ["HTTP", "WebSocket", "HTTP/2", "QUIC (Under Dev)"],
            "protocol": null
        },
        {
            "year": 2013,
            "server": "DAVServer",
            "clusters": null,
            "core_functionality": "Calendar, Contacts Sync",
            "protocol": ["DAV Protocol - CalDAV, CardDAV"]
        },
        {
            "year": 2013,
            "server": "MSServer",
            "clusters": null,
            "core_functionality": "Mail Sync Server",
            "protocol": ["IMAP"]
        },
        {
            "year": 2013,
            "server": "IRServer",
            "clusters": null,
            "core_functionality": "Inscribe & Relay Server",
            "protocol": ["IMAP", "POP"]
        },
        {
            "year": 2015,
            "server": "HSServer",
            "clusters": null,
            "core_functionality": "HTTP Streaming Server",
            "protocol": ["Twitter", "Dropbox"]
        },
        {
            "year": 2015,
            "server": "WPNServer",
            "clusters": null,
            "core_functionality": "Web Push Notification Server",
            "protocol": ["16 + external services"]
        },
        {
            "year": 2016,
            "server": "ZCAServer",
            "clusters": null,
            "core_functionality": "IRC Server for Chat",
            "protocol": ["IRC Protocol"]
        },
        {
            "year": 2016,
            "server": "PSBServer",
            "clusters": null,
            "core_functionality": "Publish Subscribe System",
            "protocol": null
        },
        {
            "year": 2016,
            "server": "ATServer",
            "clusters": null,
            "core_functionality": "Asynchronous TCP Server",
            "protocol": null
        },
        {
            "year": 2016,
            "server": "NRServer",
            "subtitle": "TURN-Server",
            "clusters": ["API Cluster", "TURN Cluster"],
            "core_functionality": "1-1 Calls",
            "protocol": ["TURN", "STUN"]
        },
        {
            "year": 2017,
            "server": "NRServer",
            "subtitle": "Media-Server",
            "clusters": ["API Cluster", "Media Server"],
            "core_functionality": "Group Call, Broadcast",
            "protocol": ["WebRTC"]
        },
        {
            "year": 2017,
            "server": "NIOClient",
            "clusters": null,
            "core_functionality": "NIO based HTTP, WebSocket Client",
            "protocol": null
        },
        {
            "year": 2018,
            "server": "AMServer",
            "clusters": ["Conference Cluster", "Streaming Cluster", "Post Processing Cluster", "Backup Monitoring Server", "On Demand Media Processor"],
            "core_functionality": "AV Processing Server",
            "protocol": null
        },
        {
            "year": 2018,
            "server": "ZVPNetwork",
            "clusters": null,
            "core_functionality": "NIO based UDP Server framework",
            "protocol": null
        },
        {
            "year": 2018,
            "server": "WSServer",
            "clusters": ["Main Cluster", "POP Cluster", "Data Cluster"],
            "core_functionality": "Web Streaming Server",
            "protocol": null
        },
        {
            "year": 2020,
            "server": "MEXServer",
            "clusters": null,
            "core_functionality": "Meta Exchange Server",
            "protocol": ["SCTP"]
        },
        {
            "year": 2021,
            "server": "RTCPlatform",
            "clusters": null,
            "core_functionality": null,
            "protocol": null
        },
        {
            "year": 2021,
            "server": "RTCPServer",
            "clusters": null,
            "core_functionality": null,
            "protocol": null
        },
        {
            "year": 2021,
            "server": "ZVP Network C",
            "clusters": null,
            "core_functionality": "NIO based UDP / TCP Server / Client Framework in C",
            "protocol": null
        },
        {
            "year": 2021,
            "server": "TURN C",
            "clusters": null,
            "core_functionality": "1-1 Call - C",
            "protocol": ["TURN", "STUN"]
        },
        {
            "year": 2021,
            "server": "RTMPServer",
            "clusters": null,
            "core_functionality": "HD Streaming",
            "protocol": ["RTMP Protocol"]
        },
        {
            "year": 2022,
            "server": "MediaRouter",
            "clusters": null,
            "core_functionality": "Group Call - C",
            "protocol": ["WebRTC"]
        },
        {
            "year": 2023,
            "server": "MEXServer C",
            "clusters": null,
            "core_functionality": "Meta Exchange Server in C",
            "protocol": null
        },
        {
            "year": 2024,
            "server": "AMServer C",
            "clusters": ["Conference Cluster", "Streaming Cluster", "Post Processing Cluster", "On Demand Media Processor"],
            "core_functionality": "C versions of AMS Clusters",
            "protocol": null
        },
        {
            "year": 2024,
            "server": "UTCServer",
            "clusters": null,
            "core_functionality": null,
            "protocol": null,
            "notes": "under development"
        },
        {
            "year": 2024,
            "server": "RTCP Cloud",
            "clusters": ["Cloud Router", "Cloud Analytics", "Cloud Manager"],
            "core_functionality": null,
            "protocol": null,
            "notes": "under development"
        },
        {
            "year": 2024,
            "server": "MGServer",
            "clusters": ["Dial In", "Walkie Talkie"],
            "core_functionality": null,
            "protocol": null,
            "notes": "under development"
        },
        {
            "year": 2024,
            "server": "Rust - FW",
            "clusters": ["NIO TCP / SSL Server"],
            "core_functionality": null,
            "protocol": null,
            "notes": "under development"
        }
    ]
});
 
export default function History() {

    const [scrollAmount, setScrollAmount] = useState(0);
 
    return (
        // <div className={"zvp_milestones_history"}>
        <div className={"page-content-8"}>
        
            <YearViewTop scrollAmount={scrollAmount} setScrollAmount={setScrollAmount} data={data} />
            <HistoryView scrollAmount={scrollAmount} setScrollAmount={setScrollAmount} data={data} />
            <YearViewBottom scrollAmount={scrollAmount} setScrollAmount={setScrollAmount} data={data} />
        </div>
    );
 
}


export function findRowSpan(arr) {
    const result = new Array(arr.length).fill(0); // Initialize result array with zeros
    let currentCount = 1; // Counter for occurrences
  
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] === arr[i - 1]) {
        currentCount++; // Increment counter if same element as previous
      } else {
        result[i - 1] = currentCount; // Store count at previous element index
        currentCount = 1; // Reset counter for new element
      }
    }
  
    result[arr.length - 1] = currentCount; // Handle the last element
  
    return result;
  }
//ignorei18n_end