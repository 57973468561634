import React, { useState, useEffect, useRef, useLayoutEffect, useContext } from 'react';
import InfoDiv from './InfoDiv';


import { Button, ButtonGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
// import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CancelIcon from '@mui/icons-material/Cancel';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';
import { keyframes } from '@mui/system';
import RemoveIcon from '@mui/icons-material/Remove';
import clipboardCopy from 'clipboard-copy';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import TableSortLabel from "@mui/material/TableSortLabel";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';

import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tooltip from '@mui/material/Tooltip';
import BlockIcon from '@mui/icons-material/Block';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import { group_by_order, find_row_span } from './../../../util/GroupingFunctions.js';
import { getFromTaskDetails } from './MilestoneDataFetcher.js';

import { UserContext } from '../../../contexts/UserContext.js';
import { GetNameAndEmpID, days_between, MultiLevelDropdown } from '../../../util/Loading.js';

import { Skeleton } from '@mui/material';
import { Fade } from '@mui/material';

import { useTheme } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { queryDB } from "../../../util/QueryDB";
import './Milestone.css';
import { TabStateContext } from '../../../contexts/TabStateContext';
import AllOutIcon from '@mui/icons-material/AllOut';
import { getFromTeamDetails } from '../taskBreaker/TBDataFetcher.js';

import { lazyFetch, lazyLoader } from '../../../util/QueryDB.js';
// import {QuarterlyInfo} from 'quarterly/QuarterlyInfo.js';
import { QuarterlyInfo } from '../quartely/QuarterlyInfo.js'
import { milestoneTransformFunction } from './MilestoneDataFetcher.js';
import { TabDetails } from '../../../../src/contexts/TabStateContext.js'
import { sort } from 'd3';
import { milestone_main_component_get_query_1, milestone_main_component_get_query_2, milestone_main_component_get_query_3, milestone_main_component_get_query_4, milestone_main_component_get_query_5, milestone_main_component_get_query_6 } from '../../../catalystDB/queryGenerator.js';
import { OtherDRI } from '../../../util/OtherDRI.js';
import TaskList from './TaskList.js';
import { MilestoneComponent } from '../../../util/components/MilestoneComponent/MilestoneComponent.js';
import { MilestoneView } from '../../../util/components/verticalTimeline/MilestoneView.js';
import Tasks from './Task.js';

export default function Milestone() {
	const [searchText, setSearchText] = useState("");
	const [rows, setRows] = useState([]);
	const [hasData, setHasData] = useState(null);
	const [initialRows, setInitialRows] = useState([]);
	const { userDetails, teamDetails, allServers, loginUserDept, configuration,GlobalServer } = useContext(UserContext);
	// console.log(GlobalServer.current);
	const [serversList, setServersList] = useState([]);
	
	const user_email = userDetails.current.auth.email_id;
	const localStorageFetchedServer = localStorage.getItem('selectedServerMilestone');
	var user_servers = (GlobalServer === "unknown" || GlobalServer === undefined) ? (localStorageFetchedServer !== null) ? localStorageFetchedServer: teamDetails.current[user_email].Servers.split(",").filter(server => allServers.includes(server)).join(",") : GlobalServer;
	var allServer = teamDetails.current[user_email].Servers.split(",").filter(server => allServers.includes(server)).join(",");

	// console.log(GlobalServer);
	// var user_servers = (GlobalServer === "unknown" || GlobalServer === undefined) ?  teamDetails.current[user_email].Servers.split(",").filter(server => allServers.includes(server)).join(",")
    // : GlobalServer;
	
	
	// const user_servers = teamDetails.current[user_email].Servers.split(",").filter(server => {
	// 	return allServers.includes(server);
	// }).join(",");
	const queryStringSuffix = "('" + user_servers.replaceAll(",", "','") + "')";
	const query = milestone_main_component_get_query_1(user_email, queryStringSuffix,loginUserDept);
	const [sortBy, setSortBy] = useState("Default");
	const toggle = useRef(true); 

	const urlParams = new URLSearchParams(window.location.search);
	const milestoneIdParam = urlParams.get('milestoneId');
	const tasklistIdParam = urlParams.get('tasklistId');
	const UserserverArray = teamDetails.current[user_email].Servers.split(",").filter(server => allServers.includes(server)).join(",");

	// const localStorageValue = localStorage.getItem('filterByValue');

	
	//console.log(localStorageValue);

	// const defaultServerArr = teamDetails.current[user_email].Servers.split(",");
	// const initialUserServerArr = localStorageValue ? JSON.parse(localStorageValue) : defaultServerArr;
	// const [filterBy, setFilterBy] = useState(initialUserServerArr);

	// localStorage.setItem('filterByValue', JSON.stringify(filterBy));

	const userserverArr = user_servers.split(",");
	const [filterBy, setFilterBy] = useState(userserverArr);
	
	// const userserverArr = teamDetails.current[user_email].Servers.split(",");
	// const [filterBy, setFilterBy] = useState(userserverArr);

	// const serverFilter = filterBy.filter(item => !item.includes("@zohocorp.com"));
	// const driFilter = filterBy.filter(item => item.includes("@zohocorp.com"));

	let url = `#${TabDetails.tasks.segment}${TabDetails.tasks.innerTabsList.milestone.segment}`;

	// let url = `#${TabDetails.tasks.segment}${TabDetails.tasks.innerTabsList.milestone.segment}`;



	// if (serverFilter.length > 0 || driFilter.length > 0) {
	// const queryParams = [];
	// if (serverFilter.length > 0) {
	// 	queryParams.push(`serverFilter=${JSON.stringify(serverFilter)}`);
	// 	// console.log(serverFilter)
	// }
	// if (driFilter.length > 0) {
	// 	queryParams.push(`driFilter=${JSON.stringify(driFilter)}`);
	// }
	// url += `?${queryParams.join('&')}`;
	// }

	window.location.hash = url;

	
	
	


	const [driList, setDriList] = useState([]);
	const [driTree, setDriTree] = useState([]);

	const compareFunctions = {
		"Server": serverCompareFunction,
		"Type": typeCompareFunction,
		"MilestoneName": milestoneNameCompareFunction,
		"TasklistName": tasklistNameCompareFunction,
		"Date": dateCompareFunction,
		"DRI": driCompareFunction
	}

	const [lazyLoad, setLazyLoad] = useState(lazyLoader(query, milestoneTransformFunction));

	// var initialRows = [];

	// useEffect(() => {
	// 	async function fetchData() {
	// 		try {
	// 			// await setDriDropDown(setDriList, userDetails, teamDetails);
	// 			await setDriDropDown(setDriList, setDriTree, userDetails, teamDetails, loginUserDept.current, configuration);
	// 			await setServerDropDown(setServersList, userDetails, teamDetails, allServers);
	// 		}
	// 		catch (error) {
	// 		}

	// 		if (lazyLoad !== null) {
	// 			try {
	// 				// const result = await getFromTaskDetails(`SELECT * FROM TaskDetails WHERE DRI= 'jayasuriya.bm@zohocorp.com'`);
	// 				const result = await lazyLoad.next();
	// 				// console.log(result.value.content)
	// 				// setInitialRows(result);
	// 				// setHasData(true);

	// 				if (result.value.content === undefined) {
	// 					setHasData(false);
	// 					setLazyLoad(null);
	// 				} else if (result.value.isLast) {
	// 					setHasData(true);
	// 					setInitialRows(result.value.content);
	// 					setLazyLoad(null);
	// 				} else {
	// 					setHasData(true);
	// 					setInitialRows(result.value.content);
	// 				}
	// 			}
	// 			catch (error) {
	// 				setHasData(false);
	// 			}
	// 		}

	// 		intialFilterSet();
	// 	}
	// 	fetchData();
	// 	// return async () => {
	// 	// 	const result = await getFromTaskDetails(`SELECT * FROM TaskDetails WHERE DRI IS NOT NULL`);
	// 	// }
	// }, [GlobalServer === "unknown" || GlobalServer === undefined]);

	useEffect(() => {
		  async function fetchData() {
			try {
			  await setDriDropDown(setDriList, setDriTree, userDetails, teamDetails, loginUserDept.current, configuration);
			  await setServerDropDown(setServersList, userDetails, teamDetails, allServers);
	  
			  if (lazyLoad !== null && ((GlobalServer === "unknown" || GlobalServer === undefined) && localStorage.getItem('selectedServerMilestone') === null)) {
				try {
				  const result = await lazyLoad.next();
	  
				  if (result.value.content === undefined) {
					setHasData(false);
					setLazyLoad(null);
				  } else if (result.value.isLast) {
					setHasData(true);
					setInitialRows(result.value.content);
					setLazyLoad(null);
				  } else {
					setHasData(true);
					setInitialRows(result.value.content);
				  }
				} catch (error) {
				  setHasData(false);
				}
			  }
	  
			  intialFilterSet();
			} catch (error) {
			  // Handle any error that occurred during data fetching
			}
		  }
	  
		  fetchData();
		// The cleanup function can be added if necessary
	  
	  }, []);

 	
async function intialFilterSet() {
		var toggleClosedFilter = "";
		if(toggle.current == true){
			 toggleClosedFilter = " (MilestoneStatus not in ('Completed','Cancelled')) AND (Approval_Status not in ('Waiting For Approval') OR Approval_Status is null)";
		}
		else if(toggle.current == false){
			toggleClosedFilter = " (MilestoneStatus in ('Completed','Cancelled')) ";
		}
		else if (toggle.current == null){
            toggleClosedFilter = " (Approval_Status in ('Waiting For Approval')) ";
		}
		// const toggleClosedFilter = toggle.current ? " (MilestoneStatus in ('Completed','Cancelled')) " : " (MilestoneStatus not in ('Completed','Cancelled')) ";
		// setSearchText("");
		if (filterBy.includes("Default")) {
			const qString = milestone_main_component_get_query_2(user_email, queryStringSuffix, toggleClosedFilter, loginUserDept);
			try {
				setHasData(null);
				const newLazyLoader = lazyLoader(qString, milestoneTransformFunction);
				setLazyLoad(newLazyLoader);
				const result = await newLazyLoader.next();
				
				if (result.value.content === undefined) {
					setHasData(false);
					setLazyLoad(null);
				} else if (result.value.isLast) {
					setHasData(true);
					setInitialRows(result.value.content);
					setLazyLoad(null);
				} else {
					setHasData(true);
					setInitialRows(result.value.content);
				}
			}
			catch (error) {
				console.log(error);
				setHasData(false);
				setLazyLoad(null);
			}
		}
		else if (filterBy.includes("Status")) {
			const qString = milestone_main_component_get_query_3(user_email, queryStringSuffix, toggleClosedFilter);
			try {
				setHasData(null);
				const newLazyLoader = lazyLoader(qString, milestoneTransformFunction);
				setLazyLoad(newLazyLoader);
				const result = await newLazyLoader.next();
				const combinedData = [];
					  
					  
				for (let i = 0; i <result.value.content.length; i++) {
				if (result.value.content[i][15] === "Updated to IDC") {
					console.log(result.value.content[i][15])
				     combinedData.push(result.value.content[i]);
				}
				}
				
			
				for (let i = 0; i < result.value.content.length; i++) {
				if (result.value.content[i][15] !== "Updated to IDC") {
				  combinedData.push(result.value.content[i]);
				}
				}


				if (result.value.content === undefined) {
					setHasData(false);
					setLazyLoad(null);
				} else if (result.value.isLast) {
					setHasData(true);
					setInitialRows(combinedData);
					setLazyLoad(null);
				} else {
					setHasData(true);
					setInitialRows(combinedData);
				}
			}
			catch (error) {
				console.log(error);
				setHasData(false);
				setLazyLoad(null);
			}
		}
		else {
			// var filters = [];
			// for(var item of filterBy) {
			// 	if(item.includes("@zohocorp.com")) {
			// 		filters.push(`Email= '${item}'`);
			// 	} else {
			// 		filters.push(`Component= '${item}'`);
			// 	}
			// }
			

			var driFilter = [];
			var componentFilter = [];
			for (var item of filterBy) {
				// console.log(item)

				if (item.includes("@zohocorp.com")) {
					driFilter.push(item);
				} else {
					if(GlobalServer === "unknown" || GlobalServer === undefined){
						componentFilter.push(item);
					}
					else{
						componentFilter.push(GlobalServer);
					}
				}
			}
			var queryFilter = "";
			if (componentFilter.length === 0 && driFilter.length !== 0) {
				queryFilter = ` ( Email in ('${driFilter.join("','")}') ) `;
			} else if (driFilter.length === 0 && componentFilter.length !== 0) {
				queryFilter = ` ( Component in ('${componentFilter.join("','")}') ) `;
			} else if (componentFilter.length !== 0 && driFilter.length !== 0) {
				queryFilter = ` ( Email in ('${driFilter.join("','")}') AND  Component in ('${componentFilter.join("','")}') ) `;
			}
			var qString = milestone_main_component_get_query_4(queryFilter, toggleClosedFilter, loginUserDept);

			setHasData(null);
			const newLazyLoader = lazyLoader(qString, milestoneTransformFunction);
			setLazyLoad(newLazyLoader);
			const result = await newLazyLoader.next();
			if (result.value.content === undefined) {
				setLazyLoad(null);
				setHasData(false);
			} else if (result.value.isLast) {
				setInitialRows(result.value.content);
				setLazyLoad(null);
				setHasData(true);
			} else {
				setInitialRows(result.value.content);
				setHasData(true);
			}
			// console.log(result.value.content)
		}
	}

	// const rowsNew = group_by_order(initialRows, [0, 4], 0);
	window.addEventListener('beforeunload', () => {
        localStorage.removeItem('selectedServerMilestone');
    });

	var rowsNew = group_by_order(initialRows, [0], 0);
	const hookFlag = useRef(true);

	useEffect(() => {
		if (hookFlag.current) {
			hookFlag.current = false;
			return;
		}
		intialFilterSet();
		// setInterval(_ => {
		// 	// console.log("hlo");
		// 	AutomaticRefresh();
		//   }, 10*1000);
	}, [filterBy, toggle.current]);

	useEffect(() => {
		if(GlobalServer != "unknown" && GlobalServer === undefined){
            intialFilterSet(); 
			setFilterBy(userserverArr)
		}
		else if(GlobalServer != "unknown" && GlobalServer != undefined){
            intialFilterSet(); 
			setFilterBy([GlobalServer])
		}
		// console.log("hi")
	  }, [GlobalServer]);

	useEffect(() => {
		if (sortBy !== "Default") {
			rowsNew = rowsNew.sort(compareFunctions[sortBy]);
			// console.log(rowsNew)
		}
		setRows(rowsNew);
	}, [initialRows, sortBy]);

	const theme = useTheme();
	const [openMilestoneView, setOpenMilestoneView] = useState(milestoneIdParam !== null ? true : false);
	const [openTasklistView, setOpenTasklistView] = useState(tasklistIdParam !== null ? true : false);

	//var content = <div style={{textAlign: "center", marginTop: "35vh"}}><LoadingComponent /></div>;
	var content = (
		<>
			<MilestoneTopBar rowsCurrent={rowsNew} setRows={setRows} setSearchText={setSearchText} setHasData={setHasData}
				toggle={toggle} drisList={driList} drisTree={driTree} serversList={serversList.sort()} setInitialRows={setInitialRows} query={query}
				lazyLoad={lazyLoad} setLazyLoad={setLazyLoad} sortBy={sortBy} setSortBy={setSortBy} filterBy={filterBy} setFilterBy={setFilterBy}
				searchText={""} GlobalServer={GlobalServer} allServer={allServer}/>
			{/* <Skeleton variant="rectangular" width={1600} height={60} />; */}
			<Skeleton variant="rectangular" width={"100%"} sx={{ flex: 1 }} />
		</>
	);


	if (initialRows.length !== 0 && hasData) {
		content = (
			<Fade in={true}>
				<div>
					<MilestoneTopBar rowsCurrent={rowsNew} setRows={setRows} setSearchText={setSearchText} setHasData={setHasData}
						toggle={toggle} drisList={driList} drisTree={driTree} serversList={serversList.sort()} setInitialRows={setInitialRows} query={query}
						lazyLoad={lazyLoad} setLazyLoad={setLazyLoad} sortBy={sortBy} setSortBy={setSortBy} filterBy={filterBy} setFilterBy={setFilterBy} searchText={searchText} UserserverArray={UserserverArray} GlobalServer={GlobalServer} allServer={allServer}/>
					<MilestoneTable toggle={toggle} hasData={hasData} rows={rows} searchText={searchText} rowsCurrent={rowsNew} setRows={setRows} lazyLoad={lazyLoad} setInitialRows={setInitialRows} initialRows={initialRows}
						setLazyLoad={setLazyLoad} filterBy={filterBy} setFilterBy={setFilterBy} />
				</div>
			</Fade>
		);
	}
	else if (hasData === false) {
		content = (
			<Fade in={true}>
				<div style={{ color: theme.palette.background.text }}>
					<MilestoneTopBar rowsCurrent={rowsNew} setRows={setRows} setSearchText={setSearchText} setHasData={setHasData}
						toggle={toggle} drisList={driList} drisTree={driTree} serversList={serversList.sort()} setInitialRows={setInitialRows} query={query}
						lazyLoad={lazyLoad} setLazyLoad={setLazyLoad} sortBy={sortBy} setSortBy={setSortBy} filterBy={filterBy} setFilterBy={setFilterBy} searchText={searchText} UserserverArray={UserserverArray} GlobalServer={GlobalServer} allServer={allServer}/>
					<h1>No Milestone Found :(</h1>
				</div>
			</Fade>
		);
	}

	return (
		<div className="page-content-2">
			{content}
			{
				openMilestoneView ? 
					<MilestoneView open={true} setOpen={setOpenMilestoneView} milestone_id={milestoneIdParam} servers={[]} index={0} />
					:
					null
			}
			{
				openTasklistView ? 
					(
						<Dialog
							fullScreen
							open={true}
							onClose={() => setOpenTasklistView(false)}
							PaperProps={{
								style: {
									backgroundColor: theme.palette.background.pageContent,
									//   backdropFilter: "blur(800000px)",
									boxShadow: "none"
								}
							}}
						>
							<DialogContent>
								<Tasks tasklistId={tasklistIdParam} milestoneName={""} tasklistName={""} serverName={""}/>
							</DialogContent>
						</Dialog>
					)
					:
					null
			}
		</div>
	);
}

function MilestoneTopBar({ toggle, setRows, rowsCurrent, setSearchText, drisList, drisTree, serversList, setHasData, setInitialRows, query, lazyLoad, setLazyLoad, sortBy, setSortBy, filterBy, setFilterBy, searchText,UserserverArray,GlobalServer,allServer}) {

	// const drisMenuList = drisList.map((item, index) => {
	// 	return <MenuItem key={index} onClick={() => handleDriItemClick(item)}>{item}</MenuItem>
	// });

	const serversMenuList = serversList.map((item, index) => {
		return <MenuItem key={index} onClick={() => handleServerItemClick(item)}>{item}</MenuItem>
	});

	const [sortAnchor, setSortAnchor] = useState(null);
	const [filterAnchor, setFilterAnchor] = useState(null);
	const [serverAnchor, setServerAnchor] = useState(null);
	const [open, setOpen] = useState(false);
	const [driAnchor, setDriAnchor] = useState(null);
	const [minimize, setMinimize] = useState(false);

	// ----------------------------------------------------------
	//const [anchorEl, setAnchorEl] = useState(null);
	const [openPopup, setOpenPopup] = useState(false);
	const [iframeLoaded, setIframeLoaded] = useState(false);

	const handleMenuOpen = (event) => {
		if (minimize) {
			setMinimize(false);
		} else {
			setOpenPopup(true);
		}
		//setAnchorEl(event.currentTarget);
		setOpenPopup(true);
	};

	// const handleMenuClose = () => {
	// 	setAnchorEl(null);
	// };

	// const handleAddItemClick = (event) => {
	// 	if (event.target.textContent === 'Create New Milestone') {
	// 		setOpenPopup(true);
	// 	}
	// 	handleMenuClose();
	// }

	const handleClosePopup = () => {
		setFilterBy([...filterBy]);
		setOpenPopup(false);
		setIframeLoaded(false);
	};

	const handleIframeLoad = () => {
		setIframeLoaded(true);
	};


	function handleDriButtonClose() {
		setDriAnchor(null);
		setFilterAnchor(null);
	}

	function handleEscKeyPress(event) {
		if (event.key === "Escape") {
			// setSearchText("");
			setOpen(false);
		}
	}

	// ----------------------------------------------------------

	// useLayoutEffect(() => {

	// 	var rowsNew = rowsCurrent.filter(row_ => {
	// 		if(row_[7] === 4)
	// 			{
	// 				return false;
	// 			}
	// 			return true;
	// 	});

	// 	setRows(rowsNew);

	// }, []);

	function handleSortButtonClick(event) {
		setSortAnchor(event.currentTarget);
	}

	function handleSortButtonClose() {
		setSortAnchor(null);
	}

	function handleSearchButtonClick() {
		// setSearchText("");
		setOpen(!open);
	}

	async function handleFilterItemClick(event, item) {
		if (item === "Server") {
			setServerAnchor(event.currentTarget);
		}
		else if (item === "Dri") {
			setDriAnchor(event.currentTarget)
		}
		else if (item === "Status") {
			setFilterBy(["Status"]);
		}
		else if (item === "Default") {
			// try {
			// 	setHasData(null);
			// 	// console.log(query);
			// 	const result = await getFromTaskDetails(query);
			// 	setInitialRows(result);
			// 	setHasData(true);
			// }
			// catch (error) {
			// 	console.log(error);
			// 	setHasData(false);
			// }
			setFilterBy(["Default"]);
		}

	}

	// async function handleDriItemClick(item) {
	// 	try {
	// 		setHasData(null);
	// 		const result = await getFromTaskDetails(`SELECT * FROM TaskDetails WHERE Email = '${item.Email}'`);
	// 		setInitialRows(result);
	// 		setHasData(true);
	// 	}
	// 	catch (error) {
	// 		setHasData(false);
	// 	}
	// }

	const handleDriTreeItemClick = async (item) => {
		// try {
		// 	setHasData(null);
		// 	const result = await getFromTaskDetails(`SELECT * FROM TaskDetails WHERE Email = '${item.Email}'`);
		// 	setInitialRows(result);
		// 	setHasData(true);
		// 	//setCloseMenu(true);
		// }
		// catch (error) {
		// 	setHasData(false);
		// };
		var tempFilterBy = [...filterBy];
		var index = tempFilterBy.indexOf("Default");
		if (index > -1) {
			tempFilterBy.splice(index, 1);
		}
		index = tempFilterBy.indexOf(item.Email);
		if (index > -1) {
			tempFilterBy.splice(index, 1);
		}
		setFilterBy([item.Email, ...tempFilterBy]);
	};

	function handleFilterButtonClick(event) {
		setFilterAnchor(event.currentTarget);
	}

	function handleFilterButtonClose() {
		setFilterAnchor(null);
	}

	async function handleServerItemClick(item) {
		// var rowsNew = rowsCurrent.filter(row_ => {
		// 	if(row_[2] === item)
		// 	{
		// 		return true;
		// 	}
		// 	return false;
		// });
		// setRows(rowsNew);

		// try {
		// 	setHasData(null);
		// 	const result = await getFromTaskDetails(`SELECT * FROM TaskDetails WHERE Component = '${item}'`);
		// 	// console.log(result);
		// 	setInitialRows(result);
		// 	setHasData(true);
		// }
		// catch (error) {
		// 	console.log(error);
		// 	setHasData(false);
		// }

		// setServerAnchor(null);
		// setFilterAnchor(null);

		var tempFilterBy = [...filterBy];
		var index = tempFilterBy.indexOf("Default");
		if (index > -1) {
			tempFilterBy.splice(index, 1);
		}
		index = tempFilterBy.indexOf(item);
		if (index > -1) {
			tempFilterBy.splice(index, 1);
		}
		// console.log("tempFilterBy")
		// console.log(item)

		// setFilterBy([item, ...tempFilterBy]);
		setFilterBy([item]);
		localStorage.setItem('selectedServerMilestone', item);
	}

	function handleSortItemClick(item) {
		setSortAnchor(null);
		setSortBy(item);
		// if (item === "Default") {
		// 	const rowsNew = group_by_order(rowsCurrent, [0, 4], 0);
		// 	setRows(rowsNew);
		// }
		// else if (item === "Server") {
		// 	var rowsNew = [...rowsCurrent];
		// 	rowsNew.sort(serverCompareFunction);
		// 	setRows(rowsNew);
		// }
		// else if (item === "Type") {
		// 	var rowsNew = [...rowsCurrent];
		// 	rowsNew.sort(typeCompareFunction);
		// 	setRows(rowsNew);
		// }
		// else if (item === "MilestoneName") {
		// 	var rowsNew = [...rowsCurrent];
		// 	rowsNew.sort(milestoneNameCompareFunction);
		// 	setRows(rowsNew);
		// }
		// else if (item === "TasklistName") {
		// 	var rowsNew = [...rowsCurrent];
		// 	rowsNew.sort(tasklistNameCompareFunction);
		// 	setRows(rowsNew);
		// }
		// else if (item === "TotalTasks") {
		// 	var rowsNew = [...rowsCurrent];
		// 	rowsNew.sort(totalTasksCompareFunction);
		// 	setRows(rowsNew);
		// }
		// else if (item === "DRI") {
		// 	var rowsNew = [...rowsCurrent];
		// 	rowsNew.sort(driCompareFunction);
		// 	setRows(rowsNew);
		// }
	}

	function handleToggleClosedButtonClick() {
		// toggle.current = !toggle.current;
		if(toggle.current == true){
			toggle.current = false;
	   }
	   else if(toggle.current == false){
		    toggle.current = null;
	   }
	   else if (toggle.current == null){
		    toggle.current = true;
	   }
		// var rowsNew = rowsCurrent.filter(row_ => {
		// 	if (toggle.current) {
		// 		if (row_[7] === "Completed") {
		// 			return true;
		// 		}
		// 		return false;
		// 	}
		// 	else {
		// 		if (row_[7] === "Completed") {
		// 			return false;
		// 		}
		// 		return true;
		// 	}
		// });
		setHasData(null);
		// setRows(rowsNew);
	}

	function handleSearchTextChange(event) {
		setSearchText(event.target.value);
	}

	function handleServerButtonClose() {
		setServerAnchor(null);
		setFilterAnchor(null);
	}

	function handleMinimizeClick(event) {
		setMinimize(true);
	}

	const grow = keyframes`
	0% {
		scale: 1;
	}
	20% {
		scale: 1.2;
		rotate: 0deg;
	}
	35% {
		rotate: -20deg;
	}
	45% {
		rotate: 20deg;
	}
	65% {
		rotate: -20deg;
	}
	80% {
		scale: 1.2;
		rotate: 0deg;
	}
	100% {
		scale: 1;
	}
	`;

	function handleChipClick(event, item) {
		if (event.detail === 2) {
			setFilterBy([item]);
			localStorage.setItem('selectedServerMilestone', item); // Store the selected server
		}
	}
// console.log(GlobalServer)
	
	return (
		<div className="milestone-top-bar">
			<div className="milestone-top-bar-left-group" style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				gap: "20px"
			}}>
				<Button variant="contained" size="small" onClick={handleMenuOpen} sx={{
					borderRadius: "100px",
					padding: 0.9,
					paddingRight: 1.2,
					paddingLeft: 1.2
				}}>
					{
						minimize ?
							<OpenInFullRoundedIcon sx={{
								animation: `${grow} 1s ease`
							}} />
							:
							<AddIcon sx={{ fontSize: "1.4rem" }} />
					}
				</Button>
				{/* <Button variant="contained" size="small">
					<EditNoteIcon />
				</Button> */}
				{/* <Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleMenuClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
				>
					<MenuItem onClick={handleAddItemClick}>Create New Milestone</MenuItem>
				</Menu> */}
				{/* <ButtonGroup variant="contained" aria-label="outlined primary button group">
					<Button variant="contained" size="small"><AddIcon /></Button>

					<Button variant="contained" size="small"><EditNoteIcon /></Button>
				</ButtonGroup> */}

				<Box style={{
					display: "flex",
					flexDirection: "row",
					gap: "10px",
					width: "calc(50vw)",
					overflowX: "scroll"
				}}
					className="filter-sort-overflow-container"
				>
					{
						searchText !== "" ?
							<Chip
								label={
									<Box style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "center",
										alignItems: "center"
									}}>
										<Box style={{
											color: "#0c2c70",
											borderRight: "1px solid #103fa3",
											paddingRight: "6px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center"
										}}>
											<SearchIcon />
										</Box>
										<Box style={{
											paddingLeft: "6px",
											color: "ghostwhite"
										}}>
											{searchText}
										</Box>
									</Box>
								}
								sx={{
									bgcolor: "#226DB4 !important",
									border: "1px solid #0c2c70"
								}}
								onDelete={() => {
									setSearchText("");
								}}
								deleteIcon={
									<CancelIcon
										onMouseDown={(event) => event.stopPropagation()}
										sx={{
											color: "#0c2c70 !important"
										}}
									/>
								}
							/>
							:
							<></>
					}
					{
						sortBy !== "Default" ?
							<Chip
								label={
									<Box style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "center",
										alignItems: "center"
									}}>
										<Box style={{
											color: "#1c5326",
											borderRight: "1px solid #1c5326",
											paddingRight: "6px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center"
										}}>
											<SortIcon />
										</Box>
										<Box style={{
											paddingLeft: "6px",
											color: "ghostwhite",
											width: "70px",
											overflow: "hidden",
											textOverflow: "ellipsis",
											textAlign: "center"
										}}>
											<Tooltip title={sortBy}>
												{sortBy}
											</Tooltip>
										</Box>
									</Box>
								}
								sx={{
									bgcolor: "rgb(8,153,73,0.9) !important",
									border: "1px solid #1c5326"
								}}
								onDelete={() => {
									setSortBy("Default");
								}}
								deleteIcon={
									<CancelIcon
										onMouseDown={(event) => event.stopPropagation()}
										sx={{
											color: "#1c5326 !important"
										}}
									/>
								}
							/>
							:
							<></>
					}
					{
						!filterBy.includes("Default") ?
							filterBy.map((item, index) => {
								return (
									<Chip
										key={index}
										label={
											<Box style={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "center",
												alignItems: "center"
											}} onClick={(event) => handleChipClick(event, item)}>
												<Box style={{
													color: "#331511",
													borderRight: "1px solid #53291c",
													paddingRight: "6px",
													display: "flex",
													alignItems: "center",
													justifyContent: "center"
												}}>
													<FilterAltOutlinedIcon />
												</Box>
												<Box style={{
													paddingLeft: "6px",
													color: "ghostwhite",
													width: "70px",
													overflow: "hidden",
													textOverflow: "ellipsis",
													textAlign: "center"
												}}>
													<Tooltip title={item}>
														{item}
													</Tooltip>
												</Box>
											</Box>
										}
										sx={{
											// bgcolor: "rgb(228,37,39, 0.9) !important"
											background: "rgba(177,44,9,1) !important",
											border: "1px solid #53291c"
										}}
										onDelete={() => {
											localStorage.removeItem('selectedServerMilestone');
											var tempFilterBy = [...filterBy];
											var index = tempFilterBy.indexOf(item);
											
											if (index > -1) {
												tempFilterBy.splice(index, 1);
												setFilterBy(tempFilterBy);
											}
											if (tempFilterBy.length === 0) {
												
												tempFilterBy.push("Zone");
												UserserverArray = localStorage.getItem('selectedServerMilestone') !== null ? [localStorage.getItem('selectedServerMilestone')] : allServer.split(',');
												setFilterBy(UserserverArray);
											}
											else if(tempFilterBy.length != 0){
												setFilterBy(tempFilterBy);
											}
											// UserserverArray.map((data, dataIndex) => {
											// 	console.log(tempFilterBy)
											// })
										}}
										deleteIcon={
											(GlobalServer === "unknown" ||  GlobalServer === undefined) ? (
												<CancelIcon
												onMouseDown={(event) => event.stopPropagation()}
												sx={{
													color: "#53291c !important"
												}}
											/>
											) : (
												<></>
											)
										}
									/>
								);
							})
							:
							<></>
					}
					<div style={{
					}} className="unselectable">
						&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
					</div>
				</Box>


				<Dialog open={openPopup} onClose={handleClosePopup} sx={{
					// zIndex: minimize ? -1 : 1300
					display: minimize ? "none" : "block"
				}} fullScreen>
					<DialogActions>
					{!navigator.onLine && (
					<Alert sx={{marginRight:"669px",marginTop:"7px",width:"247px",alignItems:"center",textAlign:"center",justifyContent:"center"}} severity="error">Kindly check you network connection.</Alert>
					)}
						<Button onClick={handleMinimizeClick} variant="text" fontSize="large"><RemoveIcon /></Button>
						<Button onClick={handleClosePopup} variant="text" fontSize="large"><CloseIcon /></Button>
					</DialogActions>
					<DialogContent>
						{!iframeLoaded && (
							<div
								style={{
									position: 'relative',
									width: '100%',
									height: '100%',
									backgroundImage: 'linear-gradient(to right, #f0f0f0, #e0e0e0)',
									borderRadius: 0,
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Skeleton
									variant="rectangular"
									width="100%"
									height="100%"
									animation="wave"
								/>
							</div>
						)}
						<iframe
							src="https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/Milestone_Duplicate_Form?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true"
							width="100%"
							height="100%"
							title="External Content"
							frameBorder={0}
							onLoad={handleIframeLoad}
							style={{ display: iframeLoaded ? 'block' : 'none' }}
						/>
					</DialogContent>
				</Dialog>

			</div>
			<div className="milestone-top-bar-right-group">
				<ButtonGroup variant="contained">
					<Collapse in={open} timeout="auto" orientation="horizontal" unmountOnExit>
						<TextField variant="outlined" placeholder="search" onChange={handleSearchTextChange} value={searchText} autoFocus onKeyDown={handleEscKeyPress} />

					</Collapse>
					<Tooltip title="search"><Button variant="contained" size="small" onClick={handleSearchButtonClick}><SearchIcon /></Button></Tooltip>
					{/* <Tooltip title="toggle closed milestones"><Button variant={!toggle.current ? "contained" : "outlined"} size="small" onClick={handleToggleClosedButtonClick}><BlockIcon /></Button></Tooltip> */}
					<Tooltip title={"toggle open/closed/Waiting For approval Milestone"}>
						<Button variant="contained" size="small" onClick={handleToggleClosedButtonClick}>
							{ 
                    toggle.current === true ? 
                        <AllOutIcon fontSize='medium' /> 
                        :
						toggle.current === false ?
                        <BlockIcon fontSize='medium' />
                        :
						toggle.current === null ?
                        <RemoveIcon fontSize='medium' />
                        :
                        <RemoveIcon fontSize='medium' />
                }
							{/* {!toggle.current ? <AllOutIcon /> : <BlockIcon />} */}
							{/* <BlockIcon />s */}
						</Button>
					</Tooltip>
					<Tooltip title="filter by"><Button variant="contained" size="small" onClick={handleFilterButtonClick}><FilterAltOutlinedIcon /><ArrowDropDownIcon /></Button></Tooltip>
					<Tooltip title="sort by"><Button variant="contained" size="small" onClick={handleSortButtonClick}><SortIcon /><ArrowDropDownIcon /></Button></Tooltip>
				</ButtonGroup>
				<Menu
					anchorEl={sortAnchor}
					open={sortAnchor !== null}
					onClose={handleSortButtonClose}
				>
					<MenuItem onClick={() => handleSortItemClick("Default")}>Default</MenuItem>
					{(GlobalServer !== "unknown" && GlobalServer !== undefined) ? (
						<></>
					):(
						<MenuItem onClick={() => handleSortItemClick("Server")}>Server</MenuItem>	
					)}
					{/* <MenuItem onClick={() => handleSortItemClick("Server")}>Server</MenuItem> */}
					<MenuItem onClick={() => handleSortItemClick("Type")}>Type</MenuItem>
					<MenuItem onClick={() => handleSortItemClick("MilestoneName")}>MilestoneName</MenuItem>
					<MenuItem onClick={() => handleSortItemClick("Date")}>Date</MenuItem>
					{/* <MenuItem onClick={() => handleSortItemClick("TotalTasks")}>Total Tasks</MenuItem> */}
					<MenuItem onClick={() => handleSortItemClick("DRI")}>DRI</MenuItem>
				</Menu>
				<Menu
					anchorEl={filterAnchor}
					open={filterAnchor !== null}
					onClose={handleFilterButtonClose}
				>
					<MenuItem onClick={(event) => handleFilterItemClick(event, "Default")} style={{ justifyContent: "center" }}>Default</MenuItem>
					<MenuItem onClick={(event) => handleFilterItemClick(event, "Dri")}><ArrowLeftIcon />DRI</MenuItem>
					<OtherDRI onClick={handleDriTreeItemClick}/>
					{(GlobalServer !== "unknown" && GlobalServer !== undefined) ?(
						<></>
					):(
						<MenuItem onClick={(event) => handleFilterItemClick(event, "Server")}><ArrowLeftIcon />Server</MenuItem>
					)}
					{/* <MenuItem onClick={(event) => handleFilterItemClick(event, "Server")}><ArrowLeftIcon />Server</MenuItem> */}
					<MenuItem onClick={(event) => handleFilterItemClick(event, "Status")} style={{ justifyContent: "center" }}>Status</MenuItem>
				</Menu>
				{drisTree && drisTree.length > 0 && (
					<MultiLevelDropdown
						dritree={drisTree}
						driAnchor={driAnchor}
						handleDriTreeButtonClose={handleDriButtonClose}
						handleDriTreeItemClick={handleDriTreeItemClick}
					/>
				)}
				<Menu
					anchorEl={serverAnchor}
					open={serverAnchor !== null}
					onClose={handleServerButtonClose}
					anchorOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>
					{serversMenuList}
				</Menu>
			</div>
		</div>
	);
}

function MilestoneTable({ toggle, hasData, rows, searchText, setRows, rowsCurrent, lazyLoad, initialRows, setInitialRows, setLazyLoad, filterBy, setFilterBy }) {
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('');
  
	const isValidDate = (dateString) => {
		const date = new Date(dateString);
		return !isNaN(date.getTime());
	  };
	  
	  const handleRequestSort = (property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	  };
	  
	  rows = [...initialRows].sort((a, b) => {
		if (orderBy === "startDate" || orderBy === "dueDate") {
		  const dateProperty = orderBy === "startDate" ? 2 : 3;
		  const aValue = isValidDate(a[dateProperty]) ? new Date(a[dateProperty]) : null;
		  const bValue = isValidDate(b[dateProperty]) ? new Date(b[dateProperty]) : null;
	  
		  if (aValue !== null && bValue !== null) {
			if (order === 'asc') {
			  return aValue - bValue;
			} else {
			  return bValue - aValue;
			}
		  } else if (aValue === null && bValue !== null) {
			return 1;
		  } else if (aValue !== null && bValue === null) {
			return -1;
		  } else {
			return 0; // both values are null, consider them equal
		  }
		}
	  });
	  



	const classNameForColors = ["color-a", "color-b", "color-c", "color-d", "color-e", "color-f"];
	var ind = useRef(0);
	var scrollContainer = useRef();
	var lastScrollTop = useRef(0);
	var loadingDiv = useRef();
	var locked = useRef(false);

	// console.log(initialRows);

	// useEffect(() => {
	// 	async function infiniteFetchAll() {
	// 		if(toggle.current && infiniteFetchFlag.current) {
	// 			infiniteFetchFlag.current = false;
	// 			var done = false;
	// 			while(!done && lazyLoad !== null) {
	// 				if (!locked.current) {
	// 					locked.current = true;
	// 					const data = await lazyLoad.next();
	// 					console.log(data);
	// 					if (data.value.content === undefined) {
	// 						setLazyLoad(null);
	// 						done = true;
	// 						// setDoneFetching(true);
	// 					} else if (data.value.isLast) {
	// 						setInitialRows([...initialRows, ...data.value.content]);
	// 						setLazyLoad(null);
	// 						done = true;
	// 					} else {
	// 						// setRows([...rows, ...data.value]);
	// 						setInitialRows([...initialRows, ...data.value.content]);
	// 					}
	// 					locked.current = false;
	// 				}
	// 			}
	// 		}
	// 	}
	// 	infiniteFetchAll();

	// }, [toggle.current]);

	// const rowsNew = rows.filter(row_ => {
	// 	if (toggle.current) {
	// 		if (row_[7] === "Completed") {
	// 			return true;
	// 		}
	// 		return false;
	// 	}
	// 	else {
	// 		if (row_[7] === "Completed") {
	// 			return false;
	// 		}
	// 		return true;
	// 	}
	// });

	rows = rows.filter(row_ => {
		for (const item of row_) {
			if (item === null) {
				continue;
			}
			const aString = item.toString().toLowerCase().replaceAll(" ", "");
			const bString = searchText.toLowerCase().replaceAll(" ", "");
			if (aString.indexOf(bString) !== -1) {
				return true;
			}
		}
		return false;
	});

	const handleScroll = async (event) => {
		const myDiv = scrollContainer.current;
		var needMore = false;

		if (myDiv.scrollTop > lastScrollTop.current && (myDiv.offsetHeight + myDiv.scrollTop >= myDiv.scrollHeight - 50)) {
			needMore = true;
		}

		lastScrollTop.current = myDiv.scrollTop;
		if (lazyLoad === null || searchText !== "") {
			return;
		}
		if (needMore && !locked.current) {
			locked.current = true;
			const data = await lazyLoad.next();
			// console.log(data);
			if (data.value.content === undefined) {
				setLazyLoad(null);
				// setDoneFetching(true);
			} else if (data.value.isLast) {
				setInitialRows([...initialRows, ...data.value.content]);
				setLazyLoad(null);
			} else {
				// setRows([...rows, ...data.value]);
				setInitialRows([...initialRows, ...data.value.content]);
			}
			locked.current = false;
		}
	};

	const theme = useTheme();
	// const rowSpanInfo = find_row_span(rows, [0, 4]);


	if (rows.length === 0) {
		return <h1 style={{ color: theme.palette.background.text }}>No Milestone Found :(</h1>;
	}
	// if (!hasData) {
	// 	return <h1 style={{ color: theme.palette.background.text }}>No Milestone Found :(</h1>;
	// }

	// console.log(toggle.current)

	const rowSpanInfo = find_row_span(rows, [0, 4]);

	return (
		<div className="milestone-table">
			<TableContainer component={Paper} onScroll={handleScroll} ref={scrollContainer} className="milestone-table-container">
				<Table stickyHeader >
					<TableHead>
						<TableRow className="milestone-table-head" sx={{
							zIndex: 3,
							position: "relative"
						}}>
							<TableCell align="left" className="milestone-table-grouper-header" />
							{toggle.current != null ? (<TableCell align="left" />):(<></>)}
							{/* <TableCell align="left" /> */}
							<TableCell align="center">S.No.</TableCell>
							<TableCell align="left">Milestone</TableCell>
							<TableCell align="center">Server</TableCell>
							<TableCell align="center">DRI</TableCell>
							<TableCell align="center">
							{toggle.current != null ?
							<TableSortLabel 
							active={orderBy === 'startDate'}
							direction={orderBy === 'startDate' ? order : 'asc'}
							onClick={() => handleRequestSort('startDate')}
							sx={{
								color:"white ! important",
								'& .MuiTableSortLabel-icon': {
								  color: 'white ! important', 
								}
							  }}
							
						>
							Start Date
						</TableSortLabel>: "Approval Send To"
							 }
							
							</TableCell>
							<TableCell align="center">
							{toggle.current != null ?
							<TableSortLabel 
							sx={{
							   color:"white ! important",
							   '& .MuiTableSortLabel-icon': {
								 color: 'white ! important', 
							   }
							 }}
						   active={orderBy === 'dueDate'}
						   direction={orderBy === 'dueDate' ? order : 'asc'}
						   onClick={() => handleRequestSort('dueDate')}
					   >
						   {toggle.current ? "Due Date" : "Completion Date"}
					   </TableSortLabel> 
					   :
					   "Approval Status"	
						}
							
							</TableCell>
							{/* <TableCell align="center">Start Date</TableCell>
							<TableCell align="center">{toggle.current ? "Completion Date" : "Due Date"}</TableCell> */}
							<TableCell align="right" />
							<TableCell align="right" className="milestone-table-grouper-header" />
						

						</TableRow>
					</TableHead>
					<TableBody id="list-view">
						{rows.map((row, index) => (
							<>
								<Row key={row[8] + index} row={row} index={index} rowSpanInfo={rowSpanInfo[index]} 
								      classNameForColors={classNameForColors} ind={ind}
									filterBy={filterBy} setFilterBy={setFilterBy} toggle={toggle} />
							</>
						))}
						{
							(lazyLoad !== null && searchText === "") ?
								<TableRow ref={loadingDiv}>
									<TableCell className={"mytasks-table-grouper-component loading"}></TableCell>
									<TableCell align="center" colSpan={8} >
										<div style={{
											whiteSpace: "nowrap",
											lineHeight: 0.5
										}}>
											<Skeleton height={"30px"}></Skeleton>
										</div>
									</TableCell>
								</TableRow>
								:
								<></>
						}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}




function Row({ row, rowSpanInfo, index, classNameForColors, ind, filterBy, setFilterBy, toggle }) {
	// console.log(row);

	var { colorsCache } = useContext(TabStateContext);
	const [moreVisibile, setMoreVisible] = useState(false);
	const [rowOpen, setRowOpen] = useState(false);
	const [open, setOpen] = useState(null);

	const parentRef = useRef();
	const childRef = useRef();

	useEffect(() => {
		if (parentRef.current !== null) {
			if (open) {
				parentRef.current.classList.add("milestone-row-sticky-div");
				parentRef.current.style.zIndex = 1;
			} else {
				parentRef.current.classList.remove("milestone-row-sticky-div");
			}
		}
	}, [open]);

	var colorClass = "color-a";
	if (index in colorsCache) {
		colorClass = colorsCache[index];
	}
	else {
		colorClass = classNameForColors[(ind.current) % 5];
		colorsCache[index] = colorClass;
	}

	//side groupers
	var component = (
		<Tooltip title={row[0]} placement="right-start">
			<TableCell className={"milestone-table-grouper-component milestone-single-row " + row[0]} align="left" rowSpan={2}>
				<div style={{ whiteSpace: "nowrap" }}>{row[0].length > 8 && !open ? row[0].slice(0, 8) + "..." : row[0]}</div>
			</TableCell>
		</Tooltip>
	);

	if (rowSpanInfo[0] === 0) {
		component = <></>;
	}
	else if (rowSpanInfo[0] !== 1) {
		component = (
			<TableCell className={"milestone-table-grouper-component milestone-multi-row " + row[0]} align="left" rowSpan={2 * rowSpanInfo[0]}>
				<div style={{ whiteSpace: "nowrap" }}>{row[0]}</div>
			</TableCell>
		);
		ind.current = ind.current + 1;
	}
	else {
		ind.current = ind.current + 1;
	}

	var type = (
		<TableCell align="center" className={"milestone-table-grouper-type milestone-single-row " + row[4]} rowSpan={2}>
			<div style={{ whiteSpace: "nowrap" }}>{row[4]}</div>
		</TableCell>
	);

	if (rowSpanInfo[4] === 0) {
		type = <></>;
	}
	else if (rowSpanInfo[4] !== 1) {
		type = (
			<TableCell align="center" className={"milestone-table-grouper-type milestone-multi-row " + row[4]} rowSpan={2 * rowSpanInfo[4]}>
				<div style={{ whiteSpace: "nowrap" }}>{row[4]}</div>
			</TableCell>
		);
	}

	const theme = useTheme();
	var color = "green";
	const today = new Date();
	const startDate = new Date(row[2]);
	const endDate = new Date(row[3]);
	const totDays = days_between(startDate, endDate);

	// console.log(theme.palette.mode);

	if (today < startDate) {
		color = "green";
	}
	else if (row[7] === "Completed" || row[7] === "Cancelled") {
		if (theme.palette.mode === "dark") {
			color = "White";
		}
		else {
			color = "black";
		}
	}
	else if (days_between(today, startDate) < (0.25 * totDays).toFixed(0)) {
		color = "forestgreen";
	}
	else if (days_between(today, startDate) < (0.5 * totDays).toFixed(0)) {
		color = "darksalmon";
	}
	else if (days_between(today, startDate) < (0.75 * totDays).toFixed(0)) {
		color = "darkgoldenrod";
	}
	else if (days_between(today, startDate) < (totDays).toFixed(0)) {
		color = "chocolate";
	}
	else {
		color = "crimson";
	}
	// console.log(row[7])
	if (row[3] === "-" || row[2] === "-") {
		color = "";
	}
	const TextOnlyTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
	))(`
          color: black;
          background-color: white;
      `);

	let daysDifference = 0;
	const currentDate = new Date();
	const rowDate = new Date(row[3]);

	// console.log(row[17])


	currentDate.setHours(0, 0, 0, 0);
	rowDate.setHours(0, 0, 0, 0);
	const isExpired = rowDate < currentDate;

	if (isExpired) {
		const timeDifferenceInMs = currentDate.getTime() - rowDate.getTime();
		daysDifference = Math.ceil(timeDifferenceInMs / (1000 * 3600 * 24));
	}
	const options = {
		month: 'short',
		day: 'numeric',
		year: 'numeric',
	};
	const formattedDate = currentDate.toLocaleDateString(undefined, options);


	const formatDate = (inputDate) => {
		const months = [
			'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
			'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
		];

		const date = new Date(inputDate);
		const day = date.getDate();
		const month = months[date.getMonth()];
		const year = date.getFullYear();

		return `${day}-${month}-${year}`;
	};
	var formattedDate_new = "";

	if (row[11] === "-" || row[11] === "" || row[11] === null || row[11] === " ") {

	}
	else {
		const inputDate = row[11];
		formattedDate_new = formatDate(inputDate);
	}

	function handleArrowClick(index) {
		if (open === index) {
			setOpen(null);
		}
		else {
			setOpen(index);
		}
		setRowOpen(!rowOpen)
	}


	return (
		<>
			<TableRow
				hover={true}
				sx={{
					'& > *': { borderBottom: 'unset' },
					backgroundColor: theme.palette.background.tableRow,
					'&:hover': {
						backgroundColor: theme.palette.background.tableRowHover + " !important"
					},
					zIndex: 2,
					position: "relative"
				}}
				onMouseEnter={() => setMoreVisible(true)}
				onMouseLeave={() => setMoreVisible(false)}
				// onClick={() => setRowOpen(!rowOpen)}
				ref={parentRef}
			>
				{component}
				{toggle.current != null ? (
					<TableCell align="left" className='slide-animation' style={{ width: "30px" }}>
					<IconButton
						size="small"
						// onClick={handleArrowClick}
						onClick={() => setRowOpen(!rowOpen)}
					>
						{
							// open 
							rowOpen
							? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
						}
					</IconButton>
					<IconButton>

						<FontAwesomeIcon icon="faCircleInfo" />
					</IconButton>
				</TableCell>
				) : (
					<></>
				)}
				
				<TableCell className='slide-animation' align="center" onClick={handleArrowClick} style={{ width: "2.5vw", maxWidth: "2.5vw" }} >{index + 1}</TableCell>
				{["", " ", undefined, null].some(value => row[10] === value) ? (
					<>
					{row[16] === "-" ? (
						<TableCell
						  className="slide-animation"
						  align="left"
						  onClick={handleArrowClick}
						  style={{ minWidth: "6vw", maxWidth: "7vw", wordWrap: "break-word" }}
						>
						  {row[1]}
						</TableCell>
					  ) : (
						<TableCell
						  className="slide-animation"
						  align="left"
						  onClick={handleArrowClick}
						  style={{ minWidth: "6vw", maxWidth: "7vw", wordWrap: "break-word" }}
						>
									<Typography>
										{row[16]}
									</Typography>
									<Typography>
										[{row[1]}]
									</Typography>
						</TableCell>
					  )}
					  
					  </>

				) : (
						<>
					{row[16] === "-" ? (
					<TextOnlyTooltip title={<span style={{ fontFamily: 'Poppins', fontSize: '0.875rem', color: "Black", backgroundImage: "" }}>{row[10]} </span>} followCursor >

						<TableCell
						  className="slide-animation"
						  align="left"
						  onClick={handleArrowClick}
						  style={{ minWidth: "6vw", maxWidth: "7vw", wordWrap: "break-word" }}
						>
						  {row[1]}
						</TableCell>
						</TextOnlyTooltip>
					  ) : (

						<TextOnlyTooltip title={<span style={{ fontFamily: 'Poppins', fontSize: '0.875rem', color: "Black", backgroundImage: "" }}>{row[10]} </span>} followCursor >
						<TableCell
						  className="slide-animation"
						  align="left"
						  onClick={handleArrowClick}
						  style={{ minWidth: "6vw", maxWidth: "7vw", wordWrap: "break-word" }}
						>
											<Typography>
												{row[16]}
											</Typography>
											<Typography>
												[{row[1]}]
											</Typography>
						</TableCell>
						</TextOnlyTooltip>
					  )}
				
					  </>
		
				)}
				<TableCell className='slide-animation' align="center" onClick={handleArrowClick} style={{ maxWidth: "10vw", wordWrap: "break-word" }}>{row[6]}</TableCell>
				<TableCell className='slide-animation' align="center" onClick={handleArrowClick} style={{ width: "5vw" }}><GetNameAndEmpID emails={row[5]} /></TableCell>
				{toggle.current != null ? (
					<TableCell className='slide-animation' align="center" onClick={handleArrowClick} style={{ minWidth: "5vw", width: "7vw", hyphens: "none" }}>{row[2]}</TableCell>
				):(
					<TableCell className='slide-animation' align="center" onClick={handleArrowClick} style={{ width: "5vw" }}><GetNameAndEmpID emails={row[17]} /></TableCell>
				)} 
				{/* <TableCell className='slide-animation' align="center" onClick={handleArrowClick} style={{ minWidth: "5vw", width: "7vw", hyphens: "none" }}>{row[2]}</TableCell> */}
				{/* <TableCell className='slide-animation' align="center" onClick={handleArrowClick} style={{minWidth: "5vw", width: "7vw", hyphens: "none", color: color}}>{row[3]}</TableCell> */}
				{toggle.current != null ? (
                    row[3].includes("Expiry Date") ? (
						<Tooltip title="If this milestone remains unstarted before the specified date, it will be automatically deleted." followCursor>
							<TableCell id="" className='slide-animation' align="center" onClick={handleArrowClick} style={{ minWidth: "5vw", width: "7vw", hyphens: "none", color: color }}>
								<Box>
									<Box sx={{
										fontSize: "1rem",
										color: "crimson"
									}}>
										<Typography style={{ color: '#898686de' }}>
											{row[3].split(" ")[0].replaceAll("-", " ")}
										</Typography>
									</Box>
									<Box sx={{
										fontSize: "0.6rem",
										color: theme.palette.background.text
									}}>
										{"(Expiry Date)"}
									</Box>
								</Box>
							</TableCell>
						</Tooltip>
					) : (
						<TableCell id="" className='slide-animation' align="center" onClick={handleArrowClick} style={{ minWidth: "5vw", width: "8vw", hyphens: "none", color: color }}>
							{row[7] === 'Completed' || row[7] === 'Cancelled' ?
									<Box>
										<Box sx={{
											fontSize: "0.9rem"
										}}>
											{formattedDate_new === "" || formattedDate_new === null ? "-" : formattedDate_new}
										</Box>
										<Box sx={{
											fontSize: "0.7rem",
											color: theme.palette.background.text
										}}>
											{[undefined, "", " ", null, "-"].includes(row[3]) || [undefined, "", " ", null, "-"].includes(row[11]) ? "" : "DueDate :" + row[3]}
										</Box>
									</Box>
									:
									daysDifference > 0 ?
										<Box>
											<Box sx={{
												fontSize: "1rem"
											}}>
												{row[3]}
											</Box>
											<Box sx={{
												fontSize: "0.6rem",
												color: theme.palette.background.text
											}}>
												{"(late by " + daysDifference + " days)"}
											</Box>
										</Box>
										:
										row[3]
							}
						</TableCell>
						
					)
				):(
					<TableCell className='slide-animation' align="center" onClick={handleArrowClick} style={{ maxWidth: "3vw", wordWrap: "break-word" }}>{row[18]}</TableCell>
				)}
				
				
				
				<MoreSection className='slide-animation' moreVisibile={moreVisibile} setMoreVisible={setMoreVisible} MilestoneRowID={row[9]} filterBy={filterBy} setFilterBy={setFilterBy}  MilestoneUniqueID1={row[12]}
				 			 creatorRowID={row[14]} TasklistUniqueID={row[13]} CreatedFromNewMilestone={row[19]} MilestoneId={row[8]} TaskListId={row[20]} MilestoneDri={row[5]}
				/>
				{type}
			</TableRow>
			<TableRow className="info-div-row"
				sx={{
					backgroundColor: theme.palette.background.infoDivOuter + " !important"
				}}
				ref={childRef}
			>
				{/*<TableCell style={{padding: "0px 10px 0px 10px"}}/>*/}
				<TableCell colSpan={8} style={{ padding: "0px 10px 0px 10px" }}>
					<Collapse in={
						// open
						rowOpen
					} timeout="auto" unmountOnExit>
						<InfoDiv milestoneName={row[1]} milestoneId={row[8]} milestoneStatus={row[7]}
							milestoneStartDate={row[2]} toggle={toggle} />
						{/* <QuarterlyInfo></QuarterlyInfo>  */}
					</Collapse>
				</TableCell>
				{/*<TableCell style={{padding: "0px 10px 0px 10px"}}/>*/}

			</TableRow>

		</>
	);
}



function MoreSection({ moreVisibile, setMoreVisible,MilestoneRowID, creatorRowID, filterBy, setFilterBy ,MilestoneUniqueID1,TasklistUniqueID,CreatedFromNewMilestone,MilestoneId,TaskListId,MilestoneDri}) {
	// console.log(MilestoneDri);
	// console.log(MilestoneId);
	const [moreAnchor, setMoreAnchor] = useState(null);
	const [editIframeLoaded, setEditIframeLoaded] = useState(false);
	const [copiedText, setCopiedText] = useState('');
	const [showAlert, setShowAlert] = useState(false);
	const [showAlert1, setShowAlert1] = useState(false);
	const [showAlert2, setShowAlert2] = useState(false);
	const [showAlert3, setShowAlert3] = useState(false);
	const [showAlertCompleted, setShowAlertCompleted] = useState(false);
	const [showAlertError, setShowAlertError] = useState(false);
	const [openPopupEdit, setOpenPopupEdit] = useState(false);
	const { userDetails, loginUserEmail, teamDetails } = useContext(UserContext);
	const loginUserRole = teamDetails.current[loginUserEmail.current].Role;
	// console.log(user_email);
	var milestoneEditUrl;
	// console.log(CreatedFromNewMilestone)
	if (CreatedFromNewMilestone == "True") {
		milestoneEditUrl = "https://creatorapp.zoho.com/zohointranet/zvp-team/Milestone_Duplicate_Form/record-edit/Milestone_Duplicate_Form_Report/"+creatorRowID;
		// console.log("https://creatorapp.zoho.com/zohointranet/zvp-team/Milestone_Duplicate_Form/record-edit/Milestone_Duplicate_Form_Report/"+MilestoneRowID)
	}
	else{
		milestoneEditUrl = "https://creatorapp.zoho.com/zohointranet/zvp-team/MileStone_Form/record-edit/Milestone_Form_Report/" + MilestoneRowID + "/";
		// console.log("https://creatorapp.zoho.com/zohointranet/zvp-team/MileStone_Form/record-edit/Milestone_Form_Report/" + MilestoneRowID + "/")
	}
	// const milestoneEditUrl = "https://creatorapp.zoho.com/zohointranet/zvp-team/MileStone_Form/record-edit/Milestone_Form_Report/" + MilestoneRowID + "/";
	const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
	const [markAsCompletedOpen, setMarkAsCompletedOpen] = useState(false);

	const {loginUserDept} = useContext(UserContext);
	

	const handleClosePopupEdit = () => {
		setFilterBy([...filterBy]);
		setOpenPopupEdit(false);
		setEditIframeLoaded(false);
		setMoreVisible(false);
	};

	const handleEditIframeLoad = () => {
		setEditIframeLoaded(true);
	};

	function handleEditClick() {
		setOpenPopupEdit(true);
		setMoreAnchor(null);
	}

	function handleMarkAsCompleted() {
		setMarkAsCompletedOpen(true);
	}

	function handleMarkAsCompletedYes() {
		setMarkAsCompletedOpen(false);
		setShowAlert2(true);
		MarkAsCompletedData();
	}

	function handleMarkAsCompletedNo() {
		setMarkAsCompletedOpen(false);
	}

	async function MarkAsCompletedData() {

    	const url = '/server/sample?table=MilestoneCompletion&rowid=' + creatorRowID +'&TasklistId='+ TaskListId;
		const headers = {
			'Content-Type': 'application/json',
		};
		fetch(url, {
			method: 'POST',
			headers: headers,
		})
			.then(response => {
				if (!response.ok) {
					throw new Error('Request failed with status: ' + response.status);
				}
				if (response.headers.get('content-length') === '0') {
					throw new Error('Empty response');
				}
				return response.json();
			})
			.then(data => {
				const statusCode = data.StatusCode;
				if (statusCode == "200") {
					setShowAlert2(false);
					setShowAlert3(true);
					setTimeout(()=> {
						setShowAlert3(false);
					}, 3000);
				}
				else{
					setShowAlertError(true);
					setTimeout(()=> {
						setShowAlertError(false);
					}, 3000);
				}
			})
			.catch(error => {
				console.error(error);
			});

	}

	async function fetchData() {

    	const url = '/server/sample?table=DeleteMilestone&rowid=' + creatorRowID +'&MilestoneId='+ MilestoneId;
		const headers = {
			'Content-Type': 'application/json',
		};
		fetch(url, {
			method: 'POST',
			headers: headers,
		})
			.then(response => {
				if (!response.ok) {
					throw new Error('Request failed with status: ' + response.status);
				}
				if (response.headers.get('content-length') === '0') {
					throw new Error('Empty response');
				}
				return response.json();
			})
			.then(data => {
				const statusCode = data.StatusCode;
				if (statusCode == "200") {
					setShowAlert(false);
					setShowAlertCompleted(true);
					setTimeout(()=> {
						setShowAlertCompleted(false);
					}, 3000);
				}
				else{
					setShowAlertError(true);
					setTimeout(()=> {
						setShowAlertError(false);
					}, 3000);
				}
			})
			.catch(error => {
				console.error(error);
			});

	}

	const handleConfirmDelete = () => {
		try {
			    setShowAlert(true)
				fetchData();
				setDeleteConfirmationOpen(false);
				setMoreVisible(false);
		}
		catch (error) {
			console.error(error);
		}
		// Close the confirmation dialog
		//setFilterBy([...filterBy]);
		
	};

	  const handleCancelDelete = () => {
		
		setDeleteConfirmationOpen(false);
	  };
	  
	  const handleDeleteClick = () => {
		setDeleteConfirmationOpen(true);
	  };
	  

	const MilestoneUniqueID =MilestoneUniqueID1 ; 
  
    const handleCopy = async () => {
      try {
		setShowAlert1(true);
        await navigator.clipboard.writeText(MilestoneUniqueID);
        setCopiedText(MilestoneUniqueID);
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    };

	useEffect(() => {
        if (showAlert1) {
            const timer = setTimeout(() => {
                setShowAlert1(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [showAlert1]);

	return (
		<TableCell align="right" style={{ width: "5px", cursor: "pointer" }}>
			<Fade in={moreVisibile} timeout={100}>
				<MoreVertIcon onClick={(event) => setMoreAnchor(event.currentTarget)} />
			</Fade>
			<Menu anchorEl={moreAnchor}
				open={moreAnchor !== null}
				onClose={() => { setMoreAnchor(null) }}
			>
				<MenuItem onClick={handleEditClick}>
					<Box style={{ display: "flex" }}>
						<EditIcon fontSize="small" />
						&nbsp;Edit
					</Box>
				</MenuItem>
				
				<MenuItem onClick={handleDeleteClick}>
					<Box style={{ display: "flex" }}>
						<DeleteIcon fontSize="small" />
						&nbsp;Delete
					</Box>
				</MenuItem>
				
				{/* <MenuItem onClick={handleDeleteClick}>
					<Box style={{ display: "flex" }}>
						<DeleteIcon fontSize="small" />
						&nbsp;Delete
					</Box>
				</MenuItem> */}

				<Dialog open={deleteConfirmationOpen} onClose={handleCancelDelete}>
					<DialogTitle>Confirm Deletion</DialogTitle>
					<DialogContent>
						Are you sure you want to delete this item?
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCancelDelete} color="primary">
							Cancel
						</Button>
						<Button onClick={handleConfirmDelete} color="primary">
							Confirm
						</Button>
					</DialogActions>
				</Dialog>


				<Tooltip title={MilestoneUniqueID} placement="right-start">
					<MenuItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						<div style={{ marginRight: '6px' }} onClick={handleCopy}>Milestone UniqueID</div>
						<ContentCopyIcon variant="contained" color="primary" onClick={handleCopy} style={{ fontSize: '17px' }}>

						</ContentCopyIcon>

					</MenuItem>
				</Tooltip>

				{(loginUserRole !== "Developer" || (loginUserEmail.current === MilestoneDri)) && (
					<MenuItem onClick={handleMarkAsCompleted}>
						<Box style={{ display: "flex" }}>
							&nbsp;Mark As Completed
						</Box>
					</MenuItem>
				)}


				
				<Dialog open={markAsCompletedOpen} onClose={handleMarkAsCompletedNo}>
					<DialogTitle>Confirm Deletion</DialogTitle>
					<DialogContent>
						Are you sure you want to mark this milestone completed ?
					</DialogContent>
					<DialogActions>
						<Button onClick={handleMarkAsCompletedNo} color="primary">
							No
						</Button>
						<Button onClick={handleMarkAsCompletedYes} color="primary">
							Yes
						</Button>
					</DialogActions>
				</Dialog>

			</Menu>

			{/* <TableCell className='slide-animation' align="center"  style={{ minWidth: "5vw", width: "7vw", hyphens: "none" }}> </TableCell> */}
			<Dialog open={openPopupEdit} onClose={handleClosePopupEdit} fullScreen>
				<DialogActions>
				{!navigator.onLine && (
					<Alert sx={{marginLeft:"255px",width:"247px",alignItems:"center",textAlign:"center",justifyContent:"center"}} severity="error">Kindly check you network connection.</Alert>
				)}
					<Button onClick={handleClosePopupEdit} variant="text" fontSize="large"><CloseIcon /></Button>
				</DialogActions>
				<DialogContent>
					{!editIframeLoaded && (
						<div
							style={{
								position: 'relative',
								width: '100%',
								height: '100%',
								backgroundImage: 'linear-gradient(to right, #f0f0f0, #e0e0e0)',
								borderRadius: 0,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Skeleton
								variant="rectangular"
								width="100%"
								height="100%"
								animation="wave"
							/>
						</div>
					)}
					<iframe
						src={milestoneEditUrl}
						width="100%"
						height="100%"
						title="External Content"
						frameBorder={0}
						onLoad={handleEditIframeLoad}
						style={{ display: editIframeLoaded ? 'block' : 'none' }}
					/>
				</DialogContent>
			</Dialog>

			<Snackbar
                    open={showAlert1}

                    onClose={() => setShowAlert1(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity="success">Milestone UniqueID copied to clipboard 👍 </Alert>
             </Snackbar>

			<Snackbar
				open={showAlert}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="info">Deleting in progress...</Alert>
			</Snackbar>

			<Snackbar
				open={showAlert2}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="info">Milestone completion in progress...</Alert>
			</Snackbar>

			<Snackbar
				open={showAlert3}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="info">Milestone marked as completed</Alert>
			</Snackbar>

			<Snackbar
				open={showAlertCompleted}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="info">Milestone is Deleted 😊</Alert>
			</Snackbar>

			<Snackbar
				open={showAlertError}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="error">Some error occured please contanct "Zone_Support" channel.</Alert>
			</Snackbar>

		</TableCell>
		
	);
	
}

// ####################################################### util functions ###################################################################

function serverCompareFunction(a, b) {
	if (a[0] < b[0]) {
		return -1;
	}
	if (a[0] > b[0]) {
		return 1;
	}
	return 0;
}

function typeCompareFunction(a, b) {
	if (a[4] < b[4]) {
		return -1;
	}
	if (a[4] > b[4]) {
		return 1;
	}
	return 0;
}


function milestoneNameCompareFunction(a, b) {
	if (a[1] < b[1]) {
		return -1;
	}
	else if (b[1] < a[1]) {
		return 1;
	}
	return 0;
}

function dateCompareFunction(a, b) {
	if (a[11] < b[11]) {
		// console.log(a[11] < b[11])
		// console.log(">>>>>>>>>>>if"+b[3] < a[3])
		return -1;
	}
	else if (b[11] < a[11]) {
		// console.log(a[11] < b[11])
		// console.log(">>>>>>>>>>>else"+b[11])
		return -1;
	}
	return 0;
}

function tasklistNameCompareFunction(a, b) {
	if (a[2] < b[2]) {
		return -1;
	}
	else if (b[2] < a[2]) {
		return 1;
	}
	return 0;
}

function totalTasksCompareFunction(a, b) {
	if (a[3] < b[3]) {
		return -1;
	}
	else if (b[3] < a[3]) {
		return 1;
	}
	return 0;
}

function driCompareFunction(a, b) {
	if (a[5] < b[5]) {
		return -1;
	}
	else if (b[5] < a[5]) {
		return 1;
	}
	return 0;
}

async function setServerDropDown(setServersList, userDetails, teamDetails, allServers) {
	return new Promise((resolve, reject) => {
		// console.log(teamDetails);
		const userEmail = userDetails.current.auth.email_id;
		const userRole = teamDetails.current[userEmail].Role;

		if (userRole === "CEO") {
			// setServersList(teamDetails.current["mariaraj@zohocorp.com"].Servers.split(","));
			setServersList(allServers);
		}
		else {
			setServersList(teamDetails.current[userEmail].Servers.split(","));
		}
		return resolve();
	});
}

async function setDriDropDown(setDriList, setDriTree, userDetails, teamDetails, dept, configuration) {
	const query = milestone_main_component_get_query_6(dept);
	var result2 = await getFromTeamDetails(query);
	var head_parent = "srinivasan@zohocorp.com";
    try {
        const rootUsers = configuration.current[`department.rootUsers`];
        if(dept in rootUsers) {
            head_parent = configuration.current[`department.rootUsers`][dept];
        }
    } catch(e) {}
	// const head_root = "mariaraj@zohocorp.com";
	const head_root = [];
	const employees = result2;
	var hierarchicalData = [];
	hierarchicalData = createHierarchy(employees, head_parent);

	return new Promise((resolve, reject) => {
		const userEmail = userDetails.current.auth.email_id;
		const userRole = teamDetails.current[userEmail].Role;
		const userReportingTo = teamDetails.current[userEmail].ReportingTo;
		var users = [];
		if (userRole === "CEO" && head_root.includes[userEmail]) {
			for (const user in teamDetails.current) {
				users.push(user);
			}

			users.sort();
			setDriList(users);
			setDriTree(hierarchicalData);
		}

		else if (userRole === "CEO" && !head_root.includes(userEmail)) {
			for (const user in teamDetails.current) {
				users.push(user);
			}
			users.sort();
			setDriList(users);
			setDriTree(hierarchicalData);
			users = findMentees([userEmail], teamDetails);
			users.sort();
			users = [userEmail, ...users];
			setDriList(users);
			hierarchicalData = createHierarchy(employees, userReportingTo);
			const getDriList = [];
			getDriList.push(getMentorWithMentees(hierarchicalData, userEmail));
			var hierarchicalData11 = [];
			hierarchicalData11 = createHierarchy(employees, head_parent);
			hierarchicalData11.push(getDriList[0]);
			setDriTree(hierarchicalData11);
		}

		else {
			users = findMentees([userEmail], teamDetails);
			users.sort();
			users = [userEmail, ...users];
			setDriList(users);
			hierarchicalData = createHierarchy(employees, userReportingTo);
			const getDriList = [];
			getDriList.push(getMentorWithMentees(hierarchicalData, userEmail));
			setDriTree(getDriList);
		}
		return resolve();
	});
}



function findMentees(userEmails, teamDetails) {
	if (userEmails.length === 0) {
		return [];
	}

	const arr = [];

	for (const person in teamDetails.current) {
		if (userEmails.includes(teamDetails.current[person].ReportingTo)) {
			arr.push(person);
		}
	}

	const newArr = arr.concat(findMentees(arr, teamDetails));

	return newArr;
}

function createHierarchy(data, reportingTo) {
	const hierarchy = [];

	for (const employee of data) {
		//console.log(employee);
		if (employee.ReportingTo === reportingTo) {
			const subordinates = createHierarchy(data, employee.Email);
			if (subordinates.length) {
				employee.mentees = subordinates;
			}
			hierarchy.push(employee);
		}
	}

	hierarchy.sort((a, b) => {
		const empIDA = parseInt(a.EmployeeID, 10);
		const empIDB = parseInt(b.EmployeeID, 10);
		return empIDA - empIDB;
	});
	// hierarchy.sort((a, b) => {
	//  const empIDA = a.name.toLowerCase();
	//  const empIDB = b.name.toLowerCase();
	//  return empIDA.localeCompare(empIDB);
	//   });

	return hierarchy;
}

function getMentorWithMentees(hierarchy, mentorEmail) {
	for (const mentor of hierarchy) {
		if (mentor.Email === mentorEmail) {
			return mentor;
		} else if (mentor.mentees && mentor.mentees.length > 0) {
			const foundMentor = getMentorWithMentees(mentor.mentees, mentorEmail);
			if (foundMentor) {
				return foundMentor;
			}
		}
	}
	return null;
}
