//ignorei18n_start
import React, { useEffect, useRef,useState } from "react";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { TimelineOppositeContent } from "@mui/lab";
import {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { Chip, Divider, Stack, Tooltip } from "@mui/material";

import { styled , useTheme } from '@mui/material/styles';

import DoneIcon from '@mui/icons-material/Done';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import BuildTimelineData from "./BuildTimelineData";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function TimelineComponent({ data}) {

    // Custom styled components
    const ColorlibConnector = styled(TimelineConnector)(({ theme }) => ({
        [`&.MuiTimelineConnector-root`]: {
            backgroundImage: 'linear-gradient( 95deg, #3c5c9b 0%, #116D6E 100%)'
        }
    }));

    const ColorlibDot = styled(TimelineDot)(({ theme }) => ({
        [`&.MuiTimelineDot-filled`]: {
            backgroundImage: 'linear-gradient( 95deg, #3c5c9b 0%, #116D6E 100%)',
            backgroundClip: 'padding-box'
        }
    }));

    sortData(data);

    const ref = useRef();
    var ind = 0;
    var closestDiff = Infinity;
    const today = new Date();
    data.forEach((d, i) => {
        if (d?.CREATEDTIME || d?.Build_Requested_Time) {
            const date = d.Build_Requested_Time ? new Date(d.Build_Requested_Time) : new Date(d.CREATEDTIME);
            const diff = Math.abs(today.getTime() - date.getTime());
            if (diff <= closestDiff) {
                closestDiff = diff;
                ind = i;
            }
        }
    });

    return (
       <div style={{ height: '95%', overflowY: "scroll", padding: "20px 0px", flex: 1 }}>
            <Timeline
                sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.2
                    }
                }}
            >
                {data.map((d, i) => (
                    <TimelineItem key={i} ref={i === ind ? ref : null}>
                        <TimelineOppositeContent color="text.secondary">
                            <Lefty data={d} />
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <ColorlibDot>
                                {data[i].current_status === "closed" ? (
                                    <Tooltip title={data[i].current_status}>
                                        <DoneIcon sx={{ padding: "2px", boxSizing: "border-box", transition: "padding 0.3s ease" }} />
                                    </Tooltip>
                                ) : (
                                    <Tooltip title={data[i].current_status}>
                                        <MoreHorizIcon sx={{ padding: "2px", boxSizing: "border-box", transition: "padding 0.3s ease" }} />
                                    </Tooltip>
                                )}
                            </ColorlibDot>
                            <ColorlibConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Righty data={d} />
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
        </div>
    );
}

function Lefty({ data }) {
    return (
        <div style={{ height: "100px", width: "100%", display: "flex", flexDirection: "row-reverse" }}>
            <Stack direction="column" spacing={1} style={{ float: "right" }}>
                <Chip
                    // label={data.AllBuildDetails.Build_Requested_Time ? "Completed on" : "Ongoing"}
                    label = "Build Created On"
                    // color={data.AllBuildDetails.Build_Requested_Time ? "success" : "warning"}
                    color="success"
                    variant="filled"
                    style={{ borderRadius: "8px", fontWeight: "bold", fontSize: "10px" }}
                />
                <Chip
                    label={data.AllBuildDetails.Build_Requested_Time ? data.AllBuildDetails.Build_Requested_Time : data.AllBuildDetails.CREATEDTIME}
                    color="primary"
                    variant="outlined"
                    style={{ borderRadius: "8px", fontWeight: "bold" }}
                />
            </Stack>
        </div>
    );
}

function Righty({ data }) {

    const theme = useTheme();

    return (
        <div style={{
            width: "100%",
            borderRadius: "10px",
            padding: "10px",
            boxSizing: "border-box",
            backgroundColor: theme.palette.mode === "light" ? "rgb(248 250 252)" : "#567a9922",
            marginBottom: "10px",
            display: "flex",
            flexDirection: "column",
            paddingLeft: "15px",
            boxShadow: "rgb(17, 109, 110,0.5) 10px 0px 15px -2px inset",
            borderLeft: "3px solid #116D6E"
        }}>
            <div style={{ fontWeight: "bold", gap:"5px"}}>{data.title}</div>
            <BuildTimelineData data={data} />
        </div>
    );
}

// Updated formatDate function to match custom format
function formatDate(date) {
    if (!date) return "";

    const d = new Date(date);
    if (isNaN(d.getTime())) {
        // If the date is not a valid date object, return the original value
        return date;
    }

    const day = String(d.getDate()).padStart(2, '0');
    const month = d.toLocaleString('en-US', { month: 'short' }); // Get short month name
    const year = d.getFullYear();

    const hours = d.getHours();
    const minutes = String(d.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    return `${day}-${month}-${year}, ${formattedHours}:${minutes} ${ampm}`;
}

function sortData(data) {
    data.sort((a, b) => {
        const date_a = a.Build_Requested_Time ? new Date(a.Build_Requested_Time) : new Date(a.CREATEDTIME);
        const date_b = b.Build_Requested_Time ? new Date(b.Build_Requested_Time) : new Date(b.CREATEDTIME);

        return date_a - date_b;
    });
}
//ignorei18n_end
