import React, { useState, useContext, useRef, useEffect } from 'react';
import { UserContext } from '../../../../contexts/UserContext';
import { CalendarTimeline } from '../../../../util/components/calendarTimeline/CalendarTimeline';
//import { VerticalTimeline } from '../../../../util/components/verticalTimeline/VerticalTimeline';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import {
  Box, 
  Button,
  ButtonGroup,
  Skeleton,
  Select,
  MenuItem,
  Grid,
  Collapse,
  TextField,
  Paper,
  Chip,
  Menu
} from "@mui/material";

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';

import { group_by_order, find_row_span } from '../../../../util/GroupingFunctions.js';
import { queryDB } from "../../../../util/QueryDB";
import { EmployeeCards } from '../../../../util/Loading.js';
import { GetNameAndEmpID, MultiLevelDropdown } from '../../../../util/Loading.js';
import { getFromTeamDetails } from '../../taskBreaker/TBDataFetcher.js';

import ExternalComponent from './ExternalComponent.js';
import CombinedInfo from '../../../pageContent/CombinedMilestone/CombinedInfo'
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTheme } from '@mui/material';
import { VerticalTimeline } from '../../../../util/components/verticalTimeline/VerticalTimeline';
import { report_5_data_fetcher_get_query_1, report_5_data_fetcher_get_query_2, report_5_data_fetcher_get_query_3, report_5_data_fetcher_get_query_4, report_5_data_fetcher_get_query_5,report_5_data_fetcher_get_query_6} from '../../../../catalystDB/queryGenerator.js';

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	paddingTop: 0,
	paddingBottom: 0,
	color: theme.palette.text.primary,
	height: "calc((100vh - 150px))",
	display: "flex",
	// justifyContent: "center",
	alignItems: "center"
}));

export function Report5() {

    const [viewSelected, setViewSelected] = useState("Internal");
    const [toggleSwitch, setToggleSwitch] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Zone");

    const handleToggleChange = (state) => {
        if(state) {
            if(state === "timeline") {
                setToggleSwitch(false);
            } else {
                setToggleSwitch(true);
            }
        } else {
            setToggleSwitch(!toggleSwitch);
        }
    };

    <ServerList
    selectedOption={selectedOption}
    setSelectedOption={setSelectedOption}
    height={"50px"}
    required={true}
/>
    var ComponentToShow = <></>;

    if (viewSelected === "External" && toggleSwitch === false) {
        ComponentToShow = <TimelineComponent viewSelected={viewSelected} setViewSelected={setViewSelected} selectedOption={selectedOption} setSelectedOption={setSelectedOption}/> ;
    }
    // else if (viewSelected === "External" && toggleSwitch === true) {
    //     ComponentToShow = <ExternalComponent  selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
    // }
    else if (viewSelected === "Internal" && toggleSwitch === false) {
        ComponentToShow = <TimelineComponent viewSelected={viewSelected} setViewSelected={setViewSelected} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />;
    }

    else if (viewSelected === "Combined" && toggleSwitch === false) {
        ComponentToShow = <TimelineComponent viewSelected={viewSelected} setViewSelected={setViewSelected} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />;
    }
    // else if (viewSelected === "Internal" && toggleSwitch === true) {
    //     ComponentToShow = <GridComponent selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>;
    // }

   
    return (
        <>
            <Box sx={{
                position: "absolute",
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                alignItems: "center",
                justifyContent: "center",
                left: 300,
                right: 300,
                top: 20,
                marginLeft: "auto",
                marginRight: "auto",
                color: "white",
                fontWeight: "bold",
                cursor: "default",
                transition: "top .5s",
                paddingLeft: "250px"
            }}
            >
                <ButtonGroup>
                    <Button
                        variant={viewSelected === "Internal" ? "contained" : "outlined"}
                        size="small"
                        onClick={() => {
                            setViewSelected("Internal");
                        }}
                        sx={{
                            textTransform: "none",
                            borderRadius: "40px"
                        }}
                    >
                        Internal
                    </Button>
                    <Button
                        variant={viewSelected === "External" ? "contained" : "outlined"}
                        size="small"
                        onClick={() => setViewSelected("External")}
                        sx={{
                            textTransform: "none",
                            borderRadius: "40px"
                        }}
                    >
                        External
                    </Button>
                    <Button
                        variant={viewSelected === "Combined" ? "contained" : "outlined"}
                        size="small"
                        onClick={() => setViewSelected("Combined")}
                        sx={{
                            textTransform: "none",
                            borderRadius: "40px"
                        }}
                    >
                        Combined
                    </Button>
                </ButtonGroup>
                {/* <ButtonGroup>
                    <Button
                        variant={toggleSwitch? "outlined" : "contained"}
                        size="small"
                        onClick={() => {
                            handleToggleChange("timeline")
                        }}
                        sx={{
                            textTransform: "none",
                            borderRadius: "40px"
                        }}
                    >
                        Timeline
                    </Button>
                    <Button
                        variant={toggleSwitch? "contained" : "outlined"}
                        size="small"
                        onClick={() => handleToggleChange("release_plan")}
                        sx={{
                            textTransform: "none",
                            borderRadius: "40px"
                        }}
                    >
                        ReleasePlan
                    </Button>
                </ButtonGroup> */}
                {/* <Switch
                    checked={toggleSwitch}
                    onChange={handleToggleChange}
                    color="primary"
                /> */}
            </Box>
            {
                ComponentToShow
                // toggleSwitch ? <TimelineComponent viewSelected={viewSelected} setViewSelected={setViewSelected} /> : <OtherComponent />
            }
        </>
    );
}

export function ServerList({ selectedOption, setSelectedOption, height, required = false }) {
    const [serversList, setServersList] = useState([]);
    const { userDetails, teamDetails } = useContext(UserContext);
    const user_email = userDetails.current.auth.email_id;
    const user_servers = teamDetails.current[user_email].Servers;
    const [clearButton, setClearButton] = useState(false);

    const sortedServersList = [...serversList].sort((a, b) => a.localeCompare(b));
    sortedServersList.push("ZVP");

    function handleSelect(event) {
        setSelectedOption(event.target.value);
        setClearButton(true);
    }

    function handleMouseEnter() {
        if (selectedOption === "") {
            return;
        }
        setClearButton(true);
    }

    function handleMouseLeave() {
        if (selectedOption === "") {
            return;
        }
        setClearButton(false);
    }

    function handleClearClick() {
        setSelectedOption("");
        setClearButton(false);
    }

    function handleUnfocus(event) {
        setTimeout(() => setClearButton(false), 200);
    }


    useEffect(() => {
        async function fetchData() {
            try {
                await setServerDropDown(setServersList, userDetails, teamDetails);
            }
            catch (error) {
                // console.log(error);
            }
        }
        fetchData();
    }, []);

    async function setServerDropDown(setServersList, userDetails, teamDetails) {
        return new Promise((resolve, reject) => {
            const userEmail = userDetails.current.auth.email_id;
            const userRole = teamDetails.current[userEmail].Role;

            if (userRole === "CEO") {
                setServersList(teamDetails.current["mariaraj@zohocorp.com"].Servers.split(","));
            }
            else {
                setServersList(teamDetails.current[userEmail].Servers.split(","));
            }
            return resolve();
        });
    }

    return (
        <>
            <Chip
                label={
                    <Box style={{
                        display: "flex",
                        flexDirection: "row",
                        // justifyContent: "center",
                        alignItems: "center"
                    }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        {/* <Box style={{
                            //paddingLeft: "6px",
                            color: "ghostwhite"
                        }}> */}
                            {selectedOption}
                            <Select
                                required={required}
                                //labelId="role-user-email"
                                value={""}
                                style={{
                                    minHeight: height,
                                    height: "auto",
                                    padding: 0
                                    // minWidth: "30px",
                                    // maxWidth: "220px"
                                }}
                                MenuProps={{
                                    style: {
                                        maxHeight: 600
                                    }
                                }}
                                onChange={handleSelect}
                                onBlur={handleUnfocus}
                                sx={{
                                    boxShadow: "none",
                                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                    {
                                        border: 0,
                                    },
                                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                        border: 0,
                                    },
                                    padding: 0
                                }}
                                disableUnderline={true}
                            >
                                {sortedServersList.map((value, index) => (
                                    <MenuItem key={index} value={value}>{value}</MenuItem>
                                ))};
                            </Select>
                            {/* <Button style={{ visibility: clearButton ? "visible" : "hidden" }} onClick={handleClearClick}>
                                <CancelIcon fontSize="small" color="error" />
                            </Button> */}
                        {/* </Box> */}
                    </Box>
                }
                sx={{
                    bgcolor: "#226DB4 !important",
                    border: "1px solid #0c2c70"
                }}
            />
        </>
    );
}

function TimelineComponent({viewSelected, setViewSelected , selectedOption,setSelectedOption}){

    const {loginUserDept,GlobalServer,teamDetails,userDetails,allServers} = useContext(UserContext);
    const user_email = userDetails.current.auth.email_id;
    var SelectedGlobalServer = (GlobalServer === "unknown" || GlobalServer === undefined) ?  "No Server" : GlobalServer;
    const [loaded, setLoaded] = useState(undefined);
    const [rows, setRows] = useState(undefined);
    const [internalDatas, setInternalDatas] = useState([]);

    var rrows = [];
    var internalrowdata = [];

    async function ExternalFeatureData() {
        try {
            if (SelectedGlobalServer === "No Server") {
                const userserver = teamDetails.current[user_email].Servers.split(",").filter(server => allServers.includes(server)).join(",");
                const result = await queryDB(report_5_data_fetcher_get_query_1(viewSelected, loginUserDept,userserver));
                return result;
            }
            else{
               
                const result = await queryDB(report_5_data_fetcher_get_query_6(viewSelected,loginUserDept,SelectedGlobalServer));
                return result;
            }
        } catch (error) {
            // console.log(error);
            throw error;
        }
    }

    useEffect(() => {
        setLoaded(undefined);
   }, [GlobalServer,viewSelected]);

    useEffect(() => {
        ExternalFeatureData()
            .then((queryResult) => {
                // console.log('Query result :', queryResult);
                if (queryResult.length > 0) {
                    for (var item of queryResult) {
                        var rrow = [];
                        rrow.push(item.ExternalFeatureReport.Feature);//0
                        rrow.push(item.ExternalFeatureReport.Module);//1
                        rrow.push(item.ExternalFeatureReport.Over_All_ETA);//2
                        rrow.push(item.ExternalFeatureReport.Module_ETA);//3
                        rrow.push(item.ExternalFeatureReport.ReleasePlan);
                        rrow.push(item.ExternalFeatureReport.CREATEDTIME);//5
                        rrow.push(item.ExternalFeatureReport.TeamName);
                        rrow.push(item.ExternalFeatureReport.CompletionDate);//7
                        rrow.push(item.ExternalFeatureReport.Server_Name);
                        rrow.push(item.ExternalFeatureReport.Server_Completion);//9
                        rrow.push(item.ExternalFeatureReport.Server_Total);
                        rrow.push(item.ExternalFeatureReport.Sever_DRI);//11
                        rrow.push(item.ExternalFeatureReport.CreatorRowid);//12
                        rrow.push(item.ExternalFeatureReport.TypeOfRoadmap);//13
                        rrows.push(rrow);   
                    }
                    setRows(rrows); 
                    setLoaded(true);
                } else {
                    setLoaded(false);
                    
                }
            })
            .catch((error) => {
                console.error('Error querying data:', error);
                setLoaded(null);
                
            });
        }, [viewSelected,GlobalServer]);
        // console.log(rows)
        // console.log(viewSelected)
        async function InternalData() {
            try {
                //console.log(selectedOption);
                const result = await queryDB(report_5_data_fetcher_get_query_2(selectedOption, loginUserDept));
                return result;
            } catch (error) {
                throw error;
            }
        }
    
    // useEffect(() => {
    //     InternalData()
    //         .then((queryResult) => {
    //             if (queryResult.length > 0) {
    //                 var milestoneMap = {};
    //                 for (const tasklist of queryResult) {
    //                     if (tasklist.TaskDetails.MilestoneName in milestoneMap) {
    //                         milestoneMap[tasklist.TaskDetails.MilestoneName].push(tasklist);
    //                     } else {
    //                         milestoneMap[tasklist.TaskDetails.MilestoneName] = [tasklist];
    //                     }
    //                 }

    //                 for (const mls in milestoneMap) {
    //                     const tasklists = milestoneMap[mls];
    //                     //console.log(mls);

    //                     // var obj = {
    //                     //     //"title": mls,
    //                     //     //"elements": []
    //                     // };
    //                     var obj = [];
    //                     tasklists.map((tasklist) => {
                            
    //                         finalArr.push({
    //                             start_date: tasklist.TaskDetails.TasklistStartDate_ !== null ? new Date(tasklist.TaskDetails.TasklistStartDate_) : undefined,
    //                             due_date: tasklist.TaskDetails.TasklistDueDate_ !== null ? new Date(tasklist.TaskDetails.TasklistDueDate_) : undefined,
    //                             completion_date: tasklist.TaskDetails.CompletionDate !== null ? new Date(tasklist.TaskDetails.CompletionDate) : undefined,
    //                             module_name: "AWS",
    //                             servers: [
    //                                 {
    //                                     name: "AMS",
    //                                     completion: 8,
    //                                     total: 10
    //                                 },
    //                                 {
    //                                     name: "Media",
    //                                     completion: 16,
    //                                     total: 20
    //                                 },
    //                                 {
    //                                     name: "AWS",
    //                                     completion: 6,
    //                                     total: 8
    //                                 },
    //                                 {
    //                                     name: "RTCP Cloud",
    //                                     completion: 5,
    //                                     total: 7
    //                                 },
    //                                 {
    //                                     name: "PubSub",
    //                                     completion: 1,
    //                                     total: 4
    //                                 },
    //                                 {
    //                                     name: "NioClient",
    //                                     completion: 3,
    //                                     total: 5
    //                                 }
    //                             ],
    //                             dris: [
    //                                 tasklist.TaskDetails.DRI
    //                             ],
    //                             // dris: [
    //                             //     "manoj.sakthivel@zohocorp.com",
    //                             //     "jayasuriya.bm@zohocorp.com",
    //                             //     "karthick.masilamani@zohocorp.com"
    //                             // ],
    //                             due_date_extension: [new Date(), new Date()],
    //                             feature_name: tasklist.TaskDetails.TasklistName,
    //                             release_type: "Monthly",
    //                             requested_by: <EmployeeCards emails={tasklist.TaskDetails.Email}/>
    //                         });
                        
    //                     })
    //                     //finalArr.push(obj);
    //                     //console.log(finalArr);
    //                     //console.log(InternalDatas)
                        
    //                 }
    //                 setLoaded(true);
    //                 setInternalDatas(finalArr);

    //                 // for (var item of queryResult) {
    //                 //     var introw = [];
    //                 //     introw.push(item.TaskDetails.TasklistName);
    //                 //     introw.push(item.TaskDetails.MilestoneName);
    //                 //     internalrowdata.push(introw);   
    //                 // }
    //                 // setInternalRows(internalrowdata);
    //                 //setLoaded(true);
    //             } else {
    //                 //setLoaded(false);
    //                 setInternalDatas([]);
    //             }
    //         })
    //         .catch((error) => {
    //             console.error('Error querying data:', error);
    //             setLoaded(null);

    //         });
    // }, [selectedOption]);

    var dat = [];
    const activeStep = 16;

    for(var i=0; i< 30; i++) {
        dat.push({
            start_date: i%3 == 0 ? new Date() : undefined,
            due_date: i%3 === 0 ? new Date() : (i%3 === 1 ? new Date("2023-07-30") : undefined ),
            completion_date: i%3 == 1 ? new Date("2002-05-19") : (i%3 == 2 ? new Date("2023-08-20") : undefined),
            module_name: "Module Test",
            servers: [
                {
                    name: "AMS",
                    completion: 8,
                    total: 10,
                    milestone_id: "2029477000000290003"
                },
                {
                    name: "Media",
                    completion: 16,
                    total: 20,
                    milestone_id: "2029477000000290003"
                },
                {
                    name: "AWS",
                    completion: 6,
                    total: 8,
                    milestone_id: "2029477000000222013"
                },
                {
                    name: "RTCP Cloud",
                    completion: 5,
                    total: 7,
                    milestone_id: "2029477000000222013"
                },
                {
                    name: "PubSub",
                    completion: 1,
                    total: 4,
                    milestone_id: "2029477000000222013"
                },
                {
                    name: "NioClient",
                    completion: 3,
                    total: 5,
                    milestone_id: "2029477000000222013"
                }
            ],
            dris: i%2 === 0 ? [
                "jayasuriya.bm@zohocorp.com",
                "karthick.masilamani@zohocorp.com"
            ] : ["manoj.sakthivel@zohocorp.com"],
            due_date_extension: [new Date(), new Date()],
            feature_name: "Test Feature Name comes here. asdfasdfasdfasdf dsfasdf asdf",
            release_type: i%2 === 0 ? "Q1" : "October",
            requested_by: i%2 === 0 ? "cliq" : "other"
        });
    }


    if (loaded === undefined) {
        return (
            <div className="page-content-2">
               <>
                    <Skeleton variant="rectangular" width={"100%"} height={50} />
                    <br></br>
                    <Skeleton variant="rectangular" width={"100%"} height={115} />
                    <br></br>
                    <Skeleton variant="rectangular" width={"100%"} sx={{ flex: 1 }} />
                </>
            </div>
        );
    } else if (loaded === null) {
        return (
            "something went wrong..."
        );
    } else if (loaded === false) {
        return (
            <h2 style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                No data Found :( 
              </h2>
            // "No data Found :( "
        );
    } 
    else 
    {
        var datas = rows.map((row, index) => {
            var data = [];
            var dri = [];
            try {
                data = JSON.parse(row[9]);
                dri = JSON.parse(row[11]);
            } catch (e) {
                console.log(e);
                data = {};
                dri = {};
            }
            return {
                start_date: row[5] !== null ? new Date(row[5]) : undefined,
                due_date: row[2] !== null ? new Date(row[2]) : undefined,
                completion_date: row[7] !== null ? new Date(row[7]) : undefined,
                module_name: row[1],
                servers: row[9] !== null ? data : [],
                // dris: [
                //     "jayasuriya.bm@zohocorp.com",
                //     "karthick.masilamani@zohocorp.com"
                // ],
                dris: row[11] !== null ? dri : undefined,
                due_date_extension: [new Date(), new Date()],
                feature_name: row[0] !== null ? row[0] : undefined,
                release_type: "Monthly",
                requested_by: row[6] !== null ? row[6] : undefined,
                CreatorRowid: row[12],
                TypeOfRoadmap: row[13]
            };
        });

        //console.log(internalDatas);
        // var intdatas = internalDatas.map((row, index) => {
        //     var data1 = [];
        //     //console.log(row[6]);
        //     try {
        //         data1 = JSON.parse(row[6]);
        //     } catch (e) {
        //         console.log(e);
        //     }
        //     return {
        //         start_date: row[1] !== null ? new Date(row[1]) : undefined,
        //         due_date: row[2] !== null ? new Date(row[2]) : undefined,
        //         completion_date: row[3] !== null ? new Date(row[3]) : undefined,
        //         module_name: row[4],
        //         servers: row[6] !== null ? data1 : [],
        //         // dris: [
        //         //     "jayasuriya.bm@zohocorp.com",
        //         //     "karthick.masilamani@zohocorp.com"
        //         // ],
        //         dris: row[11] !== null ? new Date(row[11]) : undefined,
        //         due_date_extension: [new Date(), new Date()],
        //         feature_name: row[0] !== null ? row[0] : undefined,
        //         release_type: "Monthly",
        //         requested_by: <EmployeeCards emails={row[5] !== null ? row[5] : undefined} />
        //     };
        // });
        
    }


    return (
        <>

            {
                viewSelected === "Internal" ?
                    <Box className="page-content-without-padding" sx={{
                        height: "100%",
                        width: "100%",
                        boxSizing: "border-box"
                    }}>
                        <VerticalTimeline steps={datas} activeStep={activeStep} />
                    </Box>

                    : null
            }

            {
                viewSelected === "External" ?
                    <Box className="page-content-without-padding" sx={{
                        height: "100%",
                        width: "100%",
                        boxSizing: "border-box"
                    }}>
                        {/* <CalendarTimeline data={datas} mainTitle={viewSelected} /> */}
                        <VerticalTimeline steps={datas} activeStep={activeStep} />
                    </Box>
                    : null
            }

{
                viewSelected === "Combined" ?
                    <Box className="page-content-without-padding" sx={{
                        height: "100%",
                        width: "100%",
                        boxSizing: "border-box"
                    }}>
                        {/* <CalendarTimeline data={datas} mainTitle={viewSelected} /> */}
                        <VerticalTimeline steps={datas} activeStep={activeStep} />
                    </Box>
                    : null
            }
        </>
    );
}

function GridComponent({selectedOption, setSelectedOption}){

    const [searchText, setSearchText] = useState("");
    const { userDetails, teamDetails } = useContext(UserContext);
    const user_email = userDetails.current.auth.email_id;
	const user_role = teamDetails.current[user_email].Role;
	const userserver = teamDetails.current[user_email].Servers;
	const userserverArr = teamDetails.current[user_email].Servers.split(",");
    const [filterBy, setFilterBy] = useState(userserverArr);
    const [showRoadMap, setShowRoadMap] = useState(false);

    return(
        <>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                overflow: "hidden",
                gap: "5px"
            }} className="page-content-2">

                <Topbar selectedOption={selectedOption} setSelectedOption={setSelectedOption} searchText={searchText} setSearchText={setSearchText} filterBy={filterBy} setFilterBy={setFilterBy} showRoadMap={showRoadMap} setShowRoadMap={setShowRoadMap}/>
                
                {showRoadMap ? (
                    <RoadMapComponent
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        searchText={searchText}
                        setSearchText={setSearchText}
                    />
                ) : (
                    <TaskListComponent
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        searchText={searchText}
                        setSearchText={setSearchText}
                        filterBy={filterBy}
                        setFilterBy={setFilterBy}
                    />
                )}            </Box>
        </>
    )
}

function Topbar({ selectedOption, setSelectedOption, rows, searchText, setSearchText,filterBy,setFilterBy,showRoadMap,setShowRoadMap  }) {
    const [open, setOpen] = useState(false);
    const [filterAnchor, setFilterAnchor] = useState(null);
    const [driTree, setDriTree] = useState([]);
    const [driList, setDriList] = useState([]);
    const [driAnchor, setDriAnchor] = useState(null);
    const { userDetails, teamDetails } = useContext(UserContext);

    useEffect(() => {
		async function run() {
                const user_email = userDetails.current.auth.email_id;
                try {
                    await setDriDropDown(setDriList, setDriTree, userDetails, teamDetails);
                }
                catch (error) {
                    // console.log(error);
                }
		}
		run();
	}, []);

    function handleSearchTextChange(event) {
        setSearchText(event.target.value);
    }

    function handleSearchButtonClick() {
        setSearchText("");
        setOpen(!open);
    }

    function handleFilterButtonClick(event) {
		setFilterAnchor(event.currentTarget);
	}

	function handleFilterButtonClose() {
		setFilterAnchor(null);
	}

    function handleDriButtonClose() {
		setDriAnchor(null);
		setFilterAnchor(null);
	}

    async function handleFilterItemClick(event, item) {
		if (item === "Dri") {
			setDriAnchor(event.currentTarget);
		}
		else if (item === "Default") {
			setFilterBy(["Default"]);
		}
	}

    const handleDriTreeItemClick = async (item) => {
		var tempFilterBy = [...filterBy];
		var index = tempFilterBy.indexOf("Default");
		if (index > -1) {
			tempFilterBy.splice(index, 1);
		}
		index = tempFilterBy.indexOf(item.Email);
		if (index > -1) {
			tempFilterBy.splice(index, 1);
		}
		setFilterBy([item.Email, ...tempFilterBy]);
	};

    //console.log(filterBy);

    const handleButtonClick = () => {
        setShowRoadMap(!showRoadMap);
      };

	function handleChipClick(event, item) {
        //console.log(item);
		if(event.detail === 2) {
			setFilterBy([item]);
		}
	}
    
    return (
        <>
            <div className="external-top-bar">
                <div className="external-top-bar-left" style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "20px",
                }}>
                    <Box style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                    width: "calc(50vw)",
                    overflowX: "scroll"
                }}
                    className="filter-sort-overflow-container"
                >
                     <ServerList
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        height={"50px"}
                        required={true}
                    />
                    {
                        searchText !== "" ?
                            <Chip
                                key={"search-chip"}
                                label={
                                    <Box style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Box style={{

                                            color: "#0c2c70",
                                            borderRight: "1px solid #103fa3",
                                            paddingRight: "6px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <SearchIcon />
                                        </Box>
                                        <Box style={{
                                            paddingLeft: "6px",
                                            color: "ghostwhite"
                                            // width: "60px",
                                            // overflow: "hidden",
                                            // textOverflow: "ellipsis",
                                            // textAlign: "center"
                                        }}>
                                            {/* <Tooltip title={searchText} > */}
                                            {searchText}
                                            {/* </Tooltip> */}
                                        </Box>
                                    </Box>
                                }
                                sx={{
                                    bgcolor: "#226DB4 !important",
                                    border: "1px solid #0c2c70"
                                }}
                                onDelete={() => {
                                    setSearchText("");
                                }}
                                deleteIcon={
                                    <CancelIcon
                                        onMouseDown={(event) => event.stopPropagation()}
                                        sx={{
                                            color: "#0c2c70 !important"
                                        }}
                                    />
                                }
                            />
                            :
                            <></>
                    }
                    {/* {
						!filterBy.includes("Default") ?
							filterBy.map((item, index) => {
								return (
									<Chip
										key={"filter-chip-"+index}
										label={
											<Box style={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "center",
												alignItems: "center"
											}} onClick={(event) => handleChipClick(event, item)}>
												<Box style={{
													color: "#331511",
													borderRight: "1px solid #53291c",
													paddingRight: "6px",
													display: "flex",
													alignItems: "center",
													justifyContent: "center"
												}}>
													<FilterAltOutlinedIcon />
												</Box>
												<Box style={{
													paddingLeft: "6px",
													color: "ghostwhite",
													width: "70px",
													overflow: "hidden",
													textOverflow: "ellipsis",
													textAlign: "center"
												}}>
													<Tooltip title={item}>
														{item}
													</Tooltip>
												</Box>
											</Box>
										}
										sx={{
											// bgcolor: "rgb(228,37,39, 0.9) !important"
											background: "rgba(177,44,9,1) !important",
											border: "1px solid #53291c"
										}}
										onDelete={() => {
											var tempFilterBy = [...filterBy];
											var index = tempFilterBy.indexOf(item);
											if (index > -1) {
												tempFilterBy.splice(index, 1);
											}
											if (tempFilterBy.length === 0) {
												tempFilterBy.push("Default");
											}
											setFilterBy(tempFilterBy);
										}}
										deleteIcon={
											<CancelIcon
												onMouseDown={(event) => event.stopPropagation()}
												sx={{
													color: "#53291c !important"
												}}
											/>
										}
									/>
								);
							})
							:
							<></>
					} */}
                    <div style={{
					}} className="unselectable">
						&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
					</div>
                    </Box>
                </div>
               
                <div className="milestone-top-bar-right-group">
                    <ButtonGroup variant="contained">
                        <Collapse in={open} timeout="auto" orientation="horizontal" unmountOnExit>
                            <TextField variant="outlined" placeholder="search" onChange={handleSearchTextChange} value={searchText} autoFocus />
                        </Collapse>
                        <Tooltip title="search"><Button variant="contained" size="small" onClick={handleSearchButtonClick}><SearchIcon /></Button></Tooltip>
                        <Tooltip title="filter by"><Button variant="contained" size="small" onClick={handleFilterButtonClick}><FilterAltOutlinedIcon /><ArrowDropDownIcon /></Button></Tooltip>
                        <Button variant="contained" onClick={handleButtonClick}>
                            {showRoadMap ? 'Show Milestones' : 'Show RoadMaps'}
                        </Button>
                    </ButtonGroup>
                    <Menu
					anchorEl={filterAnchor}
					open={filterAnchor !== null}
					onClose={handleFilterButtonClose}
				>
					<MenuItem onClick={(event) => handleFilterItemClick(event, "Default")} style={{ justifyContent: "left" }}>Default</MenuItem>
					<MenuItem onClick={(event) => handleFilterItemClick(event, "Dri")}><ArrowLeftIcon />DRI</MenuItem>
                    </Menu>
                    {driTree && driTree.length > 0 && (
                        <MultiLevelDropdown
                            dritree={driTree}
                            driAnchor={driAnchor}
                            handleDriTreeButtonClose={handleDriButtonClose}
                            handleDriTreeItemClick={handleDriTreeItemClick}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

function TaskListComponent({ selectedOption, setSelectedOption , searchText , setSearchText, filterBy , setFilterBy}) {
    const [loaded, setLoaded] = useState(undefined);
    const [rows,setRows] = useState([]);
    const {loginUserDept} = useContext(UserContext);
    // const [searchText, setSearchText] = useState("");

    var rrows = [];

    async function QueryData() {
        try {
            const result = await queryDB(report_5_data_fetcher_get_query_3(selectedOption, loginUserDept));
                // console.log(`SELECT MilestoneName,TasklistName,TasklistStartDate,TasklistDueDate,MilestoneStartDate,MilestoneDueDate,MilestoneStatus,MilestoneId,DRI,Component,Tasklistid,TasklistPriority,MilestoneDescription,TasklistStatus,TasklistUniqueID,TasklistDescription FROM TaskDetails where Component = '${selectedOption}' and IsExternalMilestone = null and TasklistStatus not in ('Completed','TasklistCompleted','Updated To Idc')`)
            return result; 
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    useEffect(() => {
        QueryData()
            .then((queryResult) => {
                //console.log('Query result - Tasklist data:', queryResult);
                // Serverlist,Tasklistid,TasklistPriority,MilestoneDescription,TasklistStatus,TasklistUniqueID
                if (queryResult.length > 0) {
                    for (var item of queryResult) {
                        var rrow = [];
                        // rrow.push([undefined, "", " ", null].includes(item.TaskDetails.TasklistUniqueID) ? "-" : item.TaskDetails.TasklistUniqueID);
                        rrow.push(item.TaskDetails.TasklistName);
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.TasklistUniqueID) ? "-" : item.TaskDetails.TasklistUniqueID);
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.TasklistPriority) ? "-" : item.TaskDetails.TasklistPriority);
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.TasklistDueDate) ? "-" : item.TaskDetails.TasklistDueDate);
                        rrow.push(item.TaskDetails.DRI);
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.TasklistStartDate) ? "-" : item.TaskDetails.TasklistStartDate);
                        rrow.push(item.TaskDetails.MilestoneName);
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.MilestoneStartDate) ? "-" : item.TaskDetails.MilestoneStartDate);
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.MilestoneDueDate) ? "-" : item.TaskDetails.MilestoneDueDate);
                        rrow.push(item.TaskDetails.MilestoneStatus);
                        rrow.push(item.TaskDetails.MilestoneId);
                        rrow.push(item.TaskDetails.Component);
                        rrow.push(item.TaskDetails.Tasklistid);
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.MilestoneDescription) ? "-" : item.TaskDetails.MilestoneDescription);
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.TasklistStatus) ? "-" : item.TaskDetails.TasklistStatus);
                        rrow.push([undefined, "", " ", null].includes(item.TaskDetails.TasklistDescription) ? "-" : item.TaskDetails.TasklistDescription);
                        rrows.push(rrow);

                    }
                    setRows(rrows);
                    setLoaded(true);
                } else {
                    setLoaded(false);

                }
            })
            .catch((error) => {
                console.error('Error querying data:', error);
                setLoaded(null);

            });
    }, [selectedOption]);
    

    if (loaded === undefined) {
        return (
            <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
        );
    } else if (loaded === null) {
        return (
            "something went wrong..."
        );
    } else if (loaded === false) {
        return (
            <h2 style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                No Milestone Found :(
              </h2>
        );
    } else {
    return(
        <Box sx={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            //alignItems: "flex-end",
            // justifyContent: "flex-end"
        }}>
            {/* <TasklistTopbar selectedOption={selectedOption} setSelectedOption={setSelectedOption} rows={rows} searchText={searchText} setSearchText={setSearchText} /> */}
            <TasklistTable selectedOption={selectedOption} setSelectedOption={setSelectedOption} rows={rows} searchText={searchText} setSearchText={setSearchText} />
        </Box>
    )
    }
}

function TasklistTable({ selectedOption, setSelectedOption,rows ,searchText,setSearchText}) {

    rows = rows.filter(row_ => {
		for (const i in row_) {
            const item = row_[i];
			if (item === null || item === undefined) {
				continue;
			}
			const aString = item.toString().toLowerCase().replaceAll(" ", "");
			const bString = searchText.toLowerCase().replaceAll(" ", "");
			if (aString.indexOf(bString) !== -1) {
				return true;
			}
		}
		return false;
	});

    function CompareFunction(a, b) {

        const priorityMap = {
            high: 1,
            medium: 2,
            low: 3,
            none: 4
        };

        const priorityA = a[2] ? a[2].toLowerCase() : ''; // Ensure it's a valid string or empty string
        const priorityB = b[2] ? b[2].toLowerCase() : '';

        const priorityValueA = priorityMap[priorityA] || Number.MAX_VALUE;
        const priorityValueB = priorityMap[priorityB] || Number.MAX_VALUE;


        return priorityValueA - priorityValueB;
    }

    rows = rows.sort(CompareFunction);
    
    var filteredData = rows;
    let rowSpanInfo = [];
    var dataValue = [];

    if (rows.length > 0) {
        filteredData = group_by_order(rows, [2], 0);
        //console.log(filteredData);

        rowSpanInfo = find_row_span(filteredData, [2]);
        // console.log(rowSpanInfo);
    }
    // console.log(rows)

    
        return (
            <>
                <div className="external-table">
                    <TableContainer component={Paper} className="external-table-container">
                        <Table stickyHeader >
                            <TableHead>
                                <TableRow className="external-table-head">
                                    <TableCell align="left" className="external-table-grouper-header" />
                                    <TableCell align="left" className="external-table-grouper-header" />
                                    <TableCell align="center" style={{ width: '5vw' }}>S.No</TableCell>
                                    <TableCell align="center" style={{ width: '10vw' }}>Tasklist</TableCell>
                                    <TableCell align="center" style={{ width: '10vw' }}>Start Date</TableCell>
                                    <TableCell align="center" style={{ width: '10vw' }}>Due Date</TableCell>
                                    <TableCell align="right" className="external-table-grouper-header" />
                                    {/* <TableCell align="left" className="external-table-grouper-header" /> */}

                                </TableRow>
                            </TableHead>
                            <TableBody id="list-view">
                                {
                                    Array.isArray(filteredData) ?
                                    filteredData.map((row, index) => {
                                            return <TasklistRow key={index} row={row} index={index} rowSpanInfo={rowSpanInfo}/>;
                                        })
                                        :
                                        null
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </>
        );
    
}

function TasklistRow({row, index , rowSpanInfo}){
    // console.log(row[6]);
    var dataArray = []; 
    dataArray.push(row[6]);
    var dataArray1 = []; 
    dataArray1.push(row);
    // console.log(dataArray1.map((item) => item[5]))
    const [open, setOpen] = React.useState(null);
    const theme = useTheme();

    const handleClick = () => {
        if (open === index) {
                setOpen(null);
            }
      
            else {
                setOpen(index);
            }
      };
    return (
        <>
            <TableRow className="external-table-head">
                {
                    rowSpanInfo[index][2] !== 0 ?
                        <TableCell
                            className={`milestone-table-grouper-component milestone-single-row rotated-text ${row[2]}`}
                            align="center"
                            width={"6px"}
                            rowSpan={2 * rowSpanInfo[index][2]}
                            sx={{
                                fontSize: "0.8rem !important",
                                backgroundColor: row[2] === 'High' ? 'red !important' : row[2] === 'Medium' ? 'Orange !important' : row[2] === 'Low' ? 'lightgreen !important' : 'transparent'
                            }} >
                            {row[2]}
                        </TableCell>
                        :
                      null

                }
                 <TableCell align="left" className='slide-animation' style={{ width: "30px"  }}>
                    <IconButton
						size="small"
						onClick={handleClick}
					>
						{open === null ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon />}
					</IconButton>
             </TableCell>
                <TableCell onClick={handleClick} align="center">{index+1}</TableCell>
                <TableCell onClick={handleClick} align="center" >
                    <Tooltip title={row[0]}>
                        {row[0]}
                    </Tooltip>
                </TableCell>
                <TableCell onClick={handleClick} align="center" >{row[5]}</TableCell>
                <TableCell onClick={handleClick} align="center" >{row[3]}</TableCell>
                <TableCell onClick={handleClick} align="center"></TableCell>
            </TableRow>

            <TableRow className="info-div-row"	 sx={{backgroundColor: theme.palette.background.infoDivOuter + " !important"}}>
                     <TableCell colSpan={8} style={{ padding:0 }}>
                    <Collapse in={open !== null} timeout="auto" unmountOnExit>
                    <CombinedInfo   index={index}  MilestoneName={dataArray} MilestoneStartDate={dataArray1.map((item) => item[7])} MilestoneDueDate={dataArray1.map((item) => item[8])} Status={dataArray1.map((item) => item[9])} MilestoneId={dataArray1.map((item) => item[10])} DRI={dataArray1.map((item) => item[4])} Server={dataArray1.map((item) => item[11])} Tasklistid={dataArray1.map((item) => item[12])} TasklistPriority={dataArray1.map((item) => item[2])} MilestoneDescription={dataArray1.map((item) => item[13])} MilestoneStatus={dataArray1.map((item) => item[9])} TasklistStatus={dataArray1.map((item) => item[14])}/>
                    {/* <CombinedInfo></CombinedInfo> */}
                    </Collapse>
                    </TableCell>
            </TableRow>
        </>
    )
}

function RoadMapComponent({ selectedOption, setSelectedOption,searchText,setSearchText }) {
    const [loaded, setLoaded] = useState(undefined);
    const [rows, setRows] = useState([]);
    const {loginUserDept} = useContext(UserContext);
    //const [searchText, setSearchText] = useState("");

    var rrows = [];

    async function QueryData() {
        try {
            const result = await queryDB(report_5_data_fetcher_get_query_4(selectedOption, loginUserDept));
            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    useEffect(() => {
        QueryData()
            .then((queryResult) => {
                //console.log('Query result - Low Priority:', queryResult);
                if (queryResult.length > 0) {
                    for (var item of queryResult) {
                        var rrow = [];
                        rrow.push(item.TaskBreaker.TaskName);
                        rrow.push(item.TaskBreaker.Email);
                        rrow.push(item.TaskBreaker.TaskPriority);
                        rrows.push(rrow);


                    }
                    setRows(rrows);
                    setLoaded(true);
                } else {
                    setLoaded(false);

                }
            })
            .catch((error) => {
                console.error('Error querying Roadmap data:', error);
                setLoaded(null);

            });
    }, [selectedOption]);

    //console.log(rows);

    if (loaded === undefined) {
        return (
            <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
        );
    } else if (loaded === null) {
        return (
            "something went wrong..."
        );
    } else if (loaded === false) {
        return (
            <h2 style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                No RoadMap Found :(
              </h2>
        );
    } else {

        return (
            <Box sx={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                // alignItems: "flex-end",
                // justifyContent: "flex-start"
            }}>
                {/* <RoadMapTopbar selectedOption={selectedOption} setSelectedOption={setSelectedOption} rows={rows} searchText={searchText} setSearchText={setSearchText} /> */}
                <RoadMapTable selectedOption={selectedOption} setSelectedOption={setSelectedOption} rows={rows} searchText={searchText} setSearchText={setSearchText} />
            </Box>
        );
    }
}

// function RoadMapTopbar({ selectedOption, setSelectedOption, rows, searchText, setSearchText }) {
//     const [open, setOpen] = useState(false);

//     function handleSearchTextChange(event) {
//         setSearchText(event.target.value);
//     }

//     function handleSearchButtonClick() {
//         setSearchText("");
//         setOpen(!open);
//     }

//     return (
//         <>
//             <div className="external-top-bar">
//                 <div className="external-top-bar-left" style={{
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                     gap: "20px",
//                 }}>
//                     <Box style={{
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                     gap: "10px",
//                     width: "calc(34vw)",
//                     overflowX: "scroll"
//                 }}
//                     className="filter-sort-overflow-container"
//                 >
//                         <Chip
//                             label={
//                                 <Box style={{
//                                     display: "flex",
//                                     flexDirection: "row",
//                                     justifyContent: "center",
//                                     alignItems: "center"
//                                 }}>
//                                     <Box style={{

//                                         color: "#0c2c70",
//                                         borderRight: "1px solid #103fa3",
//                                         paddingRight: "6px",
//                                         display: "flex",
//                                         alignItems: "center",
//                                         justifyContent: "center"
//                                     }}>

//                                     </Box>
//                                     <Box style={{
//                                         paddingLeft: "6px",
//                                         color: "ghostwhite"

//                                     }}>
//                                         {/* <Tooltip title={searchText} > */}
//                                         {selectedOption}
//                                         {/* </Tooltip> */}
//                                     </Box>
//                                 </Box>
//                             }
//                             sx={{
//                                 bgcolor: "#226DB4 !important",
//                                 border: "1px solid #0c2c70"
//                             }}
//                         />
//                     {
//                         searchText !== "" ?
//                             <Chip
//                                 key={"search-chip"}
//                                 label={
//                                     <Box style={{
//                                         display: "flex",
//                                         flexDirection: "row",
//                                         justifyContent: "center",
//                                         alignItems: "center"
//                                     }}>
//                                         <Box style={{

//                                             color: "#0c2c70",
//                                             borderRight: "1px solid #103fa3",
//                                             paddingRight: "6px",
//                                             display: "flex",
//                                             alignItems: "center",
//                                             justifyContent: "center"
//                                         }}>
//                                             <SearchIcon />
//                                         </Box>
//                                         <Box style={{
//                                             paddingLeft: "6px",
//                                             color: "ghostwhite"
//                                             // width: "60px",
//                                             // overflow: "hidden",
//                                             // textOverflow: "ellipsis",
//                                             // textAlign: "center"
//                                         }}>
//                                             {/* <Tooltip title={searchText} > */}
//                                             {searchText}
//                                             {/* </Tooltip> */}
//                                         </Box>
//                                     </Box>
//                                 }
//                                 sx={{
//                                     bgcolor: "#226DB4 !important",
//                                     border: "1px solid #0c2c70"
//                                 }}
//                                 onDelete={() => {
//                                     setSearchText("");
//                                 }}
//                                 deleteIcon={
//                                     <CancelIcon
//                                         onMouseDown={(event) => event.stopPropagation()}
//                                         sx={{
//                                             color: "#0c2c70 !important"
//                                         }}
//                                     />
//                                 }
//                             />
//                             :
//                             <></>
//                     }
//                     <div style={{
// 					}} className="unselectable">
// 						&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
// 					</div>
//                     </Box>
//                 </div>
               
//                     <div className="external-top-bar-right-group">
//                         <ButtonGroup variant="contained">
//                             <Collapse in={open} timeout="auto" orientation="horizontal" unmountOnExit>
//                                 <TextField variant="outlined" placeholder="search" onChange={handleSearchTextChange} value={searchText} autoFocus />
//                             </Collapse>
//                             <Tooltip title="search"><Button variant="contained" size="small" onClick={handleSearchButtonClick}><SearchIcon /></Button></Tooltip>
//                         </ButtonGroup>

//                     </div>
                
//             </div>
//         </>
//     );
// }

function RoadMapTable({ selectedOption, setSelectedOption,rows ,searchText,setSearchText}){

    rows = rows.filter(row_ => {
		for (const i in row_) {
            const item = row_[i];
			if (item === null || item === undefined) {
				continue;
			}
			const aString = item.toString().toLowerCase().replaceAll(" ", "");
			const bString = searchText.toLowerCase().replaceAll(" ", "");
			if (aString.indexOf(bString) !== -1) {
				return true;
			}
		}
		return false;
	});

    function CompareFunction(a, b) {

        const priorityMap = {
            high: 1,
            medium: 2,
            low: 3,
            none: 4
        };

        const priorityA = a[2] ? a[2].toLowerCase() : ''; // Ensure it's a valid string or empty string
        const priorityB = b[2] ? b[2].toLowerCase() : '';

        const priorityValueA = priorityMap[priorityA] || Number.MAX_VALUE;
        const priorityValueB = priorityMap[priorityB] || Number.MAX_VALUE;


        return priorityValueA - priorityValueB;
    }

    rows = rows.sort(CompareFunction);
    
    var filteredData = rows;
    let rowSpanInfo = [];
    var dataValue = [];

    if (rows.length > 0) {
        filteredData = group_by_order(rows, [2], 0);
        //console.log(filteredData);

        rowSpanInfo = find_row_span(filteredData, [2]);
        // console.log(rowSpanInfo);
    }
    
    return (
        <>
            <div className="external-table">
                <TableContainer component={Paper} className="external-table-container">
                    <Table stickyHeader >
                        <TableHead>
                            <TableRow className="external-table-head">
                                <TableCell align="left" className="external-table-grouper-header" />
                                <TableCell align="left" style={{ width: '1vw' }}>S.No.</TableCell>
                                <TableCell align="center" style={{ width: '20vw' }}>RoadMap</TableCell>
                                <TableCell align="center" style={{ width: '10vw' }}>RequestedBy</TableCell>                        
                                <TableCell align="right" className="external-table-grouper-header" />
                            </TableRow>
                        </TableHead>
                        <TableBody id="list-view">
                            {
                                Array.isArray(filteredData) ?
                                filteredData.map((row, index) => {
                                        return <RoadmapRow key={index} row={row} index={index} rowSpanInfo={rowSpanInfo}/>;
                                    })
                                    :
                                    null
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}

function RoadmapRow({row, index , rowSpanInfo}){
    return (
        <>
            <TableRow className="external-table-head" >
                {
                    rowSpanInfo[index][2] !== 0 ?
                        <TableCell className={`milestone-table-grouper-component milestone-single-row rotated-text ${row[2]}`} align="center" width={"6px"} rowSpan={rowSpanInfo[index][2]} sx={{
                            fontSize: "0.8rem !important",
                            backgroundColor: row[2] === 'High' ? 'red !important' : row[2] === 'Medium' ? 'Orange !important' : row[2] === 'Low' ? 'lightgreen !important' : 'transparent'
                        }} >
                            {row[2]}
                        </TableCell>
                        :
                        null

                }
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center" >{row[0]}</TableCell>
                <TableCell align="center" ><EmployeeCards emails={row[1]} /></TableCell>
                <TableCell align="center"></TableCell>
            </TableRow>
        </>
    )
}

export async function setDriDropDown(setDriList, setDriTree, userDetails, teamDetails, dept) {
	const query = report_5_data_fetcher_get_query_5(dept);
	var result2 = await getFromTeamDetails(query);
	const head_parent = "srinivasan@zohocorp.com";
	// const head_root = "mariaraj@zohocorp.com";
	const head_root = ["nishanth.g@zohocorp.com", "manoj.rs@zohocorp.com"];
	//console.log(result2);
	const employees = result2;
	var hierarchicalData = [];
	hierarchicalData = createHierarchy(employees, "srinivasan@zohocorp.com");

	return new Promise((resolve, reject) => {
		const userEmail = userDetails.current.auth.email_id;
		const userRole = teamDetails.current[userEmail].Role;
		const userReportingTo = teamDetails.current[userEmail].ReportingTo;
		var users = [];
		if (userRole === "CEO" && userEmail ==="mariaraj@zohocorp.com") {
			for (const user in teamDetails.current) {
				users.push(user);
			}

			users.sort();
			setDriList(users);
			setDriTree(hierarchicalData);
		}

		else if(userRole === "CEO" && userEmail !=="mariaraj@zohocorp.com")
		{
			for (const user in teamDetails.current) {
				users.push(user);
			}
			users.sort();
			setDriList(users);
			setDriTree(hierarchicalData);
			users = findMentees([userEmail], teamDetails);
			users.sort();
			users = [userEmail, ...users];
			setDriList(users);
			hierarchicalData = createHierarchy(employees, userReportingTo);
			const getDriList = [];
			getDriList.push(getMentorWithMentees(hierarchicalData, userEmail));
			var hierarchicalData11 = [];
	        hierarchicalData11 = createHierarchy(employees, "srinivasan@zohocorp.com");
			hierarchicalData11[1]=getDriList[0];
			setDriTree(hierarchicalData11);

		}

		else {
			users = findMentees([userEmail], teamDetails);
			users.sort();
			users = [userEmail, ...users];
			setDriList(users);
			hierarchicalData = createHierarchy(employees, userReportingTo);
			const getDriList = [];
			getDriList.push(getMentorWithMentees(hierarchicalData, userEmail));
			setDriTree(getDriList);
		}
		return resolve();
	});
}

export function findMentees(userEmails, teamDetails) {
	if (userEmails.length === 0) {
		return [];
	}

	const arr = [];

	for (const person in teamDetails.current) {
		if (userEmails.includes(teamDetails.current[person].ReportingTo)) {
			arr.push(person);
		}
	}

	const newArr = arr.concat(findMentees(arr, teamDetails));

	return newArr;
}
export function createHierarchy(data, reportingTo) {
	const hierarchy = [];

	for (const employee of data) {
		//console.log(employee);
		if (employee.ReportingTo === reportingTo) {
			const subordinates = createHierarchy(data, employee.Email);
			if (subordinates.length) {
				employee.mentees = subordinates;
			}
			hierarchy.push(employee);
		}
	}
	hierarchy.sort((a, b) => {
		const empIDA = parseInt(a.EmployeeID, 10);
		const empIDB = parseInt(b.EmployeeID, 10);
		return empIDA - empIDB;
	});
	return hierarchy;
}

export function getMentorWithMentees(hierarchy, mentorEmail) {
	for (const mentor of hierarchy) {
		if (mentor.Email === mentorEmail) {
			return mentor;
		} else if (mentor.mentees && mentor.mentees.length > 0) {
			const foundMentor = getMentorWithMentees(mentor.mentees, mentorEmail);
			if (foundMentor) {
				return foundMentor;
			}
		}
	}
	return null;
}