import React, { useState, useContext } from 'react';
import { Box, IconButton, List, ListItem, ListItemText, Popover, Avatar, TextField } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { UserContext } from '../../../../contexts/UserContext';
import { GetNameAndEmpID } from '../../../../util/Loading';

function DriSelector({ selectedDRI, setSelectedDRI, fetchTicketReports }) {
  const { teamDetails } = useContext(UserContext);
  const emailList = Object.keys(teamDetails.current);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (email) => {
    setSelectedDRI((prevSelected) =>
      prevSelected.includes(email) ? prevSelected.filter((item) => item !== email) : [...prevSelected, email]
    );
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const open = Boolean(anchorEl);

  // Filter emails based on search term
  const filteredEmailList = emailList.filter((email) =>
    email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', margin: '0px 0px 20px 17px' }}>
      {/* DRI Filter Icon */}
      <IconButton onClick={handleOpen} color="primary" size="small">
        <FilterListIcon sx={{ fontSize: 28 }} />
      </IconButton>

      {/* Popover for DRI Selection */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Box sx={{ maxWidth: 280, maxHeight: 300, overflowY: 'auto', p: 1 }}>
          {/* Search Field */}
          <TextField
            placeholder="Search email"
            variant="outlined"
            size="small"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ mb: 1 }}
          />
          {/* Email List */}
          <List dense>
            {filteredEmailList.map((email) => (
              <ListItem
                button
                key={email}
                selected={selectedDRI.includes(email)}
                onClick={() => handleSelect(email)}
                sx={{
                  backgroundColor: selectedDRI.includes(email) ? 'rgba(63, 81, 181, 0.15)' : 'transparent',
                  '&:hover': { backgroundColor: 'rgba(63, 81, 181, 0.1)' },
                  borderRadius: 1,
                  mb: 0.5,
                }}
              >
                <ListItemText primary={<GetNameAndEmpID emails={email} />} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>

      {/* Display Selected DRIs as Badges */}
      <Box sx={{ ml: 2, display: 'flex', gap: 1 }}>
        {selectedDRI.map((email) => (
          <Box
            key={email}
            sx={{
              display: 'flex',
              alignItems: 'center',
              bgcolor: 'rgba(63, 81, 181, 0.1)',
              border: '1px solid rgba(63, 81, 181, 0.3)',
              borderRadius: 1,
              padding: '4px 8px',
            }}
          >
            <UserAvatar email={email} />
            <IconButton
              onClick={() => setSelectedDRI((prevSelected) => prevSelected.filter((item) => item !== email))}
              size="small"
              sx={{ ml: 1, color: 'primary.main', padding: 0 }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

function UserAvatar({ email }) {
  const { teamDetails } = useContext(UserContext);
  const username = teamDetails.current[email]?.PeopleUserName;
  const zuid = teamDetails.current[email]?.zuid;
  return (
    <Avatar
      alt={username}
      src={`https://contacts.zoho.com/file?ID=${zuid}&exp=6000&fs=thumb`}
      sx={{ width: 25, height: 25 }}
    />
  );
}

export default DriSelector;
