import React from 'react';

export default function TaskQueue() {
    return (
        <div className="page-content-2">
            <iframe
                src="https://crmplus.zoho.com/zohocorppace/index.do/cxapp/agent/zohocorp/rtcp/requests/teamsq/status/4014983476583"
                title="Zoho CRM"
                style={{ width: '100%', height: '100vh', border: 'none' }}
            ></iframe>
            {/* No Data Available */}
        </div>
    );
}
