//ignorei18n_start
import React, { useState, useEffect, useContext } from 'react';
import { CircularProgress, Typography, Box } from "@mui/material";
import BuildHeader from './BuildHeader'; // Updated import for the header component
import BuildReport from './BuildReport'; // Component to display individual build reports
import {builds_reports_component_get_query_1} from '../../../catalystDB/queryGenerator';
import { queryDB } from '../../../util/QueryDB';
import { UserContext } from '../../../contexts/UserContext';

export default function BuildReportPage() {
    const [buildReports, setBuildReports] = useState([]);
    const [serchedText, setSearchText] = useState("No Data");
    const [startDate, setStartDate] = useState("Reset");
    const [endDate, setEndDate] = useState("Reset");
    const [buildState, setBuildState] = useState("All");
    const [selectedDC, setSelectedDC] = useState("Not Selected");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { GlobalServer , loginUserDept } = useContext(UserContext);
    const GlobalServerData = (GlobalServer === "unknown" || GlobalServer === undefined) ? null : GlobalServer ;

    // Function to fetch build reports
    const fetchBuildReports = async () => {
        try {
            const localBuildState = buildState === "Opted Out" ? "User Opted Out" :
                                buildState === "Tracked" ? "Approved" : "All";
            const localQueryFilter = [startDate, endDate, localBuildState, serchedText, selectedDC].filter(Boolean);
            setBuildReports([]);
            setLoading(true);
            const response = await queryDB(builds_reports_component_get_query_1(loginUserDept,localQueryFilter,GlobalServerData));
            const data = response;
            setBuildReports(data);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };
    

    // Fetch data on component mount
    useEffect(() => {
        fetchBuildReports();
    }, [GlobalServer]);

    // Handle search input
    const handleSearch = async (searchText) => {
        try {
            const localBuildState = 
            buildState === "Opted Out" ? "User Opted Out" :
            buildState === "Tracked" ? "Approved" :
            buildState === "Not Tracked" ? "User Not Tracked" : // Add this line for "Not Tracked"
            "All";
            const localQueryFilter = [startDate, endDate, localBuildState, searchText, selectedDC].filter(Boolean);
            setBuildReports([]);
            setLoading(true);
            const response = await queryDB(builds_reports_component_get_query_1(loginUserDept,localQueryFilter,GlobalServerData));
            const data = response;
            setBuildReports(data);
            setLoading(false);
            setSearchText(searchText);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    // Handle date range filtering
    const handleDateRange = async (startDate, endDate) => {
        try {
            const localBuildState = 
            buildState === "Opted Out" ? "User Opted Out" :
            buildState === "Tracked" ? "Approved" :
            buildState === "Not Tracked" ? "User Not Tracked" : // Add this line for "Not Tracked"
            "All";
            const localQueryFilter = [startDate, endDate, localBuildState, serchedText, selectedDC].filter(Boolean);
            setBuildReports([]);
            setLoading(true);
            const response = await queryDB(builds_reports_component_get_query_1(loginUserDept,localQueryFilter,GlobalServerData));
            const data = response;
            setBuildReports(data);
            setLoading(false);
            setStartDate(startDate);
            setEndDate(endDate);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    const handleBuildReportFilter = async (selectedBuildStatus) => {
        try {
            const localBuildState = 
            selectedBuildStatus === "Opted Out" ? "User Opted Out" :
            selectedBuildStatus === "Tracked" ? "Approved" :
            selectedBuildStatus === "Not Tracked" ? "Not Tracked" : // Add this line for "Not Tracked"
            "All";
            const localQueryFilter = [startDate, endDate, localBuildState, serchedText, selectedDC].filter(Boolean);
            setBuildReports([]);
            setLoading(true);
            const response = await queryDB(builds_reports_component_get_query_1(loginUserDept,localQueryFilter,GlobalServerData));
            const data = response;
            setBuildReports(data);
            setLoading(false);
           setBuildState(selectedBuildStatus)
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    const handleBuildDcFilter = async (selectedDC_Data) => {
        try {
            const localBuildState = 
            buildState === "Opted Out" ? "User Opted Out" :
            buildState === "Tracked" ? "Approved" :
            buildState === "Not Tracked" ? "User Not Tracked" : // Add this line for "Not Tracked"
            "All";
            const localQueryFilter = [startDate, endDate, localBuildState, serchedText, selectedDC_Data].filter(Boolean);
            setBuildReports([]);
            setLoading(true);
            const response = await queryDB(builds_reports_component_get_query_1(loginUserDept,localQueryFilter,GlobalServerData));
            const data = response;
            setBuildReports(data);
            setLoading(false);
            setSelectedDC(selectedDC_Data)
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };


    return (
        <div  className="page-content-11">
            <BuildHeader handleSearch={handleSearch} handleDateRange={handleDateRange} handleBuildReportFilter={handleBuildReportFilter} handleBuildDcFilter={handleBuildDcFilter}/>
            <Box style={{ marginTop: '20px', flex: 1, overflow: "hidden" }}>
                {loading && <CircularProgress  style={{ position: "fixed", top: "50%", left: "60%", transform: "translate(-50%,-50%)" }} />}
                {error && <Typography color="error">Error: {error}</Typography>}

                {buildReports.length > 0 ? (
                        <BuildReport  data={buildReports} />
                ) : (
                    !loading && <Typography>No build reports found</Typography>
                )}
            </Box>
        </div>
    );
}
//ignorei18n_end
