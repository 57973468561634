import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Box, Typography, IconButton, Tooltip, CircularProgress, Skeleton, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import ArrowCircleDownTwoToneIcon from '@mui/icons-material/ArrowCircleDownTwoTone';
import Brightness1TwoToneIcon from '@mui/icons-material/Brightness1TwoTone';
import { UserContext } from '../../../../contexts/UserContext';
import { queryDB } from '../../../../util/QueryDB';
import { TicketCard_page_component_get_query_1 } from '../../../../catalystDB/queryGenerator';
import { GetNameAndEmpID } from '../../../../util/Loading';
import TicketRangeSelector from './TicketRangeSelector';
import dayjs from 'dayjs';
import DriSelector from './DriSelector';
import { CheckCircleOutline, HourglassEmpty, ErrorOutline, CancelOutlined, TimerOutlined, DoneAllOutlined, NotInterestedOutlined } from '@mui/icons-material';

const ServerDetail = ({ serverName, onClose, TicketCardStartTime, TicketCardCustomStartDate, TicketCardCustomEndDate }) => {
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [isOntimeSelected, setIsOntimeSelected] = useState(false);
    const [isDelayedSelected, setIsDelayedSelected] = useState(false);
    const { serverConfiguration } = useContext(UserContext);
    const [overallData, setOverallData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [startTime, setStartTime] = useState(TicketCardStartTime === "Week" ? "Week" : TicketCardStartTime);
    const [customStartDate, setCustomStartDate] = useState(TicketCardCustomStartDate === null ? null : TicketCardCustomStartDate);
    const [customEndDate, setCustomEndDate] = useState(TicketCardCustomEndDate === null ? null : TicketCardCustomEndDate);
    const [showChip, setShowChip] = useState(TicketCardCustomStartDate === null ? false : true);
    const [selectedDRI, setSelectedDRI] = useState([]);

    const fetchTicketReports = async () => {
        try {
            setLoading(true);
            const { startDate, endDate } = getDateRange(startTime);
            const serverProducts = [];
            if (serverName in serverConfiguration.current) {
                const products = JSON.parse(serverConfiguration.current[serverName].IssueProductType);
                products.forEach(prod => {
                    if (!serverProducts.includes(prod)) {
                        serverProducts.push(prod);
                    }
                });
            }
            const productName = serverProducts?.join(`','`) || "undefined";
            const response = await queryDB(TicketCard_page_component_get_query_1(productName, startDate, endDate,selectedDRI));
            const data = response.map(item => item.Tickets);
            setOverallData(data);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchTicketReports();
    }, [startTime,selectedDRI]);

    function getDateRange(rangeType) {
        const today = new Date();
        let endDate = formatDate(new Date(today.setDate(today.getDate() + 1)));
        let startDate;
    
        if (rangeType === "Month") {
            startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        } else if (rangeType === "Quarter") {
            const quarterStartMonth = today.getMonth() - 2;
            startDate = new Date(today.getFullYear(), quarterStartMonth, 1);
        } else if (rangeType === "Custom") {
            // Convert custom dates to dayjs objects if they're strings
            const start = dayjs(customStartDate);
            const end = dayjs(customEndDate).add(1, 'day'); // Add an extra day to the end date
        
            if (start.isValid() && end.isValid()) {
                startDate = start;
                endDate = end;
            } else {
                // throw new Error("Invalid custom date range.");
            }
        }
         else if (rangeType === "Week") {
            const dayOfWeek = today.getDay();
            const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
            startDate = new Date(today.setDate(diff));
        } else {
            startDate = "Not Selected";
        }
    
        return {
            startDate: startDate === "Not Selected" ? "Not Selected" : formatDate(startDate),
            endDate: endDate === "Not Selected" ? "Not Selected" : rangeType === "Custom" ? formatDate(endDate) : endDate
        };
    }
    

    function formatDate(date) {
        // Check if date is a dayjs object
        if (dayjs.isDayjs(date)) {
            return date.format('YYYY-MM-DD');
        } 
        // Check if it's a Date object
        else if (date instanceof Date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        } 
        // If neither, log and throw an error
        else {
            // console.log("Invalid date format:", date);
            // throw new Error("Invalid date format");
        }
    }

    const handleStatusClick = (status) => {
        setSelectedStatus(selectedStatus === status ? null : status);
    };

    const handleOntimeClick = () => {
        setIsOntimeSelected(!isOntimeSelected);
        if (isDelayedSelected) setIsDelayedSelected(false);
    };

    const handleDelayedClick = () => {
        setIsDelayedSelected(!isDelayedSelected);
        if (isOntimeSelected) setIsOntimeSelected(false);
    };

    const filteredTickets = useMemo(() => {
        return overallData.filter((ticket) => {
            const matchesOntime = isOntimeSelected && OntimeOrNot(ticket.CREATEDTIME, ticket.ResolvedOn, ticket.Status);
            const matchesDelayed = isDelayedSelected && !OntimeOrNot(ticket.CREATEDTIME, ticket.ResolvedOn, ticket.Status);
            const matchesStatus = selectedStatus ? ticket.Status === selectedStatus : true;
            return (
                (isOntimeSelected || isDelayedSelected ? matchesOntime || matchesDelayed : true) &&
                matchesStatus
            );
        });
    }, [overallData, isOntimeSelected, isDelayedSelected, selectedStatus]);


    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        window.addEventListener('keydown', handleEsc);
        return () => window.removeEventListener('keydown', handleEsc);
    }, [onClose]);

    function isUnclean(item) {
        return [undefined, null, NaN, "", " ", "-"].includes(item);
    }

    function formattedDate(date) {
        const formattedGivenDate = new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
        return formattedGivenDate;
    }


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100vh', backgroundColor: '#F5F5F5' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box flexGrow={1} display="flex" justifyContent="center">
                    <Typography style={{ color: "black", textAlign: "center", fontSize: "17px", margin: "5px 0px 0px 0px" }} variant="h5">
                        {serverName} - Ticket Dashboard
                    </Typography>
                    <TicketRangeSelector fetchTicketReports={fetchTicketReports} startTime={startTime} setStartTime={setStartTime} customStartDate={customStartDate} setCustomStartDate={setCustomStartDate}
                                                 customEndDate={customEndDate} setCustomEndDate={setCustomEndDate} fetchTicketReports={fetchTicketReports} showChip={showChip} setShowChip={setShowChip}
                            />
                    <DriSelector selectedDRI={selectedDRI} setSelectedDRI={setSelectedDRI} fetchTicketReports={fetchTicketReports}/>
                </Box>
                <IconButton onClick={onClose} aria-label="close" sx={{ color: 'red',  marginBottom: "15px" }}>
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box sx={{ position: 'sticky', top: 0, zIndex: 1, paddingBottom: 2, boxShadow:2, borderRadius:2 }}>
                <DashboardMetrics overallData={overallData} handleStatusClick={handleStatusClick} selectedStatus={selectedStatus} isOntimeSelected={isOntimeSelected} isDelayedSelected={isDelayedSelected} handleDelayedClick={handleDelayedClick} handleOntimeClick={handleOntimeClick} loading={loading}/>
            </Box>
            {/* <Divider /> */}

            {/* Loading Indicator */}
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                    <CircularProgress size={60} />
                </Box>
            ) : (
                <Box sx={{ overflowY: 'auto', flex: 1, paddingBottom: '105px' }}>
                    <Typography style={{ color: "black", fontSize: "18px", padding: "10px 20px" }} variant="h5" gutterBottom>
                        Ticket Details
                    </Typography>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))", // Adjust min width as needed
                            gap: "16px", // Space between grid items
                        }}
                    >
                        {filteredTickets.length > 0 ? (
                            filteredTickets.map((ticket, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        padding: 2,
                                        border: '1px solid #ddd',
                                        borderRadius: 5,
                                        boxShadow: 2,
                                        backgroundColor: '#fff',
                                        minHeight: 150,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1,
                                        overflowWrap: 'break-word',
                                    }}
                                >
                                    {/* Title with line wrap and ellipsis for overflow */}
                                    <Tooltip title={ticket.Type === "Issue" ? ticket.Issue : ticket.Query}>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                fontSize: "15px",
                                                // fontWeight: 'bold',
                                                mb: 1,
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            {ticket.Type === "Issue" ? ticket.Issue : ticket.Query}
                                        </Typography>
                                    </Tooltip>
                                    <Divider sx={{ mb: 1 }} />

                                    {/* Information fields with spacing */}
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                        <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '13px' }}>
                                            <strong style={{ width: '40%' }}>DRI:</strong>
                                            <span style={{ textAlign: 'left', width: '60%' }}>
                                                {isUnclean(ticket.ClaimedBy_Mail_ID) ? "-" : <GetNameAndEmpID emails={ticket.ClaimedBy_Mail_ID} />}
                                            </span>
                                        </Typography>
                                        <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '13px' }}>
                                            <strong style={{ width: '40%' }}>Created On:</strong>
                                            <span style={{ textAlign: 'left', width: '60%' }}>{formattedDate(ticket.CREATEDTIME)}</span>
                                        </Typography>

                                        <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '13px' }}>
                                            <strong style={{ width: '40%' }}>Priority:</strong>
                                            <span style={{ textAlign: 'left', width: '60%', display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                {ticket.Priority_1 === "High" ? (
                                                    <>
                                                        <ArrowCircleUpTwoToneIcon style={{ color: "crimson" }} />
                                                        <span>High</span>
                                                    </>
                                                ) : ticket.Priority_1 === "Medium" ? (
                                                    <>
                                                        <RemoveCircleTwoToneIcon style={{ color: "orange" }} />
                                                        <span>Medium</span>
                                                    </>
                                                ) : ticket.Priority_1 === "Low" ? (
                                                    <>
                                                        <ArrowCircleDownTwoToneIcon style={{ color: "mediumseagreen" }} />
                                                        <span>Low</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Brightness1TwoToneIcon style={{ color: "grey" }} />
                                                        <span>Default</span>
                                                    </>
                                                )}
                                            </span>
                                        </Typography>
                                        {ticket.Status === 'Completed' && (
                                            <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '13px' }}>
                                                <strong style={{ width: '40%' }}>Completed On:</strong>
                                                <span style={{ textAlign: 'left', width: '60%' }}>{formattedDate(ticket.ResolvedOn) || 'Yet To Complete'}</span>
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            ))
                        ) : (
                            <Typography variant="body1" align="center" sx={{ width: '100%', marginTop: 2 }}>
                                No data available
                            </Typography>
                        )}
                    </div>
                </Box>
            )}
        </Box>
    );
};

function OntimeOrNot(CreatedTime, CompletedTime, Status) {
    const createdDate = new Date(CreatedTime);
    const now = new Date();

    if (Status === "Completed") {
        const completedDate = new Date(CompletedTime);
        const timeDiff = completedDate - createdDate; // Difference in milliseconds
        const hoursDiff = timeDiff / (1000 * 60 * 60); // Convert to hours
        return hoursDiff <= 24; // If more than 24 hours, return false
    } else {
        const timeDiff = now - createdDate; // Difference between current time and created time
        const hoursDiff = timeDiff / (1000 * 60 * 60); // Convert to hours
        return hoursDiff <= 24;
    }
}

const MetricBox = ({ label, value, color, icon: IconComponent, onClick, isSelected, loading }) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            pt: 1,
            margin:"auto"
        }}
    >
        <Box
            sx={{
                position: 'relative',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 40,
                height: 40,
                bgcolor: color,
                borderRadius: '50%',
                color: '#fff',
                cursor: 'pointer',
                boxShadow: isSelected ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : '0px 2px 6px rgba(0, 0, 0, 0.2)',
                border: isSelected ? '4px solid #6A5ACD' : 'none',
                transition: 'border 0.3s ease, box-shadow 0.3s ease',
            }}
            onClick={onClick}
        >
            <IconComponent sx={{ fontSize: 22 }} />
        </Box>
        <Tooltip title={label} arrow>
            <Typography sx={{ color: '#333', mt: 0.5, textAlign: 'center', fontSize: 12 }}>
                {label}
            </Typography>
        </Tooltip>
        {loading ? (
            <Skeleton variant="text" width={25} height={18} sx={{ marginTop: 0.5 }} />
        ) : (
            <Typography sx={{ color: color, fontWeight: 'bold', fontSize: 14 }}>
                {value}
            </Typography>
        )}
    </Box>
);

const DashboardMetrics = ({ overallData = [], handleStatusClick, selectedStatus, isOntimeSelected, isDelayedSelected, handleDelayedClick, handleOntimeClick, loading }) => {
    const metrics = [
        { label: 'Total Ticket Raised', value: overallData?.filter(ticket => ticket).length || "-", color: '#6A5ACD', icon: DoneAllOutlined },
        { label: 'Unclaimed', value: overallData?.filter(ticket => ticket.Status === 'Ticket Created').length, color: '#fd7e14', icon: HourglassEmpty, onClick: () => handleStatusClick('Ticket Created'), isSelected: selectedStatus === 'Ticket Created' },
        { label: 'Claimed', value: overallData?.filter(ticket => ticket.Status === 'Open').length, color: '#007bff', icon: TimerOutlined, onClick: () => handleStatusClick('Open'), isSelected: selectedStatus === 'Open' },
        { label: 'Completed', value: overallData?.filter(ticket => ticket.Status === 'Completed').length, color: '#17a2b8', icon: CheckCircleOutline, onClick: () => handleStatusClick('Completed'), isSelected: selectedStatus === 'Completed' },
        { label: 'Resolution waiting for Approval', value: overallData?.filter(ticket => ticket.Status === 'Resolution waiting for Approval').length, color: '#ffc107', icon: NotInterestedOutlined, onClick: () => handleStatusClick('Resolution waiting for Approval'), isSelected: selectedStatus === 'Resolution waiting for Approval' },
        { label: 'Cancelled', value: overallData?.filter(ticket => ticket.Status === 'Ticket Rejected').length, color: '#6c757d', icon: CancelOutlined, onClick: () => handleStatusClick('Ticket Rejected'), isSelected: selectedStatus === 'Ticket Rejected' },
        { label: 'Ontime', value: overallData?.filter(ticket => OntimeOrNot(ticket.CREATEDTIME, ticket.ResolvedOn, ticket.Status)).length, color: '#28a745', icon: CheckCircleOutline, onClick: handleOntimeClick, isSelected: isOntimeSelected },
        { label: 'Delayed', value: overallData?.filter(ticket => !OntimeOrNot(ticket.CREATEDTIME, ticket.ResolvedOn, ticket.Status)).length, color: '#dc3545', icon: ErrorOutline, onClick: handleDelayedClick, isSelected: isDelayedSelected },
    ];

    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(80px, 1fr))', gap: 1, padding: 1, borderRadius: 1 }}>
            {metrics.map((metric) => (
                <MetricBox key={metric.label} {...metric} loading={loading} />
            ))}
        </Box>
    );
};




export default ServerDetail;
